<div class="file-loader-wrapper">

    <div class="label-valued" [ngClass]="{'visible': loadedFile}">
        <span>{{label}}</span>
    </div>

    <div class="file-loader">
        <input type="file" #fileOpenBtn (change)="onFilesLoadedByInput($event)" [attr.accept]="accept" />

        <div class="button-wrapper">
            <button type="button" class="btn btn-big btn-accent-inverse" (click)="fireFileDialogClickEvent()">
                <span [translate]>shared.browse</span>
            </button>
        </div>

        <div class="info-wrapper">

            <div class="label" *ngIf="!loadedFile">{{label}}</div>
            <div class="filename" *ngIf="loadedFile">
                <div class="name">{{loadedFile.name}} ({{loadedFile.size|byteConvert}})</div>
                <div class="delete-link as-link" (click)="reset()" [translate]>shared.delete</div>
            </div>

            <div class="validation-messages">
                <div class="validation-messages-item" *ngFor="let message of validationMessages">{{message}}</div>
            </div>
            <div class="error">{{error}}</div>
        </div>
    </div>

</div>