import {
    Component,
    Input,
    EventEmitter,
    Output,
    HostListener,
    ChangeDetectionStrategy,
    ViewEncapsulation,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

import { UiService } from 'shared/services/ui.service'


@Component({
    selector: 'app-modal',
    templateUrl: 'popup.component.html',
    styleUrls: ['popup.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})

export class PopupComponent
{
    constructor(protected ui: UiService) { }

    @Input() set visible(value: boolean) { this.setVisible(value) }
    private visibleStream = new BehaviorSubject<boolean>(false)
    get visible$() { return this.visibleStream.asObservable() }

    @Output() public closed = new EventEmitter<void>()

    @HostListener('document:keypress', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent) {
        if (this.visibleStream.getValue() === true && event.keyCode === 27) {
            this.close()
        }
    }

    private setVisible(visible: boolean) {
        this.visibleStream.next(visible)
        if (visible) {
            this.ui.showPageMask()
        } else {
            this.ui.hidePageMask()
        }
    }

    public close(): void {
        this.setVisible(false)
        this.closed.emit()
    }
}
