import { IFilterValue } from 'shared/models/ui/IFilterValue'
import { FiltersStorageKeysMap } from 'shared/models/filters/filters-storage-keys'


// TODO: refactor into service DTSearchService
export class LocalStorageFiltersCrud {
    protected saveFitlers(id: string, filters: IFilterValue): void {
        localStorage.setItem(id, JSON.stringify(filters))
    }

    protected getFilters(id: string): IFilterValue {
        return JSON.parse(localStorage.getItem(id))
    }

    protected flushFilters(id: string): void {
        localStorage.removeItem(id)
    }

    public static flushAllFilters(): void {
        Object
            .values(FiltersStorageKeysMap)
            .forEach(key => localStorage.removeItem(key))
    }
}