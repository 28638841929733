export interface IApiPaging {
    currentPage: number
    pageCount?: number
    pageSize: number
    rowCount?: number
}

export interface IApiPagingWithType extends IApiPaging {
    type?: number
}
