<div class="dt-action-wrapper">
    <span class="action-icon">
        <ng-container *ngIf="link">
            <a [routerLink]="link" [queryParams]="queryParams"><i [attr.class]="iconClass" [attr.title]="title"></i></a>
        </ng-container>

        <ng-container *ngIf="externalLink">
            <a target="_blank" [href]="externalLink"><i [attr.class]="iconClass" [attr.title]="title"></i></a>
        </ng-container>

        <ng-container *ngIf="onClickSubscribed">
            <i [attr.class]="iconClass" (click)="runClickEvent()" [attr.title]="title"></i>
        </ng-container>
    </span>
</div>
