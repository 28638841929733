import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'translate',
    standalone: true,
})
export class TranslatePipeStub implements PipeTransform {
    transform(value: any, ...args: any[]): any {
    }
}
