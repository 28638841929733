import { formField } from '../../models'
import { formFieldConfigMock } from '../mocks'

export class TestEntityStub {
    id: number

    @formField()
    title: string

    @formField(formFieldConfigMock)
    description: string

    @formField({
        children: {
            innerParam: {},
            innerArray: {
                children: {
                    '0': {
                        children: {
                            innerParam: {},
                        },
                    },
                },
            },
        },
    })
    params: {
        innerParam: string
        innerArray: { innerParam: string }[]
    }

    @formField()
    arrayParams: string[]

    constructor() {
        this.id = 1
        this.title = 'Title'
        this.params = {
            innerParam: 'value',
            innerArray: [
                {
                    innerParam: 'value',
                },
            ],
        }
        this.arrayParams = ['value1', 'value2']
    }
}
