export enum EDimensions {
    Hour = 1,
    Day = 2,
    Agency = 3,
    Advertiser = 4,
    Flight = 5,
    Campaign = 6,
    Creative = 7,
    SSP = 8,
    OperSys = 9,
    Country = 10,
    Browser = 11,
    Manufacturer = 12,
    Domain = 13,
    Device = 15,
    App = 14,
    Carrier = 16,
    PubId = 55,
    PubName = 56,
    SiteId = 57,
    SiteName = 58,
    AppId = 59,
    AppName = 60,
    Type = 73,
    CreativeSize = 74,
    AdclientName = 75,
    FinContrErir = 76,
    CreativeErir = 77,
    vendor3paId = 78,
    segment3paId = 79,
    Region = 80,
    City = 81,
    Dcode = 88,
    Bcode = 89,
}