import { Subscription } from 'rxjs'

import { MessageBusService } from './message-bus.service'
import { INewableType } from './INewableType'
import { ICallbackFunction } from './ICallbackFunction'


export class MessageBusGroup {
    private messageBus: MessageBusService
    private subscriptions: Subscription[]

    constructor(messageBus: MessageBusService) {
        this.messageBus = messageBus
        this.subscriptions = []
    }


    public emit(event: any): MessageBusGroup {
        this.messageBus.emit(event)
        return (this)
    }


    public on<T>(
        typeFilter: INewableType<T>, 
        callback: ICallbackFunction<T>, 
        callbackContext: any = null,
    ): MessageBusGroup {
        this.subscriptions.push(
            this.messageBus.on(typeFilter, callback, callbackContext),
        )

        return (this)
    }


    public subscribe(callback: ICallbackFunction, callbackContext: any = null): MessageBusGroup {
        this.subscriptions.push(
            this.messageBus.subscribe(callback, callbackContext),
        )

        return (this)
    }

    public unsubscribe(): MessageBusGroup {
        this.subscriptions.forEach(s => s.unsubscribe())
        this.subscriptions = []
        return (this)
    }
}