import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'

import { ICheckBoxGroupElement } from '../models'

@Injectable({
  providedIn: 'root',
})
export class AgencyFilterService {
    private agenciesSubject: ReplaySubject<ICheckBoxGroupElement[]> = new ReplaySubject<ICheckBoxGroupElement[]>()
    public agencies$ = this.agenciesSubject.asObservable()

    public emitAgencies(agencies: ICheckBoxGroupElement[]): void {
        this.agenciesSubject.next(agencies)
    }
}
