<div class="dt-search-as-list" *ngIf="searchEnabled$ | async">
    <div class="dt-filters-list-wrapper">
        <ng-container [ngTemplateOutlet]="filtersForm"
                      [ngTemplateOutletContext]="{'filter': filter}"></ng-container>

        <div class="dt-filters-actions">
            <div class="reset-link">
                <span class="as-link" [translate] (click)="reset()">shared.reset</span>
            </div>
        </div>
    </div>
</div>