import { IFormValidityState } from './IFormValidityState'
import { FormGroup, AbstractControl } from '@angular/forms'


export class FormValidityState
    implements IFormValidityState {
    protected validity: { [key: string]: AbstractControl } = {}

    constructor(form?: FormGroup) {
        if (form) {
            Object.keys(form.controls).forEach(key => {
                this.validity[key] = form.get(key)
            })
        }
    }

    public fieldInvalid(key): boolean {
        const field = this.validity[key]

        if (!field)
            return false

        return field.invalid && (field.dirty || field.touched)
    }

    public fieldError(fieldKey, errorKey): boolean {
        const field = this.validity[fieldKey]

        if (!field)
            return false

        return field.errors && field.errors[errorKey]
    }
}
