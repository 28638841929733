import { Component, Inject, Injector, OnDestroy } from '@angular/core'

import { FilterBaseComponent } from '../filter-base-component'
import { ICheckBoxGroupElement, IFilterValue } from '../../../../../models'
import { ECreativeType } from 'app/inventory/shared/ECreativeType'

@Component({
    templateUrl: './creative-type-filter.component.html',
})
export class CreativeTypeFilterComponent extends FilterBaseComponent implements OnDestroy {
    protected items: ICheckBoxGroupElement[] = [
        {
            displayName: 'Banner:Image',
            value: ECreativeType.BannerImage,
        },
        {
            displayName: 'Banner:Html',
            value: ECreativeType.BannerJs,
        },
        {
            displayName: 'Banner:Html5.Zip',
            value: ECreativeType.BannerHTML5Zip,
        },
        {
            displayName: 'Video:Linear:Inline',
            value: ECreativeType.VideoLinearInline,
        },
        {
            displayName: 'Video:Linear:Wrapper',
            value: ECreativeType.VideoLinearWrapper,
        },
    ]

    constructor(
        @Inject(Injector) injector: Injector,
    ) {
        super(injector)
    }

    public generateFilterView(filter: IFilterValue): void {
        const filtersList = filter[this.key] as string[]

        const result = filtersList
            .map(creativeType => this.items.find(item => item.value === creativeType).displayName)
            .join(', ')

        this.filterView.emit(result)
    }

    public change(value: string[]): void {
        this.hasValue.emit(Boolean(value.length))
    }

    public ngOnDestroy(): void {
        this.model[this.key] = []
    }
}
