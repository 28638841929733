import { TranslateLoader } from '@ngx-translate/core'
import { of } from 'rxjs'

export class TranslateLanguageLoader implements TranslateLoader {
    public getTranslation(lang: string): any {
        //Note Dynamic require(variable) will not work. Require is always at compile time
        switch (lang) {
            case 'en':
                return of(require('../../assets/locale/en.json'))
            case 'ru':
                return of(require('../../assets/locale/ru.json'))
            default:
        }
    }
}