import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { ControlContainer, ControlValueAccessor, FormControl, ValidationErrors } from '@angular/forms'
import { Subscription } from 'rxjs'

import { IValidationMessage } from 'shared/models'
import { FormValidationService } from 'shared/services'


@Component({ template: '' })
export class FormInputComponent<ModelType> implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() public formControlName: string

    @Input() public readonly = false

    @Input() public inputMask: string

    @Input() public showInputMask = false

    @Input() public dropSpecialCharacters = false

    @Input() public maskSymbol = '_'

    @Input() public thousandSeparator: string

    @Input() public label: string = null

    @Input() public optional = false

    @Input() public tabIndex: number

    @Input() public showErrors = true

    @Input() public showValidState = false

    public uniqueId: number = Math.floor(Math.random() * 100000)

    public isFocused = false

    public isHovered = false

    public get noLabel(): boolean { return !this.label || this.label.length === 0 }

    public get isValid(): boolean { return this.control && this.control.valid }

    public get isDirty(): boolean { return this.control && this.control.dirty }

    public control: FormControl

    protected subs: Subscription[] = []

    public get errorMessages(): string[] {
        let result

        if (!this.control || !this.control.errors) {
            result = []
        } else {
            result = Object.keys(this.control.errors).map(errKey => {
                let errorLine: string

                if (this.control.errors[errKey]) {
                    if (
                        Array.isArray(this.control.errors[errKey])
                        && this.control.errors[errKey].length > 0
                    ) {
                        errorLine = this.control.errors[errKey].join('\n')
                    } else if (this.control.errors[errKey].message) {
                        errorLine = this.control.errors[errKey].message
                    } else {
                        errorLine = errKey
                    }
                } else {
                    errorLine = errKey
                }

                return errorLine
            })
        }

        return result
    }


    constructor(
        protected controlContainer: ControlContainer,
        protected cdr: ChangeDetectorRef,
        protected validator: FormValidationService,
    ) {}

    public ngOnInit(): void {
        if (this.controlContainer && this.formControlName) {
            this.control = this.controlContainer.control.get(this.formControlName) as FormControl
            this.subs.push(this.validator.lastMessage.subscribe(message => this.parseValidationMessage(message)))
        } else {
            this.control = new FormControl()
        }
    }


    public ngOnDestroy(): void {
        this.subs.forEach(s => s.unsubscribe())
    }


    //#region ControlValueAccessor realization

    public writeValue(value: ModelType): void {
        if (Number.isNaN(value) || value === this.control.value) {
            return
        }

        if (!value) {
            this.control.setValue(undefined)
        } else {
            this.control.setValue(value)
        }

        this.markForCheck()
    }

    private _onChange = (_value: ModelType | null): void => undefined
    public registerOnChange(fn: (value: ModelType | null) => void): void { this._onChange = fn }

    public onTouched = (): void => undefined
    public registerOnTouched(fn: () => void): void { this.onTouched = fn }

    //#endregion


    public onChange(value: ModelType | null): void {
        this.onTouched()
        this._onChange(value)
    }

    public onBlur(): void {
        this.isFocused = false
        this.onTouched()
        this.markForCheck()
    }

    public onFocus(): void {
        if (this.readonly) {
            return
        }

        this.isFocused = true
        this.markForCheck()
    }

    public onMouseEnter(): void {
        this.isHovered = true
        this.markForCheck()
    }

    public onMouseLeave(): void {
        this.isHovered = false
        this.markForCheck()
    }


    protected parseValidationMessage(message: IValidationMessage): void {
        if (!message || !message.apiResponse || !message.apiResponse.errorDetails) {
            return
        }

        const errors: ValidationErrors = {}

        message.apiResponse.errorDetails
            .filter(e => e.errorDescriptor.toLowerCase() === this.formControlName.toLowerCase())
            .forEach(e => errors[e.errorDescriptor] = e.errorMessage)

        this.control.setErrors(Object.keys(errors).length > 0 ? errors : null)

        this.markForCheck()
    }


    protected markForCheck(): void {
        if (this.cdr) {
            this.cdr.markForCheck()
        }
    }
}
