import { Pipe, PipeTransform } from '@angular/core'
import dayjs from 'dayjs'

@Pipe({ name: 'dateTime' })
export class DateTimePipe implements PipeTransform {
    transform(value: string, format?: string): string {
        const fmt = format ? format : 'YYYY-MM-DD HH:mm:ss'

        return dayjs(value).format(fmt)
    }
}
