<div class="filter-wrapper" [ngClass]="cssClass">
    <i class="fa fa-times" *ngIf="!static" (click)="removeFilter()"></i>
    <div class="filter-view" >
        <ng-container #filterView></ng-container>
    </div>
    <div class="filter-dropdown-wrapper">
        <div class="filter-dropdown" #modal>
            <div class="title">{{ title|translate }}</div>
            <ng-container #filterDropdown></ng-container>
            <button class="btn btn-small btn-primary apply"
                    type="button"
                    (click)="apply()"
            >
                <span [translate]="'shared.apply'"></span>
            </button>
        </div>
    </div>
</div>
