/// <reference types="@angular/localize" />

import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment'
import { RootModule } from './root/root.module'
import { enableDebugTools } from '@angular/platform-browser'

export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href
}

const providers = [
    { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
]


if (environment.production) {
    enableProdMode()
}

platformBrowserDynamic(providers).bootstrapModule(RootModule)
    .then(ref => {
        if (!environment.production) {
            const applicationRef = ref.injector.get(ApplicationRef)
            const appComponent = applicationRef.components[0]
            enableDebugTools(appComponent)
        }
    })
    .catch(err => console.log(err))
