<div #popup class="app-modal" [hidden]="!(visible$|async)">
    <div class="popup-header">
        <div class="popup-header-buttons">
            <span class="as-link close" (click)="close()"><i class="fa fa-close"></i></span>
        </div>
        <div class="popup-header-text">
            <ng-content select="[popup-header]"></ng-content>
        </div>
    </div>

    <div class="popup-content">
        <ng-content select="[popup-content]"></ng-content>
    </div>

    <div class="popup-footer">
        <ng-content select="[popup-footer]"></ng-content>
    </div>
</div>