import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core'
import { IApiPaging, IDropdownItem } from 'shared/models'


export interface IPagerPage {
    index?: number
    page?: number
    skipped: boolean
}


@Component({
    selector: 'dt-pager',
    templateUrl: 'dt-pager.component.html',
})
export class DTPagerComponent implements OnInit, OnChanges {
    @Input() paging: IApiPaging

    @Output() onChange = new EventEmitter<IApiPaging>()

    public pages: IPagerPage[] = []
    public currentIndex = 0

    public get extendedMode(): boolean {
        return this.paging && this.paging.pageCount > 15
    }

    public get hideItemsPerPageSelector() {
        return this.paging.rowCount <= parseInt(this.itemsPerPageList[0].id, 10) && this.paging.rowCount <= this.paging.pageSize
    }

    public get itemPerPage(): string {
        return this.paging.pageSize?.toString()
    }

    public set itemPerPage(value: string) {
        this.paging.pageSize = Number(value)
    }

    public itemsPerPageList: IDropdownItem[] = [
        {
            disabled: false,
            id: '10',
            name: '10',
        }, {
            disabled: false,
            id: '50',
            name: '50',
        }, {
            disabled: false,
            id: '100',
            name: '100',
        },
    ]


    ngOnInit(): void {
        this.calculatePages()
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.calculatePages()
    }


    private calculatePages(): void {

        const result: IPagerPage[] = []

        this.currentIndex = this.paging.currentPage
        if (this.extendedMode) {
            for (let i = 0; i < this.paging.pageCount; i++) {
                if (
                    i < 4 ||
                    i > this.paging.pageCount - 4 ||
                    (i > this.currentIndex - 4 && i < this.currentIndex + 4)
                ) {
                    result.push({
                        index: i,
                        page: i + 1,
                        skipped: false,
                    })
                } else {
                    if (!result[result.length - 1].skipped) {
                        result.push({
                            skipped: true,
                        })
                    }
                }
            }
        } else {
            for (let i = 0; i < this.paging.pageCount; i++) {
                result.push({
                    index: i,
                    page: i + 1,
                    skipped: false,
                })
            }
        }

        this.pages = result.filter((element, index, source) => source.indexOf(element) === index)
    }


    private applyChanges(): void {
        const currentPageIndex = this.pages.findIndex((item) => item.index === this.currentIndex)

        this.paging.currentPage = currentPageIndex > 0
            ? this.pages[currentPageIndex].index || 0
            : 0

        this.onChange.emit(JSON.parse(JSON.stringify(this.paging)))
    }


    public setPage(index: number): void {
        this.currentIndex = index
        this.applyChanges()
    }


    public changePage(delta: number): void {
        this.currentIndex += delta
        this.applyChanges()
    }


    public itemsPerPageChange(): void {
        this.currentIndex = 0
        this.applyChanges()
    }
}
