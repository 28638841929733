<div class="side-modal" [class.active]="isActive" [hidden]="!isVisible">
    <div class="side-modal-header">
        <div class="side-modal-header-buttons">
            <span class="as-link close" (click)="close()"><i class="fa fa-close"></i></span>
        </div>
        <h2 class="title">{{ title }}</h2>
    </div>

    <div class="side-modal-content">
        <ng-content select="[popup-content]"></ng-content>
    </div>

    <div class="side-modal-footer">
        <button type="button"
                class="btn btn-primary btn-medium"
                (click)="close()">
            <span translate>shared.cancel</span>
        </button>
        <ng-content select="[popup-footer]"></ng-content>
    </div>
</div>
