import { EventEmitter, Inject, Injector } from '@angular/core'
import { finalize, switchMap, tap } from 'rxjs/operators'
import { Observable, Subject } from 'rxjs'
import { Router } from '@angular/router'

import {
    IApiListRequest,
    IApiOrdering,
    IApiPaging,
    IHaveId,
    IHaveStatus,
    ISearchFilter,
    SearchEvent,
} from 'shared/models'
import { DtSearchService } from 'shared/services'
import { PageComponent } from './page.component'
import { ApiService } from 'app/services/api.service'
import { ApiHttpErrorResponse } from '../api'
import { PaginationService } from 'shared/services/pagination.service'
import { IStatus } from 'app/inventory/shared/IStatus'


export class ListPageComponent<
    ItemType,
    SearchFilterType extends ISearchFilter,
>
    extends PageComponent {

    protected _items: ItemType[] = []
    protected get items(): ItemType[] {
        return this._items
    }

    protected get noData(): boolean {
        return this._items.length === 0
    }

    protected dataLoaded = new Subject<boolean>()
    protected triggerDataFetch$ = new Subject<void>()
    protected initialLoadSubscribed = false

    protected _isLoadingNow = true
    public get isLoadingNow(): boolean {
        return this._isLoadingNow
    }

    public paging: IApiPaging
    public filter: SearchFilterType

    protected readonly defaultFilterListRequest: IApiListRequest
    protected filteredItemsListRequest: IApiListRequest

    protected initialPaging: IApiPaging = { currentPage: 0, pageSize: 10 }

    public deleteItemPopupVisible = false
    public deletedItemIndex: number
    private itemDeleted: EventEmitter<void> = new EventEmitter<void>()

    protected get itemDeleted$(): Observable<void> {
        return this.itemDeleted.asObservable()
    }

    protected dtSearch: DtSearchService
    protected api: ApiService
    protected pagination: PaginationService

    public section: string

    constructor(
        @Inject(Injector) injector: Injector,
        protected apiUrlSegment?: string,
        protected ordering: IApiOrdering = { direction: 'desc', field: 'date' },
    ) {
        super(injector)

        this.dtSearch = injector.get(DtSearchService)
        this.api = injector.get(ApiService)
        this.pagination = injector.get(PaginationService)
        this.router = injector.get(Router)

        this.section = this.router.url.split('/').pop()
        this.setSavedPagination()

        this.filteredItemsListRequest = {
            searchTerm: '',
            ordering: this.ordering,
            paging: this.paging,
            additionalParams: {},
        }

        this.defaultFilterListRequest = {
            searchTerm: '',
            ordering: { direction: 'asc', field: 'id' },
            paging: { pageSize: 9999, currentPage: 0 },
        }

        this.subscribeOn(this.dtSearch.onTriggerSearchThroughService().subscribe(() => {
            this.paging = this.initialPaging
        }))

        this.subscribeOn(this.dtSearch.searchEvent.subscribe(
            (event: SearchEvent<SearchFilterType>) => {
                this.search(event.filters)
            }))
    }


    protected onPaging(paging: IApiPaging): void {
        this.paging = paging
        this.pagination.savePagination(this.section, paging)
        this.load()
    }


    protected onSort(ordering: IApiOrdering): void {
        this.ordering = { ...ordering }
        this.load()
    }


    protected load(): void {
        /* TODO: review on selected filter, triggers request when route params not available */
        if (!this.initialLoadSubscribed) {
            this.initialLoadSubscribed = true
            this.subscribeOn(this.fetchData$().subscribe())
        }

        this._isLoadingNow = true
        this.ui.startBackendAction()

        this.filteredItemsListRequest.paging = this.paging
        this.filteredItemsListRequest.ordering = this.ordering
        this.filteredItemsListRequest.searchTerm = this.filter ? this.filter.key : null

        if (this.filter?.status?.length) {
            this.filteredItemsListRequest.additionalParams = { statuses: this.filter['status'].join(',') }
        } else {
            delete this.filteredItemsListRequest.additionalParams['statuses']
        }

        if (this.filter?.role) {
            this.filteredItemsListRequest.additionalParams = {
                ...this.filteredItemsListRequest.additionalParams,
                role: this.filter['role'],
            }
        }

        if (this.filter?.agencyIds?.length) {
            this.filteredItemsListRequest.additionalParams = {
                ...this.filteredItemsListRequest.additionalParams,
                agencyIds: this.filter['agencyIds'],
            }
        }

        if (this.filter?.type?.length) {
            this.filteredItemsListRequest.additionalParams = {
                ...this.filteredItemsListRequest.additionalParams,
                type: this.filter['type'],
            }
        } else {
            delete this.filteredItemsListRequest.additionalParams['type']
        }

        this.triggerDataFetch$.next()
    }

    protected fetchData$(): Observable<boolean> {
        return this.triggerDataFetch$.pipe(
            finalize(() => this.ui.stopBackendAction()),
            switchMap(() => this.fetchData()),
            tap((success: boolean) => {
                this.dataLoaded.next(success)
                this._isLoadingNow = false
                this.ui.stopBackendAction()
            }),
        )
    }


    protected fetchData(): Observable<boolean> {
        throw new Error('Method not implemented.')
    }


    protected clearData(): void {
        this._items = []
    }


    protected search(filter: SearchFilterType): void {
        this.filter = filter
        this.load()
    }


    protected deleteItemConfirm(index: number): void {
        this.deleteItemPopupVisible = true
        this.deletedItemIndex = index
    }


    protected deleteItemCancel(): void {
        this.deletedItemIndex = null
        this.deleteItemPopupVisible = false
    }

    protected deleteItem$(): Observable<any> {
        if (!this.apiUrlSegment || this.apiUrlSegment.length === 0)
            throw new Error('To call deleteItemRequest method you must specify apiUrlSegment in constructor')

        // TODO refactor status to ENUM
        return this.api.setItemStatus(
            this.apiUrlSegment,
            (this.items[this.deletedItemIndex] as IHaveId).id,
            3,
        )
    }

    protected deleteItem(): void {
        this.ui.startBackendAction()

        this.deleteItem$().subscribe(
            () => {
                const item: IHaveStatus = this.items[this.deletedItemIndex]
                item.deleted = true
                item.status = 3

                this.ui.stopBackendAction()
                this.itemDeleted.emit()
                this.deleteItemCancel()
                this.sysMessages.successMessage()
            },
            error => {
                console.error(error)
                this.ui.stopBackendAction()
                this.deleteItemCancel()
                // this.sysMessages.errorMessage(error.error);
            },
        )
    }


    protected activityToggle(index: number, enabled: boolean): void {
        if (!this.apiUrlSegment || this.apiUrlSegment.length === 0)
            throw new Error('To call enableItemRequest method you must specify apiUrlSegment in constructor')

        this.ui.startBackendAction()

        const request = this.api.setItemStatus(
            this.apiUrlSegment,
            (this.items[index] as IHaveId).id,
            enabled ? IStatus.enabled : IStatus.disabled,
        )

        request
            .pipe(finalize(() => this.ui.stopBackendAction()))
            .subscribe(
                () => {
                    const item: IHaveStatus = this.items[index] as IHaveStatus
                    // #TODO: item.status перевёрнуто состояние. Перепутаны next и error обработчики
                    item.status = enabled ? IStatus.disabled : IStatus.enabled
                    item.enabled = enabled
                },
                (error: ApiHttpErrorResponse) => {
                    const item: IHaveStatus = this.items[index] as IHaveStatus
                    item.status = enabled ? IStatus.enabled : IStatus.disabled
                    item.enabled = !enabled

                    this.sysMessages.errorMessage((error.error?.StatusChange || []).length > 0
                        ? error.error.StatusChange[0]
                        : error.message,
                    )
                },
            )
    }

    protected setSavedPagination(): void {
        const savedPaging = this.pagination.getPagination(this.section)
        this.paging = savedPaging || this.initialPaging
    }
}
