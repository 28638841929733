import {
  Component,
  EventEmitter,
  ViewEncapsulation,
  Output,
  TemplateRef,
  HostBinding,
  ChangeDetectionStrategy,
  HostListener,
} from '@angular/core'

import { ITooltipOptionsModel } from './interface'


@Component({
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {

  @HostBinding('class') hostClasses = ''

  @HostBinding('style.top.px') get topParam(): number { return this.options.top }

  @HostBinding('style.left.px') get leftParam(): number { return this.options.left }

  @Output() close: EventEmitter<boolean> = new EventEmitter()

  private _options: ITooltipOptionsModel

  set options(op: ITooltipOptionsModel) {
    this._options = op
    this.hostClasses = op.className
  }

  get options(): ITooltipOptionsModel {
    return this._options
  }

  get hasTemplateRef(): boolean {
    return this._options.content instanceof TemplateRef
  }

  @HostListener('animationend', ['$event'])
  public animationend(event: AnimationEvent): void {
    if (event.animationName === 'tooltipOut') {
      this.close.emit(true)
    }
  }
}
