import { Component, EventEmitter, Inject, Injector, OnDestroy, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { ICheckBoxGroupElement, IFilterValue } from 'shared/models'
import { FilterBaseComponent } from '../filter-base-component'
import { IStatusFilter } from 'app/inventory/shared/IStatusFilter'


@Component({
   templateUrl: './status-filter.component.html',
})
export class StatusFilterComponent extends FilterBaseComponent implements OnDestroy {

   @Output()
   public filterView = new EventEmitter()

   public readonly statuses: ICheckBoxGroupElement[] = [
      {
         displayName: this.i18n.instant('shared.states.isactive'),
         value: IStatusFilter.isActive,
      }, {
         displayName: this.i18n.instant('shared.states.isinactive'),
         value: IStatusFilter.isInActive,
      }, {
         displayName: this.i18n.instant('shared.states.isdeleted'),
         value: IStatusFilter.isDisabled,
      },
   ]

   constructor(@Inject(Injector) injector: Injector,
               protected i18n: TranslateService) {
      super(injector)
   }

   generateFilterView(filter: IFilterValue): void {
      this.filterView.emit(
         (filter[this.key] as string[])
            .map(status => this.statuses.find(st => st.value === status).displayName)
            .join(', '),
      )
   }

   change(value: string[]): void {
      if (value.length) {
         this.hasValue.emit(true)
      } else {
         this.hasValue.emit(false)
      }
   }

   public ngOnDestroy(): void {
      this.model[this.key] = []
   }
}