import { ChangeDetectorRef, Component, Input } from '@angular/core'


@Component({
    template: `
        <div *ngIf="bool" class="view-wrapper">
            {{title|translate}}
        </div>
        <div *ngIf="!bool" class="view-wrapper">
            {{title|translate}}: {{view || 'shared.notSelected'|translate}}
        </div>
    `,
    styles: ['.view-wrapper { cursor: pointer; line-height: 1; }'],
})
export class FilterBaseView {

    constructor(private cdr: ChangeDetectorRef) {
    }

    @Input()
    public set filterView(value: string) {
        this.view = value
        this.cdr.markForCheck()
    }
    view = ''

    @Input()
    bool = false

    @Input()
    public title = ''
}