import { Injectable } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

import { MessageBusGroup } from './MessageBusGroup'
import { INewableType } from './INewableType'
import { ICallbackFunction } from './ICallbackFunction'


@Injectable({ providedIn: 'root' })
export class MessageBusService
{
    private stream: Subject<any> = new Subject()

    public emit(event: any): void {
        this.stream.next(event)
    }

    public createGroup(): MessageBusGroup {
        return (new MessageBusGroup(this))
    }

    public on<T>(
        typeFilter: INewableType<T>,
        callback: ICallbackFunction<T>,
        callbackContext: any = null,
    ): Subscription {
        const subscription = this.stream
            .pipe(
                filter(event => event instanceof typeFilter),
            )
            .subscribe((event: T) => {
                try {
                    callback.call(callbackContext, event)
                } catch (error) {
                    console.error(error)
                }
            })

        return (subscription)
    }


    public subscribe(callback: ICallbackFunction, callbackContext: any = null): Subscription {
        const subscription = this.stream.subscribe(
            event => {
                try {
                    callback.call(callbackContext, event)
                } catch (error) {
                    console.error(error)
                }
            },
        )

        return (subscription)
    }
}