import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { ICheckBoxGroupElement } from 'shared/models'


@Component({
  selector: 'checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxGroupComponent),
      multi: true,
    },
  ],
})
export class CheckboxGroupComponent implements ControlValueAccessor {

  @Input()
  public items: ICheckBoxGroupElement[] = []

  @Input()
  public label = ''

  private checkboxes: string[] = []

  constructor(private cdr: ChangeDetectorRef) { }

  private propagateChange = (_value: string[] | null): void => undefined
  public registerOnChange(fn: () => void): void {
    this.propagateChange = fn
  }

  private propagateTouched = (): void => undefined
  public registerOnTouched(fn: () => void): void {
    this.propagateTouched = fn
  }

  public writeValue(value: string[]): void {
    if (value) {
      this.checkboxes = [...value]
    } else {
      this.checkboxes = []
    }

    this.cdr.markForCheck()
  }

  getState(value: string): boolean {
    return this.checkboxes.includes(value)
  }

  toggleState(checked: boolean, value: string): void {
    if (checked) {
      this.checkboxes.push(value)
    } else {
      this.checkboxes.splice(this.checkboxes.indexOf(value), 1)
    }

    this.propagateChange(this.checkboxes)
    this.cdr.markForCheck()
  }
}
