import { Component, Input } from '@angular/core'

@Component({
    selector: 'form-field-error',
    templateUrl: './form-field-error.component.html',
})
export class FormFieldErrorComponent
{
    @Input()
    public messages: string[] = []
}
