import { Component, ElementRef, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { fromEvent, Observable, Subscription } from 'rxjs'
import { debounceTime, finalize, first, map, switchMap, tap } from 'rxjs/operators'

import { IChipItem } from './model'
import { EQuickFilterType, IApiDtoNamed } from '../../../../index'
import { FilterSelectService } from '../../../../services/filter-select.service'

@Component({
    selector: 'selected-filter',
    templateUrl: './selected-filter.component.html',
    styleUrls: ['./selected-filter.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectedFilterComponent),
            multi: true,
        },
    ],
})
export class SelectedFilterComponent
    implements ControlValueAccessor, OnDestroy {

    @ViewChild('search')
    public searchInput: ElementRef

    @Input()
    public set columnId(value: string) {
        this._columnId = value
        this.isNeedToAddId = this.filtersWithIds.includes(Number(this.columnId))
    }
    public get columnId(): string {
        return this._columnId
    }
    private _columnId: string

    @Input()
    public disabled: boolean

    @Input()
    public label = ''

    @Input()
    public readonly = false

    @Input()
    public clearable = true

    @Input()
    public filterType: EQuickFilterType

    protected get isDisabled(): boolean {
        return this.disabled
    }

    protected get inputHasValue(): boolean {
        return this.searchInput?.nativeElement.value
    }

    protected filterTypesEnum = EQuickFilterType

    protected loading = false
    protected opened = false
    protected documentClick: Subscription
    protected itemsList: IChipItem<number | string>[] = []
    protected selectedChips: IChipItem<number | string>[] = []

    private searchInputStream$: Observable<KeyboardEvent>
    private searchInputStreamSubscription: Subscription

    private searchFocusStream$: Observable<KeyboardEvent>
    private searchFocusStreamSubscription: Subscription

    public propagateChange = (_: any) => {}
    public propagateTouched = (_: any) => {}

    protected get cssClass(): Record<string, boolean> {
        return {
            'opened': this.opened,
            'closed': !this.opened,
            'selected': this.selected,
            'disabled': this.isDisabled,
            'readonly': this.readonly,
        }
    }

    @Input()
    public filtersWithIds: number[] = []

    private isNeedToAddId = false

    constructor(
        private element: ElementRef,
        private filter: FilterSelectService,
    ) {
        this.documentClick = fromEvent(document, 'click')
            .subscribe((event: MouseEvent) => {
                const target = event.target
                if (!this.element.nativeElement.contains(target)) {
                    this.setOpenState(false)
                }
            })
    }

    public ngOnDestroy(): void {
        this.documentClick.unsubscribe()
        this.removeSearchEvents()
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouched = fn
    }

    public writeValue(obj: Array<string | number>): void {
        if (obj.length === 0) {
            this.selectedChips = []
        } else if (this.filterType === EQuickFilterType.selectable) {
            this.filter.filterSearch('', this.columnId).pipe(
                map(results => results.map(this.addIdToNameFn.bind(this))),
                tap((results: IApiDtoNamed[]) => {
                    const resMap = new Map(results.map(item =>
                        [item.id.toString(), item]),
                    )

                    this.selectedChips = obj
                        .filter((item: string) => {
                            return resMap.get(item.toString())
                        })
                        .map((item: string) => {
                            return {
                                id: item,
                                name: resMap.get(item.toString()).name,
                                selected: true,
                            }
                        })

                    this.emitModelValue()
                }),
            ).subscribe()
        } else if (this.filterType === EQuickFilterType.plainText) {
            this.selectedChips = obj.map((item: string) => {
                return {
                    id: undefined,
                    name: item,
                    selected: true,
                }
            })
            this.emitModelValue()
        }
    }

    public trackByFn(index): number {
        return index
    }

    protected get selected(): boolean {
        return this.selectedChips.length > 0
    }

    protected addManual(): void {
        const value = this.searchInput.nativeElement.value.trim()

        if (this.filterType === this.filterTypesEnum.selectable || !value) return

        const chip: IChipItem<number> = {
            id: undefined,
            name: value,
            selected: true,
        }
        this.selectedChips.push(chip)
        this.emitModelValue()
        this.searchInput.nativeElement.value = ''
    }

    protected itemClick(index: number): void {
        const chip = this.itemsList[index]
        chip.selected = true
        this.selectedChips.push(chip)

        this.searchInput.nativeElement.value = ''
        this.setOpenState(false)
        this.emitModelValue()
    }

    protected toggleList(event: Event): void {
        const target = event.target as HTMLElement
        if (
            target.classList.contains('remove-chip') ||
            this.filterType === EQuickFilterType.plainText
        ) return
        this.setOpenState(!this.opened)
    }

    protected removeChip(chip: IChipItem<number | string>, index: number): void {
        const clickedChip = this.selectedChips.find(c => c.id === chip.id)
        clickedChip.selected = false
        this.selectedChips.splice(index, 1)
        this.emitModelValue()
    }

    protected clearAll(): void {
        this.selectedChips = []
        for (const chip of this.itemsList) {
            chip.selected = false
        }
        this.searchInput.nativeElement.value = ''
        this.emitModelValue()
    }

    private emitModelValue(): void {
        this.propagateChange(this.selectedValues)
    }

    private get selectedValues(): number[] {
        let values = []
        const isEmpty = this.selectedChips.length === 0
        if (!isEmpty) {
            values = this.filterType === EQuickFilterType.plainText
                ? this.selectedChips.map(chip => chip.name)
                : this.selectedChips.map(chip => chip.id)
        }

        return values
    }

    private setOpenState(isOpened: boolean): void {
        if (this.isDisabled || this.readonly) return

        this.searchInput.nativeElement.blur()
        if (isOpened) {
            this.setupSearch()
            setTimeout(() => this.searchInput.nativeElement.focus())
        } else {
            this.removeSearchEvents()
            setTimeout(() => this.searchInput.nativeElement.blur())
        }

        this.opened = isOpened
    }

    private setupSearch(): void {
        this.itemsList = []
        const filterSearchedResults: (source: IApiDtoNamed[]) => IChipItem<number>[] = source => {
            const idsHashMap = this.selectedChips.reduce((acc, item) => (acc[item.id] = true) && acc, {})
            return source.filter(item => !idsHashMap[item.id]) as IChipItem<number>[]
        }

        this.searchInputStream$ = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'keyup')
        this.searchFocusStream$ = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'focus')

        this.searchInputStreamSubscription = this.searchInputStream$
            .pipe(
                tap(() => this.loading = true),
                debounceTime(200),
                switchMap(() => {
                    const term = this.searchInput.nativeElement.value
                    return this.filter.filterSearch(term, this.columnId).pipe(finalize(() => this.loading = false))
                }),
                map(results => results.map(this.addIdToNameFn.bind(this))),
            ).subscribe((results: IApiDtoNamed[]) => {
                if (results && results.length > 0) {
                    this.itemsList = filterSearchedResults(results)
                } else {
                    this.itemsList = []
                }
            })

        this.searchFocusStreamSubscription = this.searchFocusStream$
            .pipe(
                tap(() => this.loading = true),
                switchMap(() => {
                    const term = this.searchInput.nativeElement.value
                    return this.filter.filterSearch(term, this.columnId).pipe(finalize(() => this.loading = false))
                }),
                map(results => results.map(this.addIdToNameFn.bind(this))),
            )
            .subscribe((results: IApiDtoNamed[]) => {
                if (results && results.length > 0) {
                    this.itemsList = filterSearchedResults(results)
                } else {
                    this.itemsList = []
                }
            })
    }

    private addIdToNameFn(item: IApiDtoNamed): IApiDtoNamed {
        return this.isNeedToAddId ? { ...item, name: `[${item.id}] ${item.name}` } : item
    }

    private removeSearchEvents(): void {
        if (this.searchInputStreamSubscription) {
            this.searchInputStreamSubscription.unsubscribe()
        }
        if (this.searchFocusStreamSubscription) {
            this.searchFocusStreamSubscription.unsubscribe()
        }
    }
}
