<div class="input-text input-text-date" [ngClass]="cssClass">

    <div class="date-picker-wrapper">
        <div class="date-picker-inner" [hidden]="!datePickerVisible">
            <ngb-datepicker
                #dp="ngbDatepicker"
                [ngModel]="value"
                [dayTemplate]="customDay"
                [minDate]="datePickerOptions.minDate"
                [maxDate]="datePickerOptions.maxDate"
                (dateSelect)="onDateChanged($event)">

                <ng-template ngbDatepickerContent>
                    <div *ngFor="let month of dp.state.months">
                        <ngb-datepicker-month class="border rounded" [month]="month"></ngb-datepicker-month>
                    </div>
                </ng-template>

            </ngb-datepicker>
        </div>
    </div>

    <div class="input-wrapper">
        <div class="button-container">
            <div class="error-wrapper" *ngIf="showErrors && !isValid && isDirty && isHovered">
                <form-field-error [messages]="errorMessages"></form-field-error>
            </div>

            <button
                type="button"
                class="btn btn-medium btn-primary"
                [disabled]="readonly"
                (click)="toggleDatePicker()"
            >
                <i class="fa fa-calendar"></i>
            </button>
        </div>

        <div class="form-input-wrapper input-static" [ngClass]="{'with-errors': showErrors }">

            <div class="label-valued" [ngClass]="{'visible': control.value?.length > 0}">
                <span>{{label}}</span>
            </div>

            <input
                #textInput
                [attr.type]="'text'"
                [attr.name]="formControlName"
                [formControl]="control"
                [mask]="inputMask"
                [readonly]="!manual || readonly"
                (click)="toggleDatePicker()"
                [dropSpecialCharacters]="false"
                [attr.placeholder]="label"
                [attr.id]="'input-text-date-' + uniqueId"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (mouseenter)="onMouseEnter()"
                (mouseleave)="onMouseLeave()"
            />
        </div>
    </div>

    <label-optional *ngIf="optional"></label-optional>
</div>

<ng-template
    #customDay
    let-date
    let-currentMonth="currentMonth"
    let-selected="selected"
    let-disabled="disabled"
>
	<span
            class="custom-day"
            [class.weekend]="isWeekend(date)"
            [class.selected]="selected"
            [class.hidden]="date.month !== currentMonth"
            [class.disabled]="disabled"
    >
		{{ date.day }}
	</span>
</ng-template>
