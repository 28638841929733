import { Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs'
import { IAdvertiserReadDto, IAgencyDto, IBidderEntity, IDmpEntity, IProfileDto } from 'shared'
import { BaseApiService } from 'shared/services/base-api.service'
import { IAdvertiserBalanceDto, IAgencyBalanceDto } from 'app/users/models'


@Injectable()
export class ApiService extends BaseApiService {
    constructor(injector: Injector) {
        super(injector)
    }

    public profile(): Observable<IProfileDto> {
        return this.get<IProfileDto>(BaseApiService.API_URL_SEGMENTS.PROFILE)
    }

    public balance() {
        return this.get<IAdvertiserBalanceDto | IAgencyBalanceDto>(BaseApiService.API_URL_SEGMENTS.PROFILE_BALANCE)
    }

    public agencies(): Observable<IAgencyDto[]> {
        return this.get<IAgencyDto[]>(BaseApiService.API_URL_SEGMENTS.AGENCIES)
    }

    public advertisers(enabledOnly = false): Observable<(IAdvertiserReadDto & IBidderEntity & IDmpEntity)[]> {
        const url: string = enabledOnly === true
            ? `${BaseApiService.API_URL_SEGMENTS.ADVERTISERS}/enabled`
            : BaseApiService.API_URL_SEGMENTS.ADVERTISERS

        return this.get<(IAdvertiserReadDto & IBidderEntity & IDmpEntity)[]>(url)
    }
}
