import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { ICheckBoxGroupElement, IFilterValue } from '../../../../../models'
import { FilterBaseComponent } from '../filter-base-component'
import { AgencyFilterService } from '../../../../../services/agency-filter.service'

@Component({
    templateUrl: './agency-ids-filter.component.html',
    styleUrls: ['./agency-ids-filter.component.less'],
})
export class AgencyIdsFilterComponent extends FilterBaseComponent implements OnInit, OnDestroy {

    protected items: ICheckBoxGroupElement[] = []

    private readonly maxElementsCount = 5
    private readonly maxCharactersInFilter = 16

    constructor(
        @Inject(Injector) injector: Injector,
        private agencyFilterService: AgencyFilterService,
        private translate: TranslateService,
    ) {
        super(injector)
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.agencyFilterService.agencies$.subscribe(
            agencies => {
                this.items = agencies
            },
        ))
    }

    public generateFilterView(filter: IFilterValue): void {
        if (this.items.length === 0) {
            return
        }

        let filterValues = filter[this.key] as string[]

        let additionalItemsCount = 0
        if (filterValues.length > this.maxElementsCount) {
            additionalItemsCount = filterValues.length - this.maxElementsCount
            filterValues = filterValues.slice(0, this.maxElementsCount)
        }

        let result = filterValues
            .map(agency => this.items.find(item => item.value === agency).displayName)
            .map(name => {
                if (name.length > this.maxCharactersInFilter) {
                    return name.slice(0, this.maxCharactersInFilter) + '...'
                }
                return name
            })
            .join(', ')

        if (additionalItemsCount > 0) {
            result += ` + ${additionalItemsCount} ${ this.translate.instant('shared.selected') }`
        }

        this.filterView.emit(result)
    }

    public change(value: string[]): void {
        if (value.length) {
            this.hasValue.emit(true)
        } else {
            this.hasValue.emit(false)
        }
    }

    public ngOnDestroy(): void {
        this.model[this.key] = []
    }
}
