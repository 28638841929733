export enum EMetrics {
    Requests = 18,
    Bids = 19,
    WonAuctions = 20,
    NoContent = 21,
    Impressions = 22,
    Clicks = 23,
    Profit = 25,
    Payout = 26,
    Spend = 27,
    CTR = 28,
    FillRate = 29,
    BidRate = 30,
    WinRate = 32,
    eCPM = 33,
    CPC = 34,
    VTR = 35,
    Start = 36,
    FirstQuartile = 37,
    MidPoint = 38,
    ThirdQuartile = 39,
    Complete = 40,
    Skip = 41,
    Close = 42,
    CreativeView = 43,
    Pause = 44,
    Resume = 45,
    Mute = 46,
    Unmute = 47,
    Fullscreen = 48,
    ExitFullscreen = 49,
    Expand = 50,
    Collapse = 51,
    Errors = 52,
    Conversions = 53,
    Conversn1 = 63,
    Conversn2 = 64,
    Conversn3 = 65,
    Conversn4 = 66,
    Conversn5 = 67,
    Conversn6 = 68,
    Conversn7 = 69,
    Conversn8 = 70,
    Conversn9 = 71,
    Conversn10 = 72,
    AgencyCPM = 82,
    AgencyCPC = 83,
    AgencySpend = 84,
    ClientCPM = 85,
    ClientCPC = 86,
    ClientSpend = 87,
    UniqueImpressions = 90,
}