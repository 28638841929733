import { Directive } from '@angular/core'
import { NgControl } from '@angular/forms'


@Directive({
    selector: '[formControl][formControlAsNumber], [formControlName][formControlAsNumber]',
})
export class FormControlAsNumberDirective {
    constructor(public ngControl: NgControl) { }

    public ngOnInit(): void {
        this.ngControl.control.valueChanges
            .subscribe(val => {
                const asInt = parseFloat(val)
                this.ngControl.control.setValue(
                    isNaN(asInt) ? '' : asInt,
                    { emitEvent: false },
                )
            })
    }
}
