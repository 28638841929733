import { Directive, forwardRef, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms'


@Directive({
   selector: '[ngModelValidators][ngModel]',
   providers: [{
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NgModelValidator),
   }],
})
export class NgModelValidator implements Validator {
   @Input() ngModelValidators: ValidatorFn[];

   validate(control: AbstractControl): ValidationErrors | null {
      const result = this.ngModelValidators.reduce(
         (res, func) => {
            const validationResult = func(control)
            return validationResult !== null
               ? { ...res, ...validationResult }
               : res
         },
         {})

      return Object.keys(result).length === 0 ? null : result
   }
}