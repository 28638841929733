import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'

import { DtSearchService } from '../../../../services'


@Component({ template: '' })
export abstract class DTSearchForm<SearchFilterType> implements OnInit
{
    @ViewChild('filters', {static: true})
    public filtersForm: TemplateRef<any>

    public filter: SearchFilterType
    public default: SearchFilterType

    constructor(protected dtSearch: DtSearchService) { }

    public ngOnInit(): void {
        if (this.filtersForm) {
            this.dtSearch.enable(this.filtersForm, this.filter, this.default)
        }
    }
}
