<div class="checkbox-component"
     [ngClass]="{
        'active': checked,
        'inactive': !checked,
        'with-inner-label': showInnerLabel,
        'with-outer-label': showOuterLabel,
        'readonly': readonly || disabled
     }"
     (click)="toggle()"
>
    <div class="checkbox-container" [class.size-small]="size === 'small'">
        <i class="icm icm-checkmark"></i>
        <span class="checkbox-component-inner-label" *ngIf="showInnerLabel">
            {{innerLabel}}
        </span>
    </div>

    <div *ngIf="showOuterLabel"
         class="checkbox-component-outer-label"
         [innerHtml]="outerLabel">
    </div>

    <ng-content></ng-content>

    <div class="hint-wrapper" *ngIf="hint && hint.length > 0">
        <help-hint [text]="hint"></help-hint>
    </div>
</div>
