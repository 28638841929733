import { RxwebValidators } from '@rxweb/reactive-form-validators'

export const formFieldConfigMock = {
    validators: [RxwebValidators.required({ message: 'Description is required' })],
    dynamic: {
        dependOnCtrl: 'title',
        clearOnChange: true,
        validators: [RxwebValidators.maxLength({ value: 200 })],
    },
    disabledIfNew: false,
    disabledIfNotNew: true,
}
