import { Component, HostListener, Inject, Injector, OnDestroy } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { combineLatest, BehaviorSubject, Subscription, Observable } from 'rxjs'
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators'

import { AppTranslationService, AuthService, DictionariesService, SystemMessagesService, UiService } from '../../services'

@Component({ template: '' })
export abstract class PageComponent implements OnDestroy
{
    @HostListener('document:keydown.enter', ['$event'])
    onEnter(event): void {
        event.preventDefault()
    }

    public isNaN = Number.isNaN

    protected subscriptions: Subscription[]

    protected authService: AuthService

    protected i18n: TranslateService

    protected router: Router

    protected sysMessages: SystemMessagesService

    protected dictionaries: DictionariesService

    protected ui: UiService

    protected appTranslate: AppTranslationService

    public get currentUserId(): number { return this.authService.authData.id }

    public get isAdmin(): boolean { return this.authService.isAdmin }

    public get isAgency(): boolean { return this.authService.isAgency }

    public get isAdvertiser(): boolean { return this.authService.isAdvertiser }

    private localizationLoaded = new BehaviorSubject<boolean>(false)
    protected get localizationLoaded$(): Observable<boolean> { return this.localizationLoaded.asObservable() }

    protected get localeUpdated$(): Observable<string> {
        return combineLatest([
            this.localizationLoaded$,
            this.appTranslate.languageInitiallySetted$,
            this.appTranslate.languageChanged$,
        ]).pipe(
            filter(([loaded, setted, lang]) =>
                this.isAdmin || (loaded === true && setted === true && lang !== undefined && lang !== ''),
            ),
            map(data => data[2]),
            distinctUntilChanged(),
        )
    }


    constructor(@Inject(Injector) injector: Injector) {
        this.authService = injector.get(AuthService)
        this.i18n = injector.get(TranslateService)
        this.appTranslate = injector.get(AppTranslationService)
        this.router = injector.get(Router)
        this.sysMessages = injector.get(SystemMessagesService)
        this.dictionaries = injector.get(DictionariesService)
        this.ui = injector.get(UiService)

        this.subscriptions = [
            this.i18n.get('INIT').pipe(
                filter(value => (value || '').length > 0),
                first(),
                tap(() => this.localizationLoaded.next(true)),
            ).subscribe(),
        ]
    }

    protected subscribeOn(sub: Subscription): void {
        this.subscriptions.push(sub)
    }

    protected subscribeMany(subs: Subscription[]): void {
        this.subscriptions = this.subscriptions.concat(subs)
    }

    protected unsubscribeToAll(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    public ngOnDestroy(): void {
        this.unsubscribeToAll()
    }
}