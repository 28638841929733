import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'decimalSuffix' })
export class DecimalSuffixPipe implements PipeTransform {

    transform(value: number, args?: any): string {
        let exp, rounded,
            suffixes = ['k', 'M', 'G', 'T', 'P', 'E']

        if (isNaN(value)) {
            return ''
        }

        if (value < 1000) {
            return value.toString()
        }

        exp = Math.floor(Math.log(value) / Math.log(1000))

        return (value / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1]
    }
}
