<div class="wrapper grid">
    <div class="cell cell-20">
        <dropdown [listItems]="list"
                  [placeholder]="'app.quick-report.props.filtersList'|translate"
                  [nullable]="false"
                  [formControl]="selectedFilter"
        ></dropdown>
    </div>

    <div class="cell cell-15 padding-left-1">
        <dropdown size="small"
                  [listItems]="eqList"
                  [nullable]="false"
                  [formControl]="opModel"
                  [scrollable]="false"
                  [readonly]="!selectedFilter.value"
        ></dropdown>
    </div>
    <div class="cell cell-65 padding-left-1">
        <selected-filter [formControl]="values"
                         [readonly]="!selectedFilter.value"
                         [columnId]="filterParams?.id"
                         [label]="filterParams?.name"
                         [filterType]="filterParams?.type"
                         [filtersWithIds]="filtersWithIds"
        ></selected-filter>
    </div>
</div>
