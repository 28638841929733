<div class="chips-dropdown-wrapper" [ngClass]="cssClass">
    <div class="label-valued" [ngClass]="{'visible': selected}">
        <span>{{ label }}</span>
    </div>

    <div class="chips-dropdown-search filter-type-{{filterType}}">
        <div class="current-value" [class.with-chips]="selected" (click)="toggleList($event)">

            <ul class="chips-list">
                <li *ngFor="let chip of selectedChips; index as i" class="chip-item">
                    <div class="text">{{ chip.name }}</div>
                    <i class="fa fa-remove remove-chip" (click)="removeChip(chip, i)"></i>
                </li>

                <li class="search-container">
                    <input #search
                           [placeholder]="
                                 filterType === filterTypesEnum.plainText
                                    ? ('app.quick-report.props.manualInput' | translate)
                                    : ('shared.search' | translate)
                               "
                           (keyup.enter)="addManual()"
                           class="search-input"
                    />
                </li>
            </ul>

            <div *ngIf="filterType === filterTypesEnum.plainText" class="actions">
                <i *ngIf="inputHasValue" class="fa fa-plus" (click)="addManual()"></i>
                <i class="fa fa-remove" (click)="clearAll()"
                   [hidden]="isDisabled || !clearable  || !selected">
                </i>
            </div>
        </div>

        <div *ngIf="filterType === filterTypesEnum.selectable"
             class="action-icon fa"
             [ngClass]="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
             [hidden]="isDisabled && selected"
             (click)="toggleList($event)"
        ></div>

        <div class="list-wrapper" [hidden]="isDisabled">
            <ul class="list qreport">
                <ng-container *ngFor="let item of itemsList; index as i; trackBy: trackByFn">
                    <li *ngIf="!item.selected" class="item" (click)="itemClick(i)">
                        <ng-container *ngIf="item.name && item.name.length > 0">{{ item.name }}</ng-container>
                    </li>
                </ng-container>
                <div class="no-results" *ngIf="!loading && itemsList.length === 0">
                    {{ 'shared.noResults'|translate }}
                </div>
                <div *ngIf="loading && itemsList.length === 0" class="no-results">
                    {{ 'shared.loading'|translate }}
                </div>
            </ul>
        </div>
    </div>
</div>
