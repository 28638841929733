import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { Subscription } from 'rxjs'

import { FormValidationService } from 'shared/services/form-validation.service'
import { IValidationMessage } from 'shared/models/forms/IValidationMessage'

@Component({
    selector: 'form-field-validation',
    templateUrl: './form-field-validation.component.html',
})
export class FormFieldValidationComponent
    implements OnInit, OnDestroy {
    private sub$: Subscription

    @Input()
    public set fieldName(value) { this._fieldName = value }
    private _fieldName = ''

    public messages: string[] = []

    constructor(
        private validator: FormValidationService,
    ) { }

    public ngOnInit(): void {
        this.sub$ = this.validator.lastMessage.subscribe(message => this.parseValidationMessage(message))
    }

    public ngOnDestroy(): void {
        if (this.sub$) {
            this.sub$.unsubscribe()
        }
    }

    private parseValidationMessage(message: IValidationMessage): void {
        this.messages = []

        if (!message || !message.apiResponse || !message.apiResponse.errorDetails) {
            return
        }

        message.apiResponse.errorDetails
            .filter(e => e.errorDescriptor.toLowerCase() === this._fieldName.toLowerCase())
            .forEach(e => this.messages = this.messages.concat(e.errorMessage))
    }
}
