<div class="url-list" *ngIf="list">

    <div class="hint-wrapper" *ngIf="hint && hint.length > 0">
        <help-hint [text]="hint"></help-hint>
    </div>

    <div class="list-item"
         *ngFor="let item of list; index as i; trackBy: trackByFn"
         [@itemAnimation]
         [@.disabled]="!animationsOn">

        <div>
            <div class="actions-wrapper" [ngClass]="{'last': i == list.length - 1}">
                <i class="fa fa-times" [hidden]="list.length <= 1" (click)="delete(i)"></i>
                <i class="fa fa-plus" [hidden]="i < list.length - 1 || list.length == maxLength" (click)="add()"></i>
            </div>

            <div class="input-wrapper">
                <input-text [(ngModel)]="item.value"
                            (ngModelChange)="emitValue()"
                            [multiline]="multiline"
                            [optional]="optional"
                            [symbolsCount]="symbolsCount"
                            [showCounter]="true"
                            [label]="label"></input-text>
            </div>
        </div>
    </div>
</div>

