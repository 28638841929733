import { EQuickFilterType } from '../api'

export interface IDropdownItem {
    id: string
    name: string
    disabled?: boolean
}

export interface IFilterDropdownItem extends IDropdownItem{
    type: EQuickFilterType
}
