import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'

export declare class ApiHttpErrorResponse extends HttpErrorResponse  {
    readonly error: {[key: string]: string[]} | null
    constructor(init: {
        error?: { [key: string]: string[] }
        headers?: HttpHeaders
        status?: number
        statusText?: string
        url?: string
    })
}
