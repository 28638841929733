import {
    Component, Input, EventEmitter, Output, ChangeDetectionStrategy, ViewEncapsulation,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { IKeyValueItem, IValuesListItem, TValueListMode } from 'shared/models'


@Component({
    selector: 'values-list',
    templateUrl: 'values-list.component.html',
    styleUrls: ['values-list.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValuesListComponent {

    @Input() public mode: TValueListMode = 'block'

    @Input() public deletable = true

    @Input() public selectable = true

    @Input() public disableAfterSelect = false

    @Input() public deleteAfterSelect = true

    @Input() public clearAfterSelect = false

    @Input() public itemSeparator = ''

    @Input() public showKeys = false

    @Input()
    public set items(value: IKeyValueItem<string, string>[]) {
        this.itemsValue.next(value.map(item => (
            {
                item: item,
                selected: false,
            } as IValuesListItem),
        ))
    }
    public get items$() { return this.itemsValue.asObservable() }
    protected itemsValue = new BehaviorSubject<IValuesListItem[]>([])

    @Output() public itemSelected = new EventEmitter<[number, IKeyValueItem<string, string>]>()

    @Output() public itemDeleted = new EventEmitter<[number, IKeyValueItem<string, string>]>()

    @Output() public itemsCleared = new EventEmitter<void>()



    public onItemDelete(index: number) {
        this.deleteItem(index)
    }

    public onItemSelect(index: number) {
        if (!this.selectable) {
            return
        }

        if (this.clearAfterSelect) {
            this.clearItems()
        } else if (this.deleteAfterSelect) {
            this.deleteItem(index)
        } else if (this.disableAfterSelect) {
            this.selectItem(index)
        }
    }


    private clearItems() {
        this.itemsValue.next([])
        this.itemsCleared.emit()
    }

    private deleteItem(index: number) {
        this.itemDeleted.emit([index, this.itemsValue.getValue()[index].item])
        const items: IValuesListItem[] = [].concat(this.itemsValue.getValue()).splice(index, 1)
        this.itemsValue.next(items)
    }

    private selectItem(index: number) {
        const items: IValuesListItem[] = [].concat(this.itemsValue.getValue())

        if (items[index].selected === false) {
            items[index].selected = true

            this.itemSelected.emit([index, items[index].item])

            this.itemsValue.next(items)
        }
    }
}