import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { merge } from 'rxjs'
import { tap } from 'rxjs/operators'

import { IQuickFilter, IQuickFilterOps } from '../../../../models'
import { IDropdownItem, IFilterDropdownItem } from '../../../../index'

@Component({
    selector: 'filter-select-wrapper',
    templateUrl: './filter-select-wrapper.component.html',
    styleUrls: ['./filter-select-wrapper.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilterSelectWrapper),
            multi: true,
        },
    ],
})
export class FilterSelectWrapper implements ControlValueAccessor {

    @Input()
    public list: IFilterDropdownItem[]

    @Input()
    public filtersWithIds: number[] = []

    protected eqList: IDropdownItem[] = [
        {
            id: IQuickFilterOps.equal,
            name: '=',
        },
        {
            id: IQuickFilterOps.notEqual,
            name: '!=',
        },
    ]

    public propagateChange = (_: any) => {}
    public propagateTouched = (_: any) => {}

    public registerOnChange(fn: any): void {
        this.propagateChange = fn
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouched = fn
    }

    public writeValue(value: IQuickFilter): void {
        if (!value) {
            return
        }

        const listItem = this.list.find(item => item.id === value.columnId.toString())

        if (listItem) {
            listItem.disabled = true
            this.selectedFilter.setValue(value.columnId.toString(), { emitEvent: false })
            this.opModel.setValue(value.op, { emitEvent: false })
            this.values.setValue(value.values, { emitEvent: false })
            this.filterParams = listItem
            this.emitData()
        }
    }

    protected selectedFilter = new FormControl<string>('')
    protected opModel = new FormControl<IQuickFilterOps>(IQuickFilterOps.equal)
    protected values = new FormControl<string[]>([])
    protected filterParams: IFilterDropdownItem

    constructor() {
        const resetFilter = this.selectedFilter
            .valueChanges
            .pipe(
                takeUntilDestroyed(),
                tap(id => {
                    this.filterParams = this.list.find(i => i.id === id)

                    this.values.setValue([], { emitEvent: false })
                    this.opModel.setValue(IQuickFilterOps.equal, { emitEvent: false })
                }))

        merge(
            resetFilter,
            this.opModel.valueChanges,
            this.values.valueChanges,
        )
            .pipe(takeUntilDestroyed(), tap(() => this.emitData()))
            .subscribe()
    }

    private emitData(): void {
        this.propagateChange({
            columnId: parseInt(this.selectedFilter.value),
            values: this.values.value,
            op: this.opModel.value,
        })
    }
}
