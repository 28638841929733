import { Component, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'

import { PaginationService } from 'shared/services/pagination.service'

@Injectable({
  providedIn: 'root',
})
export class PaginationGuardService  {
  constructor(private pagination: PaginationService) {}

  public canDeactivate(
    _component: Component,
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ): boolean {
    const current = state.url.split('/')
    const next = nextState.url.split('/')

    if (next.length <= current.length) {
      const lastSection = route.url[0].path
      this.pagination.resetPagination(lastSection)
    }

    return true
  }
}
