import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { fromEvent, Subscription } from 'rxjs'

import { ILink } from '../../../models'

@Component({
    selector: 'button-with-dropdown',
    templateUrl: './button-with-dropdown.component.html',
    styleUrls: ['./button-with-dropdown.component.less'],
})
export class ButtonWithDropdownComponent implements OnInit, OnDestroy {
    @Input() public buttonTitle: string
    @Input() public buttonDropdownItems: ILink[] = []

    protected buttonActive = false

    private subscription: Subscription

    public ngOnInit(): void {
        this.subscription = fromEvent(document, 'click').subscribe(() => {
            this.buttonActive = false
        })
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe()
    }
}
