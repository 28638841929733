import { Injectable } from '@angular/core'

import { IApiPaging, IApiPagingWithType } from 'shared/models'


@Injectable({
    providedIn: 'root',
})
export class PaginationService {
    private readonly storageKeyPrefix = 'pagination'
    private readonly keys: Set<string> = new Set<string>()

    constructor() {
        for (let index = 0; index < localStorage.length; index++) {
            const key = localStorage.key(index)
            if (key.startsWith(this.storageKeyPrefix)) {
                this.keys.add(key)
            }
        }
    }

    public getPagination(section: string): IApiPaging | undefined {
        const pagination = localStorage.getItem(`${ this.storageKeyPrefix }-${ section }`)
        return pagination && JSON.parse(pagination)
    }

    public savePagination(section: string, paging: IApiPagingWithType): void {
        const { currentPage, pageSize, type } = paging
        const pagination = JSON.stringify({
            currentPage,
            pageSize,
            type,
        })
        const key = `${ this.storageKeyPrefix }-${ section }`
        localStorage.setItem(key, pagination)
        this.keys.add(section)
    }

    public resetPagination(section: string): void {
        const key = `${ this.storageKeyPrefix }-${ section }`
        localStorage.removeItem(key)
        this.keys.delete(section)
    }

    public resetAllPagination(): void {
        for (const key of this.keys.values()) {
            localStorage.removeItem(key)
        }
        this.keys.clear()
    }
}
