import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'

import { Observable, throwError } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'

import {
    IApiDto,
    IApiFieldError,
    IApiListRequest,
    IApiListResponse,
    IDictionaryItem,
    IValidationMessage,
} from 'shared/models'
import { UiService } from './ui.service'
import { environment } from 'environments/environment'
import { FormValidationService } from './form-validation.service'
import { AppTranslationService } from './app-translation.service'
import { ELanguage } from 'shared/models/enums/ELanguage'
import { UPDATE_MESSAGE_ERROR } from '../models/const'


@Injectable({ providedIn: 'root' })
export class BaseApiService {
    private _baseUrl: string
    public get baseUrl(): string { return this._baseUrl }

    private _headers: HttpHeaders
    protected get headers(): HttpHeaders { return this._headers }

    private _multipartHeaders: HttpHeaders
    protected get multipartHeaders(): HttpHeaders { return this._multipartHeaders }

    private _fileHeaders: HttpHeaders
    protected get fileHeaders(): HttpHeaders { return this._fileHeaders }

    protected http: HttpClient
    protected ui: UiService
    protected validation: FormValidationService
    protected appTranslation: AppTranslationService

    constructor(injector: Injector) {
        this.http = injector.get(HttpClient)
        this.ui = injector.get(UiService)
        this.validation = injector.get(FormValidationService)
        this.appTranslation = injector.get(AppTranslationService)

        this._baseUrl = environment.baseUrl

        this._headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
        this._fileHeaders = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8')
        this._multipartHeaders = new HttpHeaders()
    }


    public static readonly API_URL_SEGMENTS = {
        SETTINGS: 'settings',
        PROFILE: 'profiles',
        PROFILE_BALANCE: 'profiles/balance',
        ADVERTISER_BALANCE: 'balance',
        AGENCIES_BALANCE: 'balance',
        BALANCE_LOG: 'users/balance/logs',
        USERS: 'users',
        ADCAMPAIGNS: 'campaigns',
        ADCAMPAIGNS_LIST: 'campaigns/list',
        ACTS: 'acts',
        ACTS_LIST: 'acts/list',
        ACTS_STORAGE: 'actstorage',
        ACTS_CHILDS: 'attached/childs',
        DISTRIBUTION: 'acts/distribution',
        DISTRIBUTION_RESET: 'distributions/reset',
        ACT_STATUS_RESET: 'status/reset',
        ADCLIENTS: 'adclients',
        ADCLIENTS_LIST: 'adclients/list',
        ADGROUPS: 'groups',
        ADGROUPS_FAST: 'groups/fast/',
        TARGETINGFILES: {
            CRUD: 'targetingfiles',
            VALIDATE: 'targetingfiles/validate',
        },
        AGENCIES: 'agencies',
        ADVERTISERS: 'vendors',
        SSP: 'ssps',
        SSPS_FOR_MATCHING: 'ssps/matching/partners',
        CONTAINERS: 'dmp/containers',
        GOALS: 'dmp/containers/{id}/goals',
        SEGMENTS: 'dmp/containers/{id}/segments',
        SEGMENT_UPLOAD: 'dmp/containers/{containerId}/segments/{segmentId}/upload',
        TARGETS: 'dmp/targets',
        MATCHING: 'dmp/matching',
        APP_DATA: 'dmp/app',
        SEGMENTS_FOR_AUDIENCES: 'bidder/auditories/segments',
        MATCHING_PARTNERS: 'dmp/matching/partners',
        AUDIENCES: 'bidder/auditories',
        PREDICTTYPES: 'bidding_strategies',
        CREATIVES: {
            LIST: 'creatives',
            BANNER_IMAGES: 'creatives/banners/images',
            BANNER_HTML: 'creatives/banners/htmls',
            BANNER_HTML5: 'creatives/banners/html5',
            VIDEO_LINEAR_INLINE: 'creatives/videos/inlines',
            VIDEO_LINEAR_WRAPPER: 'creatives/videos/wrappers',
            VIDEO_LINEAR_INTERACTIVE: 'creatives/videos/vpaids',
            VIDEO_NONLINEAR: 'creatives/videos/nonlinears',
            NATIVE_MOBILE: 'creatives/natives/mobiles',
            FILES: 'creatives/files',
        },
        DICTIONARIES: {
            GEOS: 'geos',
            ZIP: 'zipcodes',
            ZIP_SEARCH: 'zipcodes/search',
            DMA: 'dmas',
            DMA_SEARCH: 'dmas/search',
            GEOLOCATIONS: '',
            COUNTRIES: 'countries',
            TIMEZONES: 'countries/{id}/timezones',
            AUDIENCES: 'audiences',
            DEVICES: 'devices',
            CATEGORIES: 'categories',
            MANUFACTURERS: 'manufacturers',
            OPERATINGSYSTEMS: 'operatingsystems',
            CARRIERS: 'carriers',
            BROWSERS: 'browsers',
            LANGUAGES: 'languages',
            SSPS: 'ssps/list',
            POSITIONS: 'positions',
            DOMAINS: 'domains',
            APPLICATIONS: 'applications',
            IPS: 'ips',
            PUBLISHERS: 'publishers',
            SITEIDS: 'siteids',
            WEBAPP: '',
            TARGETS: 'targets',
            TARGETS_BY_IDS: 'targets/ids',
            THIRDPARTYAUDIENCES: 'segments3pa',
            SHARED_THIRDPARTYAUDIENCES: 'segments3pa/shared',
        },
        VALIDATORS: 'validate',
        CONVERSIONS: {
            COLUMNS: 'conversions/log/columns',
            FILTERS_SEARCH: 'conversions/log/filters',
        },
        REPORTS: {
            PUBLIC: 'shared/report/{id}',
            LIST: 'reports',
            STATS_CONVERSIONS: 'conversions/log',
            DIMENSIONS: 'reports/statcolumns?type=1',
            SHARED_DIMENSIONS: 'reports/shared/statcolumns?type=1',
            METRICS: 'reports/statcolumns?type=2',
            SHARED_METRICS: 'reports/shared/statcolumns?type=2',
            TIMEZONES: 'reports/filters/timezoneoffsets',
            SHARED_TIMEZONES: 'reports/filters/shared/timezoneoffsets',
            FILTERSSEARCH: 'reports/filters',
            FILTERS: {
                AGENCIES: 'agencies',
                ADVERTISERS: 'vendors',
                ADCAMPAIGNS: 'campaigns',
                ADGRROUPS: 'groups',
                AUDIENCES: 'audiences',
                ADCLIENTS: 'adclients',
                CREATIVES: 'creatives',
                ADTYPES: 'creative_types',
                SSPS: 'ssps',
                COUNTRIES: 'countries',
                BROWSERS: 'browsers',
                DEVICES: 'devices',
                OPERATINGSYSTEMS: 'operatingsystems',
                THIRDPARTYVENDOR: 'segments3pa',
                MANUFACTURERS: 'manufacturers',
                CATEGORIES: 'categories',
                CARRIERS: 'carriers',
            },
            QUICK_REPORT: 'stats',
            REPORT: 'stats',
            DASHBOARD: 'stats/dashboard',
            CSV: 'stats/csv',
            XLSX: 'stats/xlsx',
            PUBLIC_XLSX: 'stats/shared/xlsx',
        },
    }

    public get<T>(url: string): Observable<T> {
        return this.http
            .get<T>(this._baseUrl + url, { headers: this.headers })
            .pipe(catchError(error => this.handleError(error)))
    }

    public delete<T>(url: string): Observable<T> {
        return this.http
            .delete<T>(this._baseUrl + url, { headers: this.headers })
    }

    public getFile(url: string, contentType: string, fileName: string): Observable<Blob> {
        return this.http
            .get(this._baseUrl + url, {
                headers: this.headers,
                responseType: 'blob',
            })
            .pipe(
                catchError(error => this.handleError(error)),
                tap(response => {
                    const file = new File([response], fileName, { type: contentType })
                    const anchor = document.createElement('a')
                    anchor.href = window.URL.createObjectURL(file)
                    anchor.download = fileName
                    anchor.click()
                }),
            )
    }

    public postFile(
        url: string,
        contentType: string,
        body: Record<string, any>,
        fileName: string,
    ): Observable<Blob> {
        return this.http
            .post(this._baseUrl + url, body, {
                headers: this.headers,
                responseType: 'blob',
            })
            .pipe(
                catchError(error => this.handleError(error)),
                tap(response => {
                    const file = new File([response], fileName, { type: contentType })
                    const anchor = document.createElement('a')
                    anchor.href = window.URL.createObjectURL(file)
                    anchor.download = fileName
                    anchor.click()
                }),
            )
    }

    public post<RequestType, ResponseType>(url: string, request?: RequestType): Observable<ResponseType> {
        return this.http
            .post<ResponseType>(this._baseUrl + url, request ? JSON.stringify(this.cleanNullFields(request)) : '', { headers: this.headers })
            .pipe(catchError(error => this.handleError(error)))
    }

    public create<RequestType>(url: string, request?: RequestType): Observable<IApiDto> {
        return this.post<RequestType, IApiDto>(url, request)
    }

    public createMultipart(url: string, formData: FormData): Observable<IApiDto> {
        return this.http
            .post<IApiDto>(this._baseUrl + url, formData, { headers: this.multipartHeaders })
            .pipe(catchError(error => this.handleError(error)))
    }

    public update<RequestType>(url: string, request?: RequestType): Observable<any> {
        return this.http
            .put(this._baseUrl + url, request ? JSON.stringify(this.cleanNullFields(request)) : '', { headers: this.headers })
            .pipe(catchError(error => this.handleError(error)))
    }

    public setItemStatus(urlSegment: string, id: number, status: number): Observable<any> {
        const body = {
            id: id,
            status: status,
        }
        return this.update<any>(`${urlSegment}/${id}/status`, body)
    }

    public getList<T>(url: string, request?: IApiListRequest): Observable<T[]> {
       let resultUrl = url
       const params = {}

       if (request && request.additionalParams) {
          Object.keys(request.additionalParams).forEach((key: string) => {
             params[key] = request.additionalParams[key]
          })
       }

       if (request && request.searchTerm && request.searchTerm.length > 0) {
          params['searchTerm'] = encodeURI(request.searchTerm)
       }

       if (request && request.type) {
          params['type'] = request.type
       }

       if (Object.keys(params).length > 0) {
          resultUrl += '?'
          resultUrl += Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&')
       }


       return this.get<T[]>(resultUrl)
    }

    public getPagedList<T>(url: string, request?: IApiListRequest): Observable<IApiListResponse<T>> {
        let cleanedUrl = url

        const hasEndedSlash: boolean = url[url.length - 1] === '/'

        if (hasEndedSlash) {
            cleanedUrl = url.slice(0, url.length - 1)
        }

        if (request) {
            cleanedUrl = `${cleanedUrl}?pageSize=${request.paging.pageSize}&page=${request.paging.currentPage}`

            if (request.additionalParams) {
                Object.keys(request.additionalParams).forEach((key: string) => {
                    cleanedUrl += `&${key}=${request.additionalParams[key]}`
                })
            }
        }

        if (request && request.searchTerm && request.searchTerm.length > 0) {
            cleanedUrl += '&searchTerm=' + encodeURI(request.searchTerm)
        }

        return this.get<IApiListResponse<T>>(cleanedUrl)
    }

    protected cleanNullFields(source): any {
        const cleaned = JSON.parse(JSON.stringify(source))
        const keysForRemove: string[] = []

        Object.getOwnPropertyNames(cleaned).forEach(p => {
            if (cleaned[p] === null) {
                keysForRemove.push(p)
            }
        })

        keysForRemove.forEach(key => delete cleaned[key])

        return cleaned
    }

    protected handleError(error: HttpErrorResponse): Observable<never> {
        let errorMessage: IValidationMessage

        if (error.message === UPDATE_MESSAGE_ERROR) {
            if (this.ui) {
                this.ui.stopBackendAction()
            }

            return throwError(() => error)
        } else if (error.status === 400) {
            errorMessage = {
                apiResponse: {
                    errorMessage: error.message,
                    errorDetails: <IApiFieldError[]>[],
                    isSuccessful: false,
                },
            }

            if (error?.error && typeof error.error !== 'object') {
                errorMessage.apiResponse.errorMessage = error.error
            }

            error?.error && typeof error.error === 'object' && Object.keys(error.error).forEach(key =>
                errorMessage.apiResponse.errorDetails.push({
                    errorDescriptor: key,
                    errorMessage: error.error[key],
                }),
            )
        }
        else if (error.status === 500) {
            errorMessage = {
                apiResponse: {
                    errorDetails: [],
                    errorMessage: 'Server error: ' + error.message,
                    isSuccessful: false,
                },
            }
        }
        else if (error.status === 404) {
            errorMessage = {
                apiResponse: {
                    errorDetails: [],
                    errorMessage: 'Server error: ' + error.message,
                    isSuccessful: false,
                },
            }
        }
        else if (error.status === 0) {
            errorMessage = {
                apiResponse: {
                    errorDetails: [],
                    errorMessage: 'API Host was not setup correctly',
                    isSuccessful: false,
                },
            }
        }
        else {
            console.error(`Backend returned code ${error.status}, body was: ${error.error}`)
        }

        if (this.ui) {
            this.ui.stopBackendAction()
        }

        if (this.validation) {
            this.validation.setMessage(errorMessage)
        }

        return throwError(() => error)
    }

    public getDictionary(urlSegment: string, parentId?: number): Observable<IDictionaryItem[]> {
        const language = this.appTranslation.getCurrentLanguage() === 'en' ? ELanguage.english : ELanguage.russian

        const url = parentId
            ? `${urlSegment}/${parentId}?language=${language}`
            : `${urlSegment}?language=${language}`

        return this.get<IDictionaryItem[]>(url)
    }

    public searchInDictionary(urlSegment: string, term: string): Observable<IDictionaryItem[]> {
        const url = `${urlSegment}/search/?searchTerm=${encodeURI(term)}`
        return this.get<IDictionaryItem[]>(url)
    }

    public timezones(countryId: number): Observable<IDictionaryItem[]> {
        const language = this.appTranslation.getCurrentLanguage() === 'en' ? ELanguage.english : ELanguage.russian
        const url = BaseApiService.API_URL_SEGMENTS.DICTIONARIES.TIMEZONES.replace('{id}', countryId.toString()) + `?language=${language}`
        return this.get<IDictionaryItem[]>(url)
    }

    public getVersion(): Observable<string> {
       return this.http.get<unknown>('.version', {
          headers: this.headers,
          params: {
             timestamp: new Date().getTime(),
          },
       }).pipe(
          map(version => String(version)),
       )
    }
}
