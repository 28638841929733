import { IHaveStatus } from 'shared/models'
import { IUser } from './IUser'

export interface IAgency extends IUser, IHaveStatus {
    name?: string
    webSiteUrl?: string
    currency?: number
    language?: number
    countryId?: number
    timezoneId?: number
    agencyFeePercent?: number
}
