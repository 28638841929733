import {
    Component,
    OnChanges,
    Input,
    ElementRef,
    IterableDiffers,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    forwardRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    SimpleChanges,
    IterableDiffer,
} from '@angular/core'
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'
import { fromEvent, Observable, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

import { EKeyboardKeys } from 'shared/models/enums/EKeyboardKeys'
import { IDropdownItemTyped } from '../../../models/ui/IDropDownItemTyped'

interface IDropdownSearchSelectableDropdownItem extends IDropdownItemTyped<unknown> {
    selected?: boolean
}

type Directions = 'top' | 'bottom' | 'auto'


@Component({
    selector: 'dropdown',
    templateUrl: 'dropdown.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropdownComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent
    implements OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor {

    private readonly DEFAULT_LIST_HEIGHT: number = 200

    private iterableDiffer: IterableDiffer<unknown>
    private searchInputStream: Observable<KeyboardEvent>
    private searchInputStreamSubscription: Subscription

    protected selected = false
    protected opened = false
    protected displayName = ''
    protected uniqueId: number = Math.floor(Math.random() * 100000)
    protected filteredListItems: IDropdownSearchSelectableDropdownItem[] = []
    protected value: unknown

    protected get cssClass(): Record<string, boolean> {
        return {
            'no-label': this.noLabel,
            'without-search': !this.searchable,
            'opened': this.opened,
            'closed': !this.opened,
            'selected': this.selected,
            'disabled': this.isDisabled,
            'no-scroll': this.scrollable === false,
            'invalid': !this.isValid,
            'direction-top': this.openDirection === 'top',
            'direction-bottom': this.openDirection === 'bottom' || this.openDirection === 'auto',
            'hint-inside': this.hint && this.hint.length > 0 && this.hintInside,
        }
    }

    //#region inputs and outputs

    @Input()
    public searchable = true

    @Input()
    public size?: string = 'medium'

    @Input()
    public nullable?: boolean = true

    @Input()
    public scrollable = true

    @Input()
    public placeholder = ''

    @Input()
    public readonly = false

    @Input()
    public required = false

    @Input()
    public listItems: IDropdownItemTyped<unknown>[] = []

    @Input()
    public showIds = false

    @Input()
    public resetAfterSelect = false

    @Input()
    public hideDisabledItems = false

    @Input()
    public logging = false

    @Input()
    public hint?: string = ''

    @Input()
    public hintInside = false

    @Input()
    public set openDirection(value: Directions) {
        this._openDirection = value
        this.setupToggleDirection()
    }

    public get openDirection(): Directions {
        return this._openDirection
    }

    private _openDirection: Directions = 'auto'

    private openDirectionSubscription: Subscription

    //#endregion


    @ViewChild('currentValue')
    public currentValue: ElementRef

    @ViewChild('topLevelElement')
    public topLevelElement: ElementRef

    @ViewChild('searchInput')
    public searchInput: ElementRef

    @ViewChild('list')
    public listElement: ElementRef

    protected get noLabel(): boolean {
        return !this.placeholder || this.placeholder.length === 0
    }

    protected get isDisabled(): boolean {
        return this.readonly || !this.listItems || this.listItems.length === 0
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private _element: ElementRef,
        private _iterableDiffers: IterableDiffers,
    ) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null)

        this.searchInputStreamSubscription = fromEvent(document, 'click').subscribe((event: MouseEvent) => {
            const target = event.target as HTMLElement

            this.log('[' + this.uniqueId + '] DOCUMENT CLICK: ', target)

            if (target.hasAttribute('data-dropdown-id')) {
                if (target.getAttribute('data-dropdown-id') !== this.uniqueId.toString()) {
                    this.log('closed by another dropdown')
                    this.setOpenState(false)
                }
            } else {
                this.log('closed by outside click')
                this.setOpenState(false)
            }
        })
    }

    //#region lifecycle hooks
    public ngOnChanges(changes: SimpleChanges): void {
        const listChange = this.iterableDiffer.diff(this.listItems)
        this.log('ngOnChanges: listChange [' + this.uniqueId + ']: ', listChange)

        if (listChange) {
            this.filteredListItems = this.listItems as IDropdownSearchSelectableDropdownItem[]
        }

        if (changes.placeholder) {
            this.setDefaultDisplayName()
        }

        this.setValue()
    }

    public ngAfterViewInit(): void {
        this.searchInputStream = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'keyup')

        this.searchInputStream.pipe(
            debounceTime(200),
            distinctUntilChanged(),
        ).subscribe((event: KeyboardEvent) => {
            if (!this.opened) {
                return
            }

            event.stopImmediatePropagation()

            switch (event.key) {
                case EKeyboardKeys.ArrowUp:
                case EKeyboardKeys.ArrowDown:
                    this.navigateByArrows(event.key)
                    break
                case EKeyboardKeys.Enter:
                    this.selectItemByEnter()
                    break
                case EKeyboardKeys.Escape:
                    this.closeListByEscape()
                    break
                default:
                    this.findAndResetArrowSelectedItem()
                    this.filterItems((event.target as HTMLInputElement).value)
            }
        })

        const rootElement = this._element.nativeElement
        rootElement.addEventListener('focus', () => rootElement.classList.add('focused'))
        rootElement.addEventListener('blur', () => rootElement.classList.remove('focused'))

        rootElement.addEventListener('keypress', (event: KeyboardEvent) => {
            if (!this.opened && event.key === EKeyboardKeys.Enter) {
                this.setOpenState(true)
            }
        })

        if (!this.openDirection || this.openDirection === 'auto') {
            this.calculateCurrentDirection()
            this.setupToggleDirection()
        }
    }

    public ngOnDestroy(): void {
        this.searchInputStreamSubscription.unsubscribe()
        if (this.openDirectionSubscription) {
            this.openDirectionSubscription.unsubscribe()
        }
    }
    //#endregion


    //#region ControlValueAccessor realization
    public writeValue(value: unknown): void {
        this.log('[' + this.uniqueId + '] writeValue:', value)

        if (value === null || value === undefined) {
            this.value = null
            this.setDefaultDisplayName()
        } else {
            this.value = value
            this.setValue()
        }

        this.cdr.markForCheck()
    }

    private propagateChange = (_value: unknown): void => undefined

    public registerOnChange(fn: () => void): void {
        this.propagateChange = fn
    }

    private propagateTouched = (): void => undefined

    public registerOnTouched(fn: () => void): void {
        this.propagateTouched = fn
    }
    //#endregion


    //#region private methods
    private log(...log: any[]): void {
        if (!this.logging)
            return

        console.log(...log)
    }

    private get isValid(): boolean {
        return this.required ? !!this.value : true
    }

    private setDefaultDisplayName(): void {
        this.displayName = this.placeholder
            ? this.placeholder
            : ''
    }

    private setValue(): void {
        this.log('[' + this.uniqueId + ']: setValue: ', this.value)
        this.log('this.filteredListItems: ', this.filteredListItems)

        if (this.value !== undefined && this.value !== null) {
            this.selected = false
            for (const i in this.filteredListItems) {
                if (this.filteredListItems[i].id === this.value) {
                    this.displayName = this.filteredListItems[i].name
                    this.selected = true
                }
            }
        } else {
            this.setDefaultDisplayName()
            this.selected = false
        }
    }

    private emitModelValue(value: unknown = null): void {
        const emittedValue = value !== null ? value : this.value
        this.propagateChange(emittedValue)
        this.log('this.emitModelValue: ', emittedValue)
    }

    private setOpenState(isOpened: boolean): void {
        if (this.isDisabled) {
            return
        }

        this.log('setOpenState: ', isOpened)

        this.opened = isOpened

        this.findAndResetArrowSelectedItem()

        if (isOpened) {
            setTimeout(() => {
                this.searchInput.nativeElement.value = ''
                this.searchInput.nativeElement.focus()
            })
        } else {
            this.filterItems('')
        }

        this.cdr.detectChanges()
    }

    private filterItems(key: string): void {
        const term = key.toLowerCase()

        this.filteredListItems = this.listItems
            .filter(item => term.length === 0 || (item.name.toLowerCase().indexOf(term) > -1))
            .map(item => ({
                id: item.id,
                name: item.name,
                disabled: item.disabled,
                selected: false,
            } as IDropdownSearchSelectableDropdownItem))

        this.cdr.markForCheck()
    }

    private navigateByArrows(key: string): void {
        if (this.filteredListItems.length === 0) {
            return
        }

        const currentSelectedIndex = this.filteredListItems.findIndex((item) => item.selected === true)

        if (currentSelectedIndex < 0) {
            this.filteredListItems[0].selected = true
        } else {
            if (
                (key === EKeyboardKeys.ArrowUp && currentSelectedIndex === 0) ||
                (key === EKeyboardKeys.ArrowDown && currentSelectedIndex === this.filteredListItems.length - 1)
            ) {
                return
            }

            this.filteredListItems[currentSelectedIndex].selected = false
            const delta = (key === EKeyboardKeys.ArrowDown ? 1 : -1)
            this.filteredListItems[currentSelectedIndex + delta].selected = true

            if (currentSelectedIndex > 1 && currentSelectedIndex < this.filteredListItems.length - 2) {
                const listElement = this.listElement.nativeElement
                const listItem = listElement.getElementsByTagName('li')[currentSelectedIndex + delta]
                listElement.scrollTop = listElement.scrollTop + delta * listItem.getBoundingClientRect().height
            }
        }

        this.cdr.detectChanges()
    }

    private findAndResetArrowSelectedItem(scrollToTop = true): number {
        if (scrollToTop) {
            const listElement = this.listElement.nativeElement
            listElement.scrollTop = 0
        }

        const currentSelectedIndex = this.filteredListItems.findIndex((item) => item.selected === true)
        if (currentSelectedIndex > -1) {
            this.filteredListItems[currentSelectedIndex].selected = false
        }

        return currentSelectedIndex
    }

    private selectItemByEnter(): void {
        const currentSelectedIndex = this.findAndResetArrowSelectedItem()
        if (currentSelectedIndex > -1) {
            this.value = this.filteredListItems[currentSelectedIndex].id
            this.setValue()
            this.emitModelValue()
            this.setOpenState(false)
        }
    }

    private closeListByEscape(): void {
        this.findAndResetArrowSelectedItem()
        this.setOpenState(false)
        this.setValue()
    }

    private calculateCurrentDirection(): void {
        const elementBoundingRect = this.topLevelElement.nativeElement.getBoundingClientRect()

        const listHeight = this.opened
            ? this.listElement.nativeElement.getBoundingClientRect().height
            : this.DEFAULT_LIST_HEIGHT

        const deltaTop = window.innerHeight - elementBoundingRect.top - elementBoundingRect.height - listHeight
        const newOpenDirection = deltaTop > 0 ? 'bottom' : 'top'

        if (this._openDirection !== newOpenDirection) {
            this._openDirection = newOpenDirection
            this.cdr.detectChanges()
        }
    }

    private setupToggleDirection(): void {
        if (this.openDirection === 'auto') {
            this.openDirectionSubscription = fromEvent(window, 'resize').subscribe(
                () => this.calculateCurrentDirection(),
            )
        } else {
            if (this.openDirectionSubscription) {
                this.openDirectionSubscription.unsubscribe()
            }
        }
    }
    //#endregion

    //#region protected methods
    protected itemClick(index: number): void {
        if (!this.isDisabled) {
            const newValue: unknown = this.filteredListItems[index].id
            this.emitModelValue(newValue)

            if (this.resetAfterSelect) {
                this.value = null
                this.setDefaultDisplayName()
                this.selected = false
            } else {
                this.value = newValue
                this.displayName = this.filteredListItems[index].name
                this.selected = true
            }

            this.setOpenState(false)
            this.cdr.detectChanges()
        }
    }

    protected toggleList(): void {
        this.setOpenState(!this.opened)
    }

    protected clearValue(emit = true): void {
        this.value = null
        this.setValue()
        this.filteredListItems = this.listItems
        setTimeout(() => this.searchInput.nativeElement.value = '')

        if (emit) {
            this.emitModelValue()
        }
    }
    //#endregion

    public trackByFn(_, item: IDropdownSearchSelectableDropdownItem): unknown {
        return item.id
    }
}
