import { IApiPaging } from './IApiPaging'
import { IApiOrdering } from './IApiOrdering'

export interface IApiListRequest
{
    searchTerm?: string
    paging?: IApiPaging
    ordering?: IApiOrdering
    additionalParams?: {[key: string]: string | string[]}
    type?: number | string
}