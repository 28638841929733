import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject, Observable } from 'rxjs'


@Injectable({ providedIn: 'root' })
export class AppTranslationService {
    public static readonly LOCALES = ['en', 'ru']

    private onLanguageChanged = new BehaviorSubject<string>(AppTranslationService.LOCALES[0])
    public get languageChanged$(): Observable<string> { return this.onLanguageChanged.asObservable() }

    private languageInitiallySetted = new BehaviorSubject<boolean>(false)
    public get languageInitiallySetted$(): Observable<boolean> { return this.languageInitiallySetted.asObservable() }

    constructor(private translate: TranslateService) {
        this.translate.addLangs(AppTranslationService.LOCALES)
        this.translate.setDefaultLang(AppTranslationService.LOCALES[0])
    }

    public getCurrentLanguage(): string {
        return this.translate.currentLang || AppTranslationService.LOCALES[0]
    }

    public changeLanguage(language = 'en'): void {
        this.translate.use(language)
        this.onLanguageChanged.next(language)

        const languageInitiallySetted = this.languageInitiallySetted.getValue()
        if (languageInitiallySetted === false) {
            this.languageInitiallySetted.next(true)
        }
    }

    public useDefaultLanguage(): void {
        this.translate.use(AppTranslationService.LOCALES[0])
        this.languageInitiallySetted.next(false)
    }
}
