import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
    selector: 'collapsable-area',
    templateUrl: './collapsable-area.component.html',
    styleUrls: ['./collapsable-area.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsableAreaComponent
{
    @Input()
    public title = ''

    @Input()
    public disabled = false

    @Input()
    public required = false

    private _collapsed = true

    @Input()
    public set collapsed(value: boolean) { this._collapsed = value }

    public get collapsed(): boolean { return this._collapsed }

    protected toggle(): void {
        if (this.disabled) return

        this._collapsed = !this._collapsed
    }
}
