import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    Host,
    HostListener,
    Input,
    OnInit,
    Optional,
    SkipSelf,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms'
import dayjs from 'dayjs'
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { distinctUntilChanged, filter } from 'rxjs/operators'

import { FormInputComponent } from '../formInputComponent'
import { DestroyService, FormValidationService } from 'shared/services'


@Component({
    selector: 'input-text-date',
    templateUrl: 'input-text-date.component.html',
    styleUrls: ['input-text-date.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextDateComponent),
            multi: true,
        },
        DestroyService,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputTextDateComponent extends FormInputComponent<string> implements OnInit {

    @Input() public manual = false
    @Input() public inputMask = ''

    @ViewChild('dp') datepicker: NgbDatepicker;

    public datePickerVisible = false

    protected readonly DAYJS_DATE_FORMAT: string = 'M/D/YYYY'
    protected readonly DP_DATE_FORMAT: string = 'YYYY-MM-DD'

    public value: NgbDateStruct = {
        day: undefined,
        month: undefined,
        year: undefined,
    }

    protected get cssClass(): Record<string, boolean | string> {
        return {
            nolabel: this.noLabel,
            focused: this.isFocused,
            readonly: this.readonly,
            invalid: !this.isValid && this.isDirty,
        }
    }

    @Input()
    public set datePickerOptions(value: Record<string, NgbDateStruct>) {
        this.datePickerOptionsValue = this.updateDatePickerOptions(this.datePickerOptionsValue, value)
    }

    get datePickerOptions(): Record<string, NgbDateStruct> {
        return this.datePickerOptionsValue
    }

    private datePickerOptionsValue: Record<string, NgbDateStruct> = {}

    @Input() public logging = false

    constructor(
        @Optional() @Host() @SkipSelf()
        protected controlContainer: ControlContainer,
        protected eRef: ElementRef,
        protected destroy: DestroyService,
        protected validator: FormValidationService,
        private dateParser: NgbDateParserFormatter,
        private calendar: NgbCalendar,
    ) {
        super(controlContainer, null, validator)
    }


    public ngOnInit(): void {
        super.ngOnInit()

        this.log('ngOnInit: control value: ', this.control?.value)
        this.setDatePickerInnerValue()

        if (this.manual) {
            this.subs.push(
                this.control.valueChanges.pipe(filter(Boolean), distinctUntilChanged())
                    .subscribe(v => {
                        this.setDatePickerInnerValue()
                        this.datepicker?.navigateTo(this.value)
                        this.onChange(v)
                    }),
            )
        }
    }

    private log(...log: any[]): void {
        if (this.logging) {
            console.log(...log)
        }
    }

    private setDatePickerInnerValue(): void {
        if (this.control.value) {
            const date = dayjs(this.control.value)
            this.value = {
                day: date.date(),
                month: date.month() + 1,
                year: date.year(),
            }
        }
    }

    protected toggleDatePicker(): void {
        if (!this.readonly) {
            this.datePickerVisible = !this.datePickerVisible

            if (this.manual) {
                this.datepicker.navigateTo(this.value)
            }
        }
    }

    @HostListener('document:click', ['$event'])
    public clickout(event: MouseEvent): void {
        const clickedInside = this.eRef.nativeElement.contains(event.target)
        if (!clickedInside && this.datePickerVisible) {
            this.toggleDatePicker()
        }
    }


    public writeValue(value: string | Date | NgbDateStruct): void {
        this.log('writeValue: ', value)

        if (!value || value === '') {
            if (this.control.value !== value) {
                this.control.setValue(undefined)
            }
        } else {
            let formattedValue

            if (typeof value === 'string') {
                formattedValue = dayjs(value).format(this.DP_DATE_FORMAT)
            } else if (value instanceof Date) {
                formattedValue = dayjs(value.toString()).format(this.DP_DATE_FORMAT)
            } else {
                formattedValue = dayjs(
                    `${value.month}/${value.day}/${value.year}`,
                    this.DAYJS_DATE_FORMAT,
                ).format(this.DP_DATE_FORMAT)
            }

            if (formattedValue === this.control.value) {
                return
            }

            this.control.setValue(formattedValue)
            this.setDatePickerInnerValue()
        }

        this.markForCheck()
    }

    protected onDateChanged(date: NgbDateStruct): void {
        setTimeout(() => {
            this.log('onDateChanged: ', date)
            this.writeValue(date)
            const dateFormatted = dayjs(this.dateParser.format(date)).format(this.DP_DATE_FORMAT)
            this.onChange(dateFormatted)
            this.toggleDatePicker()
        })
    }

    protected updateDatePickerOptions(
        source: Record<string, NgbDateStruct>,
        update: Record<string, NgbDateStruct>,
    ): Record<string, NgbDateStruct> {
        const result = JSON.parse(JSON.stringify(source))
        Object.keys(update).forEach(key => result[key] = update[key])
        return result
    }

    protected isWeekend(date: NgbDate): boolean {
        return this.calendar.getWeekday(date) >= 6
    }
}
