export enum ECreativeType {
    BannerImage = '1',
    BannerJs = '2',
    VideoLinearInline = '3',
    VideoLinearWrapper = '4',
    VideoLinearVPAID = '5',
    VideoNonLinear = '6',
    NativeModile = '7',
    BannerHTML5Zip = '8',
}
