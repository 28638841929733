import { Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { InputTextDateComponent } from '../input-text-date/input-text-date.component'

@Component({
    selector: 'input-text-date-wrapper',
    template: `
        <form [formGroup]="form">
            <input-text-date formControlName="testName"></input-text-date>
        </form>
    `,
})
export class InputTextDateWrapperComponent {
    @ViewChild(InputTextDateComponent) inputTextDateComponent: InputTextDateComponent

    form = new FormGroup({
        'testName': new FormControl('12/01/2023'),
    })
}
