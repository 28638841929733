import { FormMetadataKeys } from './FormMetadataKeys'
import { IFormFieldConfiguration } from './IFormFieldConfiguration'


export function formField(config?: IFormFieldConfiguration): PropertyDecorator {
    return (target: object, propertyKey: string) => {
        defineMetadata(target, propertyKey, config)
    }
}

function defineMetadata(
    target: object,
    propertyKey: string,
    config?: IFormFieldConfiguration,
    parentKey = '',
): void {
    const key = parentKey ? parentKey + `[${propertyKey}]` : propertyKey

    Reflect.defineMetadata(FormMetadataKeys.FORM_FIELD, config || {}, target, key)

    if (config?.children) {
        Object.keys(config.children).forEach(fieldKey => {
            defineMetadata(target, fieldKey, config.children[fieldKey], key)
        })
    }
}
