import { Injectable } from '@angular/core'
import { IDictionary } from '../models/global/IDictionary'
import { IDropdownItemTyped } from '../models/ui/IDropDownItemTyped'


@Injectable({ providedIn: 'root' })
export class DictionariesService {
    public get currencies() { return this._currencies }
    private readonly _currencies: { [key: string]: IDropdownItemTyped<number>[] } = {
        'ru': [
            { id: 1, name: 'Рубль' },
            { id: 2, name: 'Евро' },
            { id: 3, name: 'Доллар США' },
            { id: 4, name: 'Фунт стерлингов' },
        ],
        'en': [
            { id: 1, name: 'Ruble' },
            { id: 2, name: 'Euro' },
            { id: 3, name: 'US dollar' },
            { id: 4, name: 'Pound sterling' },
        ],
    }

    public get languages() { return this._languages }
    private readonly _languages: IDropdownItemTyped<number>[] = [
        { id: 1, name: 'English' },
        { id: 2, name: 'Русский' },
    ]

    public get currencyCodes() { return this._currencyCodes }
    private readonly _currencyCodes: IDropdownItemTyped<number>[] = [
        { id: 1, name: 'RUB' },
        { id: 2, name: 'EUR' },
        { id: 3, name: 'USD' },
        { id: 4, name: 'GBP' },
    ]

    public get currencySymbols() { return this._currencySymbols }
    private readonly _currencySymbols: IDictionary<string> = {
        '1': '₽',
        '2': '€',
        '3': '$',
        '4': '£',
    }

    constructor() { }
}