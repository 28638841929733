import { Component } from '@angular/core'
import { merge } from 'rxjs'

import { UiService } from '../../services'


@Component({
   selector: 'overlay',
   template: `
      <div class="page-overlay"
           [ngStyle]="{'display': loadingIndicatorVisible ? 'block' : 'none'}"
      ></div>
   `,
   styleUrls: ['./overlay.components.scss'],
})
export class OverlayComponent {

   private _loadingIndicatorVisible = false
   public get loadingIndicatorVisible(): boolean {
      return this._loadingIndicatorVisible
   }

   constructor(private ui: UiService) {
       merge(this.ui.showBackEndActionMask$, this.ui.pageMaskVisible$)
           .subscribe(value => this._loadingIndicatorVisible = value)
   }
}
