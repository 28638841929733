<div *ngIf="visible">

    <div class="breadcrumb" *ngIf="multiple; else single">

        <ng-container *ngFor="let item of items; index as i">

            <div class="breadcrumb-item"
                 [ngClass]="{'level-1': items.length <= 3, 'level-2': items.length > 3}"
                 *ngIf="i < items.length - 1; else header">

                <ng-container *ngIf="!item.url || item.url.length == 0 || (item.editIcon && item.url?.length > 0); else link">
                    <a *ngIf="item.editIcon" class="edit-icon" [routerLink]="item.url">
                        <i class="fa fa-pencil"></i>
                    </a>
                    <span [tooltip]="item.title"
                          tooltipPosition="below"
                          tooltipColor="primary">{{item.title}}</span>
                </ng-container>

                <ng-template #link>
                    <a [routerLink]="[item.url]"
                       [queryParams]="item.params"
                       [tooltip]="item.title"
                       tooltipPosition="below"
                       tooltipColor="primary">{{item.title}}</a>
                </ng-template>

                <i class="arrow fa fa-chevron-right" *ngIf="i < items.length - 2"></i>
            </div>

            <ng-template #header>
                <span class="current-page-header">
                    {{item.title}}
                </span>
            </ng-template>

        </ng-container>

    </div>

    <ng-template #single>
        <span class="single-item">{{items[0].title}}</span>
    </ng-template>

</div>