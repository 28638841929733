<div class="dt-name-cell">
    <div class="str-1">
        <div class="name">
            <span *ngIf="!routerLink">{{name}}</span>
            <a *ngIf="routerLink" [routerLink]="routerLink">{{name}}</a>
        </div>
    </div>

    <div class="str-2">
        <span class="id">{{id}}</span>
        <div class="description" *ngIf="description && description.length > 0">{{description}}</div>
        <div class="content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
