<div class="search">
    <input-text
        [label]="('shared.searchBy' | translate) + ' ' + searchLabel"
        [(ngModel)]="searchTerm"
        (ngModelChange)="onSearch($event)"
    ></input-text>
</div>

<div *ngIf="searchTerm?.length === 0" class="tree-wrapper">
    <ng-scrollbar [autoHeightDisabled]="false">
        <div class="tree-container">
            <ng-container *ngIf="tree">
                <ng-container *ngTemplateOutlet="recursiveTree; context: { $implicit: tree, parentIds: [], atSearch: false }"></ng-container>
            </ng-container>
        </div>
    </ng-scrollbar>
</div>

<ng-container *ngIf="searchTerm?.length > 0">
    <div class="tree-wrapper">
        <ng-scrollbar [autoHeightDisabled]="false">
            <div class="tree-container">
                <ng-container *ngIf="(searchTree | keyvalue)?.length > 0 && !isSearching">
                    <ng-container *ngTemplateOutlet="recursiveTree; context: { $implicit: searchTree, parentIds: [], atSearch: true }"></ng-container>
                </ng-container>
                <ng-container *ngIf="(searchTree | keyvalue)?.length === 0 && !isSearching">
                    <span translate="shared.notFound"></span>
                </ng-container>
                <ng-container *ngIf="isSearching">
                    <span translate="shared.loading"></span>
                </ng-container>
            </div>
        </ng-scrollbar>
    </div>
</ng-container>

<ng-template #recursiveTree let-tree let-parentIds="parentIds" let-atSearch="atSearch">
    <div class="tree">
        <div class="tree-item"
             *ngFor="let item of (tree | keyvalue: sortFn); trackBy: trackBy">

            <ng-container *ngIf="item.value.level < hierarchy.length - 1
                            && (item.value.children | keyvalue).length > 0">
                <div class="toggle-wrapper" (click)="expand($event, parentIds, item.value.id, atSearch)">
                    <span class="toggle">
                        <i *ngIf="item.value.expanded" class="fa fa-caret-down"></i>
                        <i *ngIf="!item.value.expanded" class="fa fa-caret-right"></i>
                    </span>

                    <ng-container *ngTemplateOutlet="customTemplate ? customTemplate : displayName; context: { $implicit: item }"></ng-container>
                </div>

                <div class="subtree" *ngIf="item.value.children && item.value.expanded">
                    <ng-container *ngTemplateOutlet="recursiveTree; context: {
                            $implicit: item.value.children,
                            parentIds: parentIds.concat(item.value.id),
                            atSearch: atSearch,
                        }"></ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="item.value.level === hierarchy.length - 1">
                <div *ngIf="!multiple" class="radio-wrapper">
                    <div class="radio-item-wrapper"
                         (click)="select(item.value.id)"
                         [ngClass]="{
                            'selected': item.value.id === value,
                         }">

                        <div class="dot">
                            <div class="dot-inner"></div>
                        </div>

                        <ng-container *ngTemplateOutlet="customTemplate ? customTemplate : displayName; context: { $implicit: item }"></ng-container>
                    </div>
                </div>

                <div *ngIf="multiple" class="checkbox-wrapper">
                    <div class="checkbox-item-wrapper">
                        <checkbox [ngModel]="selectedItems.get(item.value.id)"
                                  (ngModelChange)="selectItem(item.value.id, $event)">
                            <ng-container *ngTemplateOutlet="customTemplate ? customTemplate : displayName; context: { $implicit: item }"></ng-container>
                        </checkbox>
                    </div>
                </div>
            </ng-container>

        </div>
    </div>
</ng-template>

<ng-template #displayName let-item>
    <div
        class="name"
        [class.current]="isItemChosen(item.value)"
    >{{
        (entityNameMap[hierarchy[item.value.level]] | translate)
        + ' '
        + item.value.id
        + ': '
        + item.value.name
        + (isItemChosen(item.value) ? ' *' : '')
    }}</div>
</ng-template>