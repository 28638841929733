import { Component, Input, forwardRef } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { ITabItem } from 'shared/models/ui/ITabItem'


@Component({
   selector: 'tabs',
   templateUrl: 'tabs.component.html',
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => TabsComponent),
         multi: true,
      },
   ],
})
export class TabsComponent implements ControlValueAccessor {
   private value: string = null

   @Input() public tabs: ITabItem[] = []

   @Input() public disabled = false


   select(index: number): void {
      if (this.tabs[index].disabled) {
         return
      }

      this.tabs.forEach((tab, i) => {
         this.tabs[i].selected = index === i
      })
      this.value = this.tabs[index].value.toString()
      this.propagateChange(this.value)
   }

   //#region ControlValueAccessor realization

   public writeValue(value: string): void {
      if (value !== undefined && value !== null) {
         this.value = value.toString()
         this.tabs.forEach(tab => tab.selected = this.value === tab.value.toString())
         this.propagateChange(this.value)
      } else {
         this.select(0)
      }
   }

   private propagateChange = (_value: string): void => undefined

   public registerOnChange(fn: () => void): void {
      this.propagateChange = fn
   }

   private propagateTouched = (): void => undefined

   public registerOnTouched(fn: () => void): void {
      this.propagateTouched = fn
   }

   public setDisabledState?(isDisabled: boolean): void {
      this.disabled = isDisabled
   }

   //#endregion
}