import { NgModule, Provider } from '@angular/core'
import { HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule, Routes } from '@angular/router'

import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { NgcCookieConsentModule } from 'ngx-cookieconsent'

import { TranslateLanguageLoader, SharedModule, PrivacyPolicyComponent } from 'shared'

import { AuthGuardService as AuthGuard } from './routeGuards/auth-guard.service'
import { AppRootComponent } from './app-root.component'
import { environment } from '../environments/environment'
import { cookieConsentConfig } from './cookieConsentConfig'

const routes: Routes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: 'app', loadChildren: () => import('app/app.module').then(m => m.AppModule), canActivate: [AuthGuard] },
    { path: 'auth', loadChildren: () => import('auth/auth.module').then(m => m.AuthModule) },
    { path: 'shared', loadChildren: () => import('public/public.module').then(m => m.PublicModule) },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: '**', redirectTo: 'app', pathMatch: 'full' },
]

const allProviders: Provider[] = [
    { provide: 'BASE_URL', useFactory: () => document.getElementsByTagName('base')[0].href },
]

const devProviders = allProviders.concat([])
const prodProviders = allProviders.concat([])


@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        RouterModule.forRoot(routes),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateLanguageLoader,
            },
        }),
        NgcCookieConsentModule.forRoot(cookieConsentConfig),
    ],
    declarations: [AppRootComponent],
    providers: environment.production ? prodProviders : devProviders,
    bootstrap: [AppRootComponent],
})
export class RootModule { }