import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    ViewEncapsulation,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'


@Component({
    selector: 'checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.less'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor {

    private _checked = false
    private _readonly = false
    private _disabled = false
    private _innerLabel = ''
    private _outerLabel = ''

    @Input()
    public size = ''

    @Input()
    public set readonly(value: boolean) {
        this._readonly = value
    }

    public get readonly(): boolean { return this._readonly }
    public get disabled(): boolean { return this._disabled }

    public get checked(): boolean { return this._checked }


    @Input()
    public set innerLabel(val: string) { this._innerLabel = val || '' }
    public get innerLabel(): string { return this._innerLabel }
    public get showInnerLabel(): boolean { return this._innerLabel && this._innerLabel.length > 0 }

    @Input()
    public set outerLabel(val: string) { this._outerLabel = val || '' }
    public get outerLabel(): string { return this._outerLabel }
    public get showOuterLabel(): boolean { return this._outerLabel && this._outerLabel.length > 0 }

    @Input()
    public hint?: string = ''

    constructor(private cdr: ChangeDetectorRef) {}


    private emitValue(): void {
        this.propagateChange(this._checked)
    }

    protected toggle(): void {
        if (this._readonly || this._disabled) {
            return
        }

        this._checked = !this._checked
        this.emitValue()

        this.cdr.markForCheck()
    }


    //#region ControlValueAccessor realization

    public writeValue(value: boolean): void {
        if (value !== undefined && !!value === value) {
            this._checked = value

            this.cdr.markForCheck()
        }
    }

    private propagateChange = (_value: boolean | null): void => undefined
    public registerOnChange(fn: () => void): void {
        this.propagateChange = fn
    }

    private propagateTouched = (): void => undefined
    public registerOnTouched(fn: () => void): void {
        this.propagateTouched = fn
    }

    public setDisabledState?(isDisabled: boolean): void {
        this._disabled = isDisabled
    }
    //# endregion
}
