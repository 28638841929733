import {
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnInit,
    TemplateRef,
} from '@angular/core'
import { KeyValue } from '@angular/common'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

import { IEntities, IEntityTree, IEntityTreeItem } from './models/IEntityTree'
import { deepGet, deepSet } from '../../../utils'
import { DestroyService } from '../../../services'

@Component({
    selector: 'entity-tree',
    templateUrl: './entity-tree.component.html',
    styleUrls: ['./entity-tree.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EntityTreeComponent),
            multi: true,
        },
        DestroyService,
    ],
})
export class EntityTreeComponent implements ControlValueAccessor, OnInit {

    @Input() public hierarchy: string[] = []

    @Input() public placeholder = ''

    @Input() public entities: IEntities

    @Input() public multiple = false

    @Input() public customTemplate: TemplateRef<any>

    protected value: number | number[]

    protected tree: IEntityTree

    protected searchTree: IEntityTree

    protected searchTerm = ''

    protected selectedItemPath = []

    protected readonly entityNameMap = {
        'adclients': 'app.clients.titles.entity',
        'campaigns': 'app.campaigns.titles.entity',
        'groups': 'app.groups.titles.entity',
        'containers': 'app.containers.titles.entity',
        'segments': 'app.segments.titles.entity',
    }

    private search$ = new Subject<string>()

    protected isSearching = false

    protected selectedItems: Map<number, boolean> = new Map<number, boolean>()

    constructor(
        private cdr: ChangeDetectorRef,
        private translate: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this.search$.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            filter(term => term.length > 0),
            tap(() => {
                this.isSearching = true
                this.cdr.detectChanges()

                setTimeout(() => {
                    this.createSearchTree()
                    this.isSearching = false
                    this.cdr.detectChanges()
                })
            }),
        ).subscribe()

        this.tree = this.createTree(this.entities)
    }

    protected get searchLabel(): string {
        return this.hierarchy.map(item => this.translate.instant(this.entityNameMap[item])).join('/')
    }

    protected isItemChosen(item: IEntityTreeItem): boolean {
        if (this.selectedItemPath.length === 0) {
            return false
        }

        return item.id === this.selectedItemPath[item.level].toString()
            && item.level !== this.hierarchy.length - 1
    }

    protected expand(event: Event, path: string[], id: number, atSearch = false): void {
        event.stopPropagation()

        let treePath = []
        path.forEach(item => {
            treePath = treePath.concat(item, 'children')
        })

        const item = deepGet(atSearch ? this.searchTree : this.tree, treePath.concat(id))

        if (Object.entries(item.children).length > 0) {
            item.expanded = !item.expanded
        }
    }

    protected select(id: number): void {
        this.selectedItemPath = []
        this.selectedItemPath.unshift(id)

        let currentId = id
        for (let i = this.hierarchy.length - 2; i >= 0 ; i--) {
            const child = this.entities[this.hierarchy[i + 1]].find(entity => entity.id === currentId)
            const parent = this.entities[this.hierarchy[i]].find(entity => entity.id === child.parentId)
            currentId = parent.id
            this.selectedItemPath.unshift(currentId)
        }

        this.writeValue(id)
        this.propagateChange(id)
    }

    protected onSearch(searchTerm: string): void {
        this.search$.next(searchTerm)
    }

    protected trackBy(_: number, item: KeyValue<unknown, IEntityTreeItem>): string {
        return item.value.level + '-' + item.value.id
    }

    protected sortFn(a: { value: IEntityTreeItem }, b: { value: IEntityTreeItem }): number {
        return a.value.id > b.value.id ? -1 : (b.value.id > a.value.id ? 1 : 0)
    }

    private createTree(entities: IEntities): IEntityTree {
        const newTree = {}

        // root entities first
        entities[this.hierarchy[0]].forEach(item => {
            newTree[item.id] = { ...item, children: {}, level: 0 }
        })

        if (this.hierarchy.length <= 1) {
            return newTree
        }

        // then children entities
        for (let i = 1; i < this.hierarchy.length; i++) {
            entities[this.hierarchy[i]].forEach(item => {
                const path = [item.id.toString()]
                let current = { ...item }
                let currentIndex = i
                let parentId = current.parentId
                while (parentId !== null) {
                    path.unshift(parentId.toString(), 'children')
                    current = entities[this.hierarchy[currentIndex - 1]].find(entity => entity.id === parentId)
                    parentId = current.parentId
                    if (parentId === null) {
                        break
                    }

                    currentIndex--
                }

                deepSet(newTree, path, { ...item, children: {}, level: i })
            })
        }

        return this.removeEmpty(newTree)
    }

    private createSearchTree(): void {
        const searchEntities: IEntities = {}

        for (const key of this.hierarchy) {
            searchEntities[key] = []
        }

        for (let level = 0; level < this.hierarchy.length; level++) {
            const key = this.hierarchy[level]
            const entitiesToAdd = this.entities[key].reduce((arr, current) => {
                const term = this.searchTerm.trim().toLowerCase()
                const entityIdName = current.id.toString().concat(' ').concat(current.name?.toLowerCase())
                if (entityIdName.includes(term)) {
                    const copy = { ...current, expanded: true }
                    return arr.concat(copy)
                }

                return arr
            }, [])

            if (entitiesToAdd?.length > 0) {
                searchEntities[key] = searchEntities[key].concat(entitiesToAdd)
            }

            const deepestLevel = this.hierarchy.length - 1

            // add current level's entities' children
            if (level < deepestLevel) {
                for (let childIndex = level + 1; childIndex <= deepestLevel; childIndex++) {
                    const childrenToAdd = this.entities[this.hierarchy[childIndex]].reduce((arr, current) => {
                        if (searchEntities[this.hierarchy[childIndex - 1]]?.findIndex((entity) => current.parentId === entity.id) > -1) {
                            const copy = { ...current, expanded: true }
                            return arr.concat(copy)
                        }

                        return arr
                    }, [])

                    if (childrenToAdd?.length > 0) {
                        searchEntities[this.hierarchy[childIndex]] = searchEntities[this.hierarchy[childIndex]].concat(childrenToAdd)
                    }
                }
            }

            // add current level's entities' parents
            if (level > 0) {
                for (let parentIndex = level - 1; parentIndex >= 0; parentIndex--) {
                    const parentsToAdd = this.entities[this.hierarchy[parentIndex]].reduce((arr, current) => {
                        if (searchEntities[this.hierarchy[parentIndex + 1]]?.findIndex((entity) => entity.parentId === current.id) > -1) {
                            const copy = { ...current, expanded: true }
                            return arr.concat(copy)
                        }

                        return arr
                    }, [])

                    if (parentsToAdd?.length > 0) {
                        searchEntities[this.hierarchy[parentIndex]] = searchEntities[this.hierarchy[parentIndex]].concat(parentsToAdd)
                    }
                }
            }
        }

        this.searchTree = this.createTree(searchEntities)
    }

    private removeEmpty(tree: IEntityTree, level = 0): IEntityTree {
        const newTree = { ...tree }

        if (level === this.hierarchy.length - 1) {
            return newTree
        }

        Object.keys(newTree).forEach(id => {
            if (Object.entries(newTree[id].children).length === 0) {
                delete newTree[id]
            } else {
                newTree[id].children = this.removeEmpty(tree[id].children, level + 1)
            }
        })

        return newTree
    }

    private propagateChange = (_value: number | number[] | null): void => undefined

    public registerOnChange(fn: () => void): void {
        this.propagateChange = fn
    }

    private propagateTouched = (): void => undefined

    public registerOnTouched(fn: () => void): void {
        this.propagateTouched = fn
    }

    public writeValue(value: number | number[]): void {
        if (value === null || value === undefined) {
            this.value = null
        } else if (Array.isArray(value)) {
            this.value = value
            this.selectedItems.forEach((_, key) => {
                this.selectedItems.set(key, false)
            })
            this.value.forEach(v => this.selectedItems.set(v, true))
        } else {
            this.value = value
        }

        this.cdr.markForCheck()
    }

    protected selectItem(id: number, isSelected: boolean): void {
        this.selectedItems.set(id, isSelected)
        const newValue = []
        this.selectedItems.forEach((selected, itemId) => {
            if (selected) {
                newValue.push(itemId)
            }
        })

        this.writeValue(newValue)
        this.propagateChange(newValue)
    }
}
