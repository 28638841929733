import { Component } from '@angular/core'
import { NgcCookieConsentService } from 'ngx-cookieconsent'

@Component({
    selector: 'app-root',
    templateUrl: './app-root.component.html',
    styleUrls: ['./app-root.component.less'],
})
export class AppRootComponent {
    constructor(private ccService: NgcCookieConsentService) { }
}
