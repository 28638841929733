import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

import { IValidationMessage } from 'shared/models/forms/IValidationMessage'

@Injectable({ providedIn: 'root' })
export class FormValidationService {
    private dead: Subject<void> = new Subject<void>()

    private _lastMessage: Subject<IValidationMessage> = new Subject<IValidationMessage>()

    get lastMessage(): Subject<IValidationMessage> {
        return this._lastMessage
    }

    public setMessage(message: IValidationMessage): void {
        this._lastMessage.next(message)
    }

    public resetMessage(): void {
        this._lastMessage.next(undefined)
    }

    public destroy(): void {
        this.dead.next()
        this.dead.complete()
    }
}
