import { Component, Injectable } from '@angular/core'
import { Router, NavigationStart } from '@angular/router'
import { Title } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { Subject, BehaviorSubject, Observable, fromEvent } from 'rxjs'
import { takeUntil, filter, tap } from 'rxjs/operators'
import { NgxUiLoaderService } from 'ngx-ui-loader'

import { IBreadCrumbItem } from 'shared/models/ui/IBreadCrumbItem'
import { IEnvironmentConfig } from 'environments/IEnvironmentConfig'
import { environment } from 'environments/environment'
import { SystemMessagesService } from './system-messages.service'


@Injectable({ providedIn: 'root' })
export class UiService {
    private readonly MAIN_MENU_COLLAPSED_KEY = 'mainMenuIsCollapsed'

    private _dead: Subject<void> = new Subject<void>()

    protected pageMaskVisible = new BehaviorSubject<boolean>(false)

    public get pageMaskVisible$() {
        return this.pageMaskVisible.asObservable()
    }

    public get outletComponent() {
        return this._outletComponent.asObservable()
    }

    protected _outletComponent = new BehaviorSubject(null)

    protected showBackEndActionMask = new BehaviorSubject<boolean>(false)

    public get showBackEndActionMask$() {
        return this.showBackEndActionMask.asObservable()
    }

    protected _documentClickObservable: Observable<MouseEvent>

    public get documentClickEvent() {
        return this._documentClickObservable
    }

    protected mainMenuCollapsed = new BehaviorSubject<boolean>(false)

    public get mainMenuCollapsed$() {
        return this.mainMenuCollapsed.asObservable()
    }

    protected _breadCrumb = new BehaviorSubject<IBreadCrumbItem[]>([])
    public breadCrumb: Observable<IBreadCrumbItem[]> = this._breadCrumb.asObservable()

    constructor(
        protected router: Router,
        protected loader: NgxUiLoaderService,
        protected title: Title,
        protected i18n: TranslateService,
        private messages: SystemMessagesService,
    ) {
        const mainMenuIsCollapsed = localStorage.getItem(this.MAIN_MENU_COLLAPSED_KEY) === 'true'
        this.mainMenuCollapsed.next(mainMenuIsCollapsed)

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            takeUntil(this._dead),
            tap(() => {
                this.stopBackendAction()
                this.hidePageMask()
                this.messages.clearMessage()
            }),
        ).subscribe()

        if (document) {
            this._documentClickObservable = fromEvent<MouseEvent>(document, 'click')
        }
    }


    //#region PAGE MASKS

    public startBackendAction(): void {
        this.showBackEndActionMask.next(true)
    }


    public stopBackendAction(): void {
        this.showBackEndActionMask.next(false)
    }


    public showPageMask(): void {
        this.pageMaskVisible.next(true)
    }


    public hidePageMask(): void {
        this.pageMaskVisible.next(false)
    }

    //#endregion


    //#region LAYOUT

    private setTitle(pageTitle: string) {
        const baseTitle = (environment as IEnvironmentConfig).title || ''
        this.title.setTitle(`${ baseTitle }${ pageTitle }`)
    }

    public mainMenuToggle() {
        const collapsed = !this.mainMenuCollapsed.getValue()
        this.mainMenuCollapsed.next(collapsed)
        localStorage.setItem(this.MAIN_MENU_COLLAPSED_KEY, collapsed.toString())
    }

    public setHeaderTitle(title: string) {
        this.setTitle(title)
        this._breadCrumb.next([{ title: title }])
    }

    public registerOutletComponent(component: Component) {
        this._outletComponent.next(component)
    }

    public setPageBreadcrumb(breadcrumb: IBreadCrumbItem[]) {
        this.setTitle(breadcrumb[breadcrumb.length - 1].title)
        this._breadCrumb.next(breadcrumb)
        // setTimeout(() => { });
    }

    public addBodyClass(className: string) {
        const body = document.getElementsByTagName('body')[0]
        body.classList.add(className)
    }


    public removeBodyClass(className: string) {
        const body = document.getElementsByTagName('body')[0]
        body.classList.remove(className)
    }

    //#endregion


    public destroy(): void {
        this._dead.next()
        this._dead.complete()
    }
}

