<div class="editable-field__wrapper"
     [class.view-mode]="viewMode"
     [class.invalid]="newValue.invalid"
>
    <div *ngIf="viewMode; else editMode" class="editable-field__content">
        <ng-content></ng-content>
    </div>

    <div class="editable-field__icon">
        <i *ngIf="viewMode" class="fa fa-pencil"></i>
        <button *ngIf="!viewMode"
                (click)="submit($event)"
                [disabled]="!newValue.valid"
                class="submit-button"
        >
            OK
        </button>
    </div>
</div>

<ng-template #editMode>
    <input class="inline-input"
           type="text"
           [formControl]="newValue"
           [mask]="mask"
           [dropSpecialCharacters]="false"
    />
</ng-template>
