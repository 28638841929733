<div class="values-list" 
    [ngClass]="{
        'block': mode === 'block',
        'inline': mode === 'inline',
        'selectable': selectable
    }"
    *ngIf="{
        items: items$ | async
    } as data">

    <div 
        *ngFor="let item of data.items; index as i" 
        class="values-list-item" 
        [ngClass]="{'selected': item.selected}"
        (click)="onItemSelect(i)">

        <span class="item-wrapper">
            <span class="item-key" *ngIf="showKeys">{{item.item.key}}</span>
            <span class="item-separator" *ngIf="showKeys">{{itemSeparator}}</span>
            <span class="item-value">{{item.item.value}}</span>
        </span>

        <span *ngIf="deletable" class="action-delete">
            <i class="fa fa-times" (click)="onItemDelete(i)"></i>
        </span>

    </div>

</div>
