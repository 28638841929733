import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { takeUntil, tap } from 'rxjs/operators'

import { IBreadCrumbItem } from 'shared'
import { DestroyService, UiService } from '../../../services'

@Component({
   selector: 'breadcrumb',
   templateUrl: './breadcrumb.component.html',
   styleUrls: [ './breadcrumb.component.less' ],
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [ DestroyService ],
})
export class BreadcrumbComponent implements OnInit {
   private _items?: IBreadCrumbItem[] = []
   public get items(): IBreadCrumbItem[] {
      return this._items
   }

   public get visible(): boolean {
      return this._items.length > 0
   }

   public get multiple(): boolean {
      return this.items.length > 1
   }

   constructor(
      private ui: UiService,
      private cdr: ChangeDetectorRef,
      private destroy$: DestroyService,
   ) {
   }

   public ngOnInit(): void {
      this.ui.breadCrumb.pipe(
         takeUntil(this.destroy$),
         tap(bc => {
            this._items = [].concat(bc)
            this.cdr.detectChanges()
         }),
      ).subscribe()
   }
}
