import { Injectable, OnDestroy } from '@angular/core'
import { Subject, Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class DestroyService extends Observable<void> implements OnDestroy {
    private readonly alive$ = new Subject<void>()

    constructor() {
        super(subscriber => this.alive$.subscribe(subscriber))
    }

    ngOnDestroy() {
        this.alive$.next()
        this.alive$.complete()
    }
}
