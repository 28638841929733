import { Component, ViewChild } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'

import { InputTextComponent } from '../input-text/input-text.component'


@Component({
    selector: 'input-text-wrapper',
    template: `
        <form [formGroup]="form">
            <input-text formControlName="testName"></input-text>
        </form>
    `,
})
export class InputTextWrapperComponent {
    @ViewChild(InputTextComponent) inputTextComponent: InputTextComponent

    form = new FormGroup({
        'testName': new FormControl(''),
    })
}
