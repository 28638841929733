
<div class="block-default privacy-policy-wrapper">
    <ng-container *ngIf="lang !== 'en'">
        <h3>1. КТО МЫ</h3>

        <p><strong>ООО «iAGE Engineering»</strong> («мы», «нас», «компания») является компанией по разработке программного обеспечения, которая специализируется на предоставлении различных услуг на рынке цифровой рекламы и размещается в городе Рязани. Мы создаем и эксплуатируем различные платформы цифровых технологий и оказываем сопутствующие услуги, которые обеспечивают сотрудничество между покупателем и продавцом рекламных ресурсов.</p>
        <p>Мы предоставляем эту политику конфиденциальности, чтобы информировать вас о том, что мы очень серьезно относится к конфиденциальности данных, которые мы собираем и обрабатываем с помощью наших Сервисов. Вся обработка персональных данных выполняется в соответствии с действующим законодательством о защите персональных данных, включая Федеральный закон от 27 июля 2006 года № 152-ФЗ «О персональных данных».</p>

        <h3>2. ОПРЕДЕЛЕНИЯ И ТЕРМИНЫ</h3>

        <p><strong>Сайты и Сервисы</strong> – это совокупность связанных между собой веб-страниц, размещенных в сети Интернет по адресу <strong>iageengineering.net</strong> и <strong>iae.one</strong>, а также их поддоменах.</p>
        <p><strong>Поддомен</strong> – это страница или совокупность страниц, расположенные на доменах третьего уровня, принадлежащие <strong>ООО «iAGE Engineering»</strong>, а также другие временные страницы, внизу который указана контактная информация Компании.</p>
        <p><strong>Пользователь</strong> – лицо, имеющее доступ к Сайту или Сервисам, посредством сети Интернет и использующее информацию, материалы и продукты Компании.</p>

        <h3>3. ВАШ ВЫБОР</h3>

        <h4>ТАРГЕТИРОВАННАЯ РЕКЛАМА В БРАУЗЕРЕ</h4>

        <p>
            Если вы не хотите получать персонализированную рекламу в вашем текущем браузере, то вы можете отключить ее,  перейдя по этой
            <a href="https://www.iageengineering.net/opt-out" target="_blank">ссылке</a>. Обратите внимание, что файлы cookie и другие идентификаторы устройств различаются в разных браузерах и на разных устройствах, этот «отказ» относится только к этому браузеру и данному устройству.
        </p>

        <h4>ТАРГЕТИРОВАННАЯ РЕКЛАМА В ПРИЛОЖЕНИИ</h4>
        <p>
            Пользователи <strong>Android</strong> (версия 2.3 и выше). Чтобы использовать опцию «Отказаться от рекламы на основе интересов», следуйте инструкциям, предоставленным Google
            <a href="https://support.google.com/ads/answer/2662922?hl=ru" target="_blank">здесь</a>. Если на вашем устройстве не установлены Сервисы Google Play, вы не получите сервисы <strong>ООО «iAGE Engineering»</strong>. Обратите внимание, что эта настройка устройства, которая отключает основанную на интересах рекламу у всех поставщиков.
        </p>
        <p>
            Пользователи <strong>iOS</strong> (версия 6 и выше). Чтобы использовать параметр «Ограничить отслеживание рекламы», следуйте инструкциям, предоставленным Apple
            <a href="https://support.apple.com/en-us/HT202074" target="_blank">здесь</a>. Обратите внимание, что эта настройка устройства, которая отключает основанную на интересах рекламу у всех поставщиков.
        </p>
        <p>Также обратите внимание, что некоторые мобильные приложения могут использовать или полагаться на техническую функцию, называемую «веб-просмотр», которая позволяет разработчикам приложений отображать веб-приложения или веб-страницы непосредственно в своем приложении, не выходя из приложения, чтобы открыть свой обычный браузер. Веб-просмотр может быть независимым как от обычного браузера, так и от среды мобильных приложений. Поэтому мы рекомендуем также отказаться от этой конкретной среды, если вы не хотите получать рекламные объявления ООО «iAGE Engineering».</p>
        <p>Включение этих параметров отключит рекламу и сбор данных <strong>ООО «iAGE Engineering»</strong> как в Интернете, так и в среде мобильных приложений, если эти параметры остаются включенными. Ваш выбор вступит в силу, как только мы узнаем ваше устройство в сети наших партнеров.</p>
        <p>Обратите внимание: если вы обновите браузер или операционную систему своего устройства или отключите опции «Ограничить отслеживание рекламы» или «попросить сайты не отслеживать меня», мы больше не сможем узнать, что вы отказались от наших услуг.</p>

        <h3>4. КАКУЮ ИНФОРМАЦИЮ МЫ СОБИРАЕМ</h3>

        <p>Мы не собираем персональные данные, которые могут идентифицировать вас (пользователя Интернета) в «реальном мире». Когда вы посещаете Сайт, мы не знаем вашего имени, адреса электронной почты или какой-либо информации, которая непосредственно идентифицирует вас.</p>
        <p>Мы осуществляет автоматизированную обработку следующих данных, полученных при помощи наших Сервисов:</p>
        <ul>
            <li>страна, город и почтовый индекс;</li>
            <li>электронные данные (HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные об идентификаторе браузера, информация об аппаратном и программном обеспечении);</li>
            <li>широта и долгота;</li>
            <li>мобильные идентификаторы;</li>
            <li>дата и время осуществления доступа к нашим Сервисам.</li>
            <li>Если Вы вступили с нами в переписку, то мы можем хранить её копию.</li>
        </ul>

        <h3>5. КАК МЫ ОБРАБАТЫВАЕМ ДАННЫЕ</h3>

        <p>Наша платформа использует следующие технологии для обработки данных:</p>
        <ul>
            <li><strong>Cookie</strong>. Это небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя. Сохраняя информацию в Cookie, наши Сервисы могут запоминать предпочтения или распознавать веб-браузеры одного посещения от другого.</li>
            <li><strong>Пиксель</strong>. Это изображение размером 1×1 пиксель, которое позволяет Сервисам или третьей стороне, которая обслуживает пиксель, собирать данные, показывать рекламные объявления, измерять эффективность рекламы или предотвращать фальшивый рекламный трафик.</li>
            <li><strong>Набор средств разработки</strong>. Это блок кода или алгоритма, похожий на Пиксель, который встроен в приложение и позволяет нашим Сервисам получать определенную информацию, связанную с использованием приложения.</li>
        </ul>
        <p>Мы также можем получать данные от третьей стороны через межсерверное соединение или через безопасную передачу файлов.</p>

        <h3>6. КАК ИСПОЛЬЗУЮТСЯ СОБРАННЫЕ ДАННЫЕ</h3>

        <p>Сбор этой информации позволяет нам лучше понять вас, какой контент вам интересен. Мы используем эту информацию для наших внутренних аналитических целей и для повышения качества и актуальности наших сервисов.</p>
        <p>Информация, которую мы собираем о вас, может использоваться нами или передаваться нами третьим лицам любым способом и в любых целях, разрешенных законодательством.</p>
        <p>В первую очередь мы используем информацию в следующих целях:</p>
        <ul>
            <li>Обеспечение корректной работы релевантности рекламной кампании;</li>
            <li>Создание рекламных сегментов;</li>
            <li>Анализировать и уведомлять об эффективности рекламной кампании;</li>
            <li>Обнаруживать мошеннический трафик.</li>
        </ul>

        <h3>7. СТОРОННИЕ ССЫЛКИ</h3>
        <p>По нашему усмотрению мы можем включать или предлагать сторонние продукты или услуги. Эти сторонние сайты имеют отдельные и независимые политики конфиденциальности. Поэтому мы не несем ответственности за содержание и деятельность этих сайтов. Вам следует ознакомиться с применимой политикой конфиденциальности третьих лиц и условиями использования при посещении любых внешних сайтов, а также перед предоставлением какой-либо личной информации таким внешним сайтам.</p>

        <h3>8. ДЕТСКАЯ КОНФИДЕНЦИАЛЬНОСТЬ</h3>
        <p>Мы сознательно не собираем, не храним и не используем Персональную информацию от детей в возрасте до 13 лет (и в некоторых юрисдикциях в возрасте до 16 лет), и никакая часть наших Сервисов не предназначена для детей в возрасте до 13 лет (и в некоторых юрисдикциях в возрасте до 16 лет). Если вы узнаете, что ваш ребенок предоставил нам личную информацию без вашего согласия, вы можете предупредить нас по адресу contact-us@iageengineering.net. Если мы узнаем, что мы собрали какую-либо личную информацию от детей младше 13 лет (и в некоторых юрисдикциях в возрасте до 16 лет), мы незамедлительно предпримем шаги для удаления такой информации и аннулирования учетной записи ребенка.</p>

        <h3>9. БЕЗОПАСНОСТЬ И ХРАНЕНИЕ ДАННЫХ</h3>
        <p>Мы собираем и сохраняем данные в течение 12 месяцев с даты их поступления. Срок действия наших файлов cookie истекает через 30 дней после их последнего обновления.</p>

        <h3>10. КОНТАКТЫ</h3>
        <p>
            Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать по адресу: 
            <strong>contact-us@iageengineering.net</strong>
        </p>

        <p class="last-updated">Последнее обновление: 8 Июля 2019</p>

    </ng-container>


    <ng-container *ngIf="lang === 'en'">
        <h3>1. WHO WE ARE</h3>

        <p><strong>«iAGE Engineering»</strong> LLC («We», «Us», «the Company») is a software development company that specializes in providing various services in the digital advertising market and is located in the city of Ryazan. We create and operate various digital technology platforms and provide related services that provide cooperation between the buyer and seller of advertising resources.</p>
        <p>We provide this privacy policy to inform you that we are very serious about the confidentiality of the data that we collect and process using our Services. All processing of personal data is performed in accordance with the current legislation on the protection of personal data, including the Federal Law dated July 27, 2006 No. 152-FL “About Personal Data”.</p>

        <h3>2. DEFINITIONS AND TERMS</h3>

        <p><strong>Sites and Services</strong> are a collection of interconnected web pages hosted on the Internet at <strong>iageengineering.net</strong> and <strong>iae.one</strong>, as well as their subdomains.</p>
        <p><strong>Subdomain</strong> is a page or a collection of pages located on third-level domains owned by <strong>iAGE Engineering LLC</strong>, as well as other temporary pages, at the bottom of which is the contact information of the Company.</p>
        <p><strong>User</strong> is a person having access to the Site or Services via the Internet and using information, materials and products of the Company.</p>

        <h3>3. USER CHOICES</h3>

        <h4>TARGETED WEB ADS</h4>

        <p>
            If you do not want to receive personalized advertising in your current browser, then you can disable it by clicking on this
            <a href="https://www.iageengineering.net/opt-out" target="_blank">link</a>. Please note that cookies and other device identifiers vary across browsers and devices, this “disclaimer” only applies to this browser and this device.
        </p>

        <h4>TARGETED IN-APP ADS</h4>

        <p>
            <strong>Android</strong> users (version 2.3 and above). To use the «opt-out of interest-based advertising» option, follow the instructions provided by Google
            <a href="https://support.google.com/ads/answer/2662922" target="_blank">here</a>. If you don’t have the Google Play Services installed on your device, you won’t receive <strong>iAGE Engineering LLC</strong> services. Please note that this is a device setting and will disable interest-based ads from all providers.
        </p>
        <p>
            iOS users (version 6 and above) To use the «Limit Ad-Tracking» option, follow the instructions provided by Apple
            <a href="https://support.apple.com/en-us/HT202074" target="_blank">here</a>. Please note that this is a device setting and will disable interest-based ads from all providers.
        </p>
        <p>Please also note that some mobile applications may use or rely on a technical feature called «webview» that allows app developer’s to display web applications or web pages directly in their application without leaving the application to open your usual browser. A webview can be independent both of your usual browser and the mobile application environment. Therefore we would recommend to opt out also from this specific environment if you do not want to be served with <strong>iAGE Engineering LLC</strong> advertisements.</p>
        <p>Enabling these options will disable<strong>iAGE Engineering LLC</strong> ads and data collection both in the web and in mobile application environments as long as these options remain enabled. Your choice will be effective as soon as we recognize your device on our partners network.</p>
        <p>Please note that if you update the browser or operating system of your device, or if you deactivate the «Limit Ad Tracking» or «Ask websites not to track me» options, we may no longer be able to know that you have opted out.</p>

        <h3>4. WHAT INFORMATION WE COLLECT</h3>

        <p>We do not collect personal data that can identify you (the Internet user) in the “real world”. When you visit the Site, we do not know your name, email address or any information that directly identifies you.</p>
        <p>
            We perform automated processing of the following data obtained through our Services:
        </p>
        <ul>
            <li>country, city and postal code;</li>
            <li>electronic data (HTTP-headers, IP-address, cookies, web beacons/pixel tags, browser identifier data, hardware and software information);</li>
            <li>latitude and longitude;</li>
            <li>mobile identifiers;</li>
            <li>the date and time of access to our Services.</li>
        </ul>
        <p>If you entered into correspondence with us, then we can keep a copy of it.</p>

        <h3>5. HOW WE PROCESS DATA</h3>

        <p>Our platform uses the following technologies for data processing:</p>
        <p><strong>Cookie</strong>. This is a small piece of data sent by a web server and stored on the user’s computer. By storing information in a Cookie, our Services can remember preferences or recognize web browsers of one visit from another.</p>
        <p><strong>Pixel</strong>. This is a 1×1 pixel image that allows Services or a third party that serves a pixel to collect data, display advertisements, measure advertising effectiveness, or prevent false advertising traffic.</p>
        <p><strong>Development Toolkit</strong>. This is a block of code or algorithm similar to Pixel, which is embedded in the application and allows our Services to receive certain information related to the use of the application.</p>
        <p>We can also receive data from a third party through an inter-server connection or through secure file transfer.</p>

        <h3>6. HOW THE COLLECTED DATA IS USED</h3>

        <p>The collection of this information allows us to understand better what content you are interested in. We use this information for our internal analytical purposes and to improve the quality and relevance of our services.</p>
        <p>The information we collect about you may be used by us or transferred to third parties by us in any way and for any purposes permitted by law.</p>
        <p>First of all, we use information for the following purposes:</p>

        <ul>
            <li>Ensuring the correct operation of the relevance of the advertising campaign;</li>
            <li>Creation of advertising segments;</li>
            <li>Analyze and report on the effectiveness of an advertising campaign;</li>
            <li>Detect fraudulent traffic.</li>
        </ul>

        <h3>7. THIRD PARTY LINKS</h3>

        <p>At our discretion, we may include or offer third-party products or services. These third-party sites have separate and independent privacy policies. Therefore, we are not responsible for the content and activities of these sites. You should familiarize yourself with applicable third-party privacy policies and terms of use when visiting any external sites, as well as before providing any personal information to such external sites.</p>

        <h3>8. CHILDREN’S PRIVACY</h3>

        <p>We do not knowingly collect, store or use Personal Information from children under the age of 13 years (and in some jurisdictions under the age of 16 years), and no part of our Services is intended for children under the age of 13 years (and in some jurisdictions under the age of 16). If you find out that your child has provided us with personal information without your consent, you can notify us at contact-us@iageengineering.net. If we find out that we have collected any personal information from children under the age of 13 (and in some jurisdictions under the age of 16), we will immediately take steps to remove such information and cancel the child’s account.</p>

        <h3>9. SECURITY AND DATA STORAGE</h3>

        <p>We collect and save data within 12 months from the date of their receipt. Our cookies expire 30 days after their last update.</p>

        <h3>10. CONTACT US</h3>

        <p>
            Any suggestions or questions regarding this Privacy Policy should be reported to:
            <strong>contact-us@iageengineering.net</strong>
        </p>

        <p class="last-updated">Last updated: 8 July 2019</p>

    </ng-container>

</div>