<div class="tabs" [ngClass]="{'disabled': disabled}">
    <div
         class="tab"
         [ngClass]="{
            'selected': !!tab.selected,
            'changed':  !!tab.changed,
            'disabled': !!tab.disabled
         }"
         (click)="select(i)"
         *ngFor="let tab of tabs; index as i">
        <span>{{tab.title}}</span>
    </div>
</div>