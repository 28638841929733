import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import * as cookie from 'js-cookie'

import { environment } from 'environments/environment'
import { UiService } from './ui.service'
import { MessageBusService } from './messageBus/message-bus.service'
import { EventLoggedIn, EventLoggedOut } from './messageBus/events'
import { AppTranslationService } from './app-translation.service'
import { IAuthData } from '../models'
import { UserIdleService } from '../../app/services/user-idle.service'
import { LocalStorageFiltersCrud } from '../components/ui/data-table/dt-search/local-storage-filters-crud'


@Injectable({ providedIn: 'root' })
export class AuthService {
    private readonly AUTH_COOKIE_LIFETIME: number = 6 * 30 * 24 * 60 * 60 * 1000
    private readonly STORAGE_KEY = 'IBAUTH'
    private jwtHelper: JwtHelperService

    private _token: string
    public get token(): string {
        return this._token
    }

    private _authData: IAuthData = null
    public get authData(): IAuthData {
        return this._authData
    }

    public get isAdmin(): boolean {
        return this._authData && this._authData.role.toLowerCase() === 'administrator'
    }

    public get isAgency(): boolean {
        return this._authData && this._authData.role.toLowerCase() === 'agency'
    }

    public get isAdvertiser(): boolean {
        return this._authData && this._authData.role.toLowerCase() === 'advertiser'
    }

    public get isLoggedIn(): boolean {
        if (this._token && !this._authData) {
            this.decodeAndSaveToken(this._token)
        }

        return this._authData && !isNaN(this.authData.id) && this.authData.role && this.authData.role.length > 0
    }

    constructor(
        protected router: Router,
        protected ui: UiService,
        protected bus: MessageBusService,
        protected appTranslate: AppTranslationService,
        protected idleSvc: UserIdleService,
    ) {
        this.jwtHelper = new JwtHelperService()
        this._token = this.getAuthCookie()
    }


    public login(token: string, redirect: boolean): void {
        this._token = token
        this.decodeAndSaveToken(token)
        this.setAuthCookie(token)
        if (redirect) {
            window.location.href = document.referrer
        } else {
            this.router.navigate(['/app']).then()
        }
        this.bus.emit(new EventLoggedIn(this.authData.role))
    }

    public logout(): void {
        LocalStorageFiltersCrud.flushAllFilters()
        this.idleSvc.destroy()
        this.deleteAuthCookie()
        this._authData = null
        this.appTranslate.useDefaultLanguage()
        this.router.navigate(['/auth'])
        this.bus.emit(new EventLoggedOut(null))
    }

    public decodeAndSaveToken(token?: string): void {
        const decodedToken: any = this.jwtHelper.decodeToken(token)
        this._token = token
        this._authData = {
            aud: decodedToken.aud,
            exp: new Date(decodedToken.exp * 1000),
            id: parseInt(decodedToken.id, 10),
            iss: decodedToken.iss,
            nbf: parseInt(decodedToken.nbf, 10),
            role: decodedToken.role,
        }
    }

    public checkLoginState(): void {
        if (this._authData) {
            const sessionExpired = this._authData.exp.getTime() - new Date().getTime() < 0
            if (sessionExpired) {
                this.logout()
            }
        }
    }

    private setAuthCookie(token: string): void {
        cookie.set(this.STORAGE_KEY, token, {
            expires: new Date(Date.now() + this.AUTH_COOKIE_LIFETIME),
            path: '/',
            sameSite: 'strict',
            secure: environment.production,
        })
    }

    private getAuthCookie(): string {
        return cookie.get(this.STORAGE_KEY)
    }

    private deleteAuthCookie(): void {
        cookie.remove(this.STORAGE_KEY, { path: '/' })
    }
}
