import { style, sequence, animate, animation } from '@angular/animations'


const transitionCss = '350ms cubic-bezier(0.215, 0.61, 0.355, 1)'

export const slideEnterAnimation = animation([
    style({
        height: 0,
        opacity: 0,
        overflow: 'hidden',
    }),
    sequence([
        animate(transitionCss, style({ height: '*', opacity: 1 })),
        animate('1ms linear', style({ overflow: 'visible' })),
    ]),
])

export const slideLeaveAnimation = animation([
    style({
        height: '*',
        opacity: 1,
        overflow: 'visible',
    }),
    sequence([
        animate('1ms linear', style({ overflow: 'hidden' })),
        animate(transitionCss, style({ height: 0, opacity: 0 })),
    ]),
])


export const scaleEnterAnimation = animation([
    style({ transform: 'scaleY(0.5)', opacity: 0 }),
    animate(transitionCss, style({ transform: 'scaleY(1)', opacity: 1 })),
])

export const scaleLeaveAnimation = animation([
    style({ transform: 'scaleY(1)', opacity: 1, height: '*' }),
    animate(transitionCss, style({ transform: 'scaleY(0.5)', opacity: 0 })),
])