import { NgcCookieConsentConfig } from 'ngx-cookieconsent'
import { environment } from '../environments/environment'

export const cookieConsentConfig: NgcCookieConsentConfig = {
    enabled: environment.cookieConsentBlockEnabled,
    cookie: {
        domain: environment?.cookieConsentDomain || '',
    },
    whitelistPage: ['test'],
    theme: 'edgeless',
    type: 'opt-out',
    layout: 'my-custom-layout',
    layouts: {
        'my-custom-layout': '{{messagelink}}{{compliance}}',
    },
    elements: {
        messagelink: '<span id="cookieconsent:desc" class="cc-message">{{message}}<a class="cc-link" target="_blank" href="{{privacyPolicyHref}}">{{privacyPolicyLink}}</a></span>',
    },
    content: {
        message: 'This website uses cookies to improve your experience. We\'ll assume you\'re ok with this, but you can opt-out if you wish.',
        privacyPolicyLink: 'Read more.',
        privacyPolicyHref: 'privacy-policy',
    },
}