<div #topLevelElement class="dropdown-wrapper" [ngClass]="cssClass">

   <div class="label-valued" [ngClass]="{'visible': value?.toString().length > 0}">
      <span>{{placeholder}}</span>
   </div>

   <div class="dropdown-search dropdown-{{size}}" [attr.data-dropdown-id]="uniqueId">

      <div
         class="action-icon fa fa-remove"
         (click)="clearValue()"
         [hidden]="isDisabled || !nullable  || !selected"
      ></div>

      <div
         class="action-icon fa"
         [ngClass]="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
         [attr.data-dropdown-id]="uniqueId"
         [hidden]="isDisabled || nullable && selected"
         (click)="toggleList()">
      </div>

      <div class="search-area">
         <input
            type="text"
            #searchInput
            ignoreOnEnter
            [attr.data-dropdown-id]="uniqueId"
         />
      </div>

      <div class="current-value" #currentValue (click)="toggleList()"
           [attr.data-dropdown-id]="uniqueId">
         {{displayName}}
      </div>

      <div class="list-wrapper" [hidden]="isDisabled">
         <ul class="list" #list>
            <ng-container *ngFor="let item of filteredListItems; index as i; trackBy: trackByFn">
               <li *ngIf="!item.disabled"
                   class="item"
                   [ngClass]="{'selected': item.selected}"
                   (click)="itemClick(i)">

                  <span *ngIf="showIds" class="item-id">{{item.id}}</span>

                  <ng-container *ngIf="item.name && item.name.length > 0">{{item.name}}</ng-container>
                  <ng-container *ngIf="!item.name || item.name.length == 0">&nbsp;</ng-container>
               </li>


               <li *ngIf="item.disabled && !hideDisabledItems" class="item disabled">
                  <ng-container *ngIf="item.name && item.name.length > 0">{{item.name}}</ng-container>
                  <ng-container *ngIf="!item.name || item.name.length == 0">&nbsp;</ng-container>
               </li>
            </ng-container>
         </ul>
      </div>
   </div>

   <ng-content select="label-optional"></ng-content>

   <div class="hint-wrapper" *ngIf="hint && hint.length > 0">
      <help-hint [text]="hint"></help-hint>
   </div>

</div>
