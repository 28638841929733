export interface IChipFilterItem<T> {
    id: number
    type: T
    key: string
    name?: string
    selected: boolean
    static?: boolean
    viewTitle?: string
    bool?: boolean
    isHidden?: () => boolean
}
