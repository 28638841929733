import { Component, Input, forwardRef } from '@angular/core'
import { trigger, transition, useAnimation } from '@angular/animations'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import * as animations from 'shared/animations/animations'
import { IAnimatedListItem } from 'shared/models/ui/IAnimatedLisItem'


@Component({
    selector: 'text-list',
    templateUrl: './text-list.component.html',
    styleUrls: ['./text-list.component.less'],
    animations: [
        trigger('itemAnimation', [transition(':enter', [useAnimation(animations.scaleEnterAnimation)])]),
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextListComponent),
            multi: true,
        },
    ],
})
export class TextListComponent implements ControlValueAccessor {
    list: IAnimatedListItem<string>[] = [{ value: '', hidden: false }]

    @Input()
    public label: string = null

    @Input()
    public optional = true

    @Input()
    public maxLength = 3

    @Input()
    public hint?: string = ''

    @Input()
    public hintInside = false

    @Input()
    public multiline = true

    @Input()
    public symbolsCount?: number = null

    private _animationsOn = false

    public get animationsOn(): boolean { return this._animationsOn }

    constructor() { }

    protected emitValue(): void {
        this.onChange(this.list.map(item => item.value))
    }

    protected add(): void {
        this.list.push({ value: '', hidden: false })
        this.emitValue()
    }

    protected delete(index: number): void {
        this.list.splice(index, 1)
        this.emitValue()
    }

    protected trackByFn(index): number {
        return index
    }


    private onChange = (_value: string[] | null): void => undefined
    public registerOnChange(fn: (value: string[] | null) => void): void { this.onChange = fn }

    public onTouched = (): void => undefined
    public registerOnTouched(fn: () => void): void { this.onTouched = fn }


    writeValue(value: string[]): void {
        if (value === null) {
            return
        }

        const isEqual = this.list.map(i => i.value).join('') === value.join('')

        if (isEqual) {
            return
        }

        if (!this._animationsOn && value) {
            setTimeout(() => this._animationsOn = true, 100)
        }

        this.list = value.map(v => <IAnimatedListItem<string>>{ value: v, hidden: false })
        this.emitValue()
    }
}
