import { Injectable, TemplateRef } from '@angular/core'
import { Subject, BehaviorSubject } from 'rxjs'

import { SearchEvent } from '../models'


@Injectable({ providedIn: 'root' })
export class DtSearchService {

    private _filtersForm: BehaviorSubject<TemplateRef<any>> = new BehaviorSubject(null)
    public get filtersForm() { return this._filtersForm.asObservable() }

    public filter: any

    private _defaultFilterState: any
    public get defaultFilterState() { return this._defaultFilterState }

    private _searchEvent: Subject<SearchEvent<any>> = new Subject()
    public get searchEvent() { return this._searchEvent.asObservable() }

    private _resetFilters = new Subject<void>()
    public get onResetFilters() { return this._resetFilters.asObservable() }
    public resetFilters() { this._resetFilters.next() }

    private enabled = new BehaviorSubject<boolean>(null)
    public get enabled$() { return this.enabled.asObservable() }

    private _triggerSearch = new Subject<void>()
    public triggerSearchThroughService() { this._triggerSearch.next() }
    public onTriggerSearchThroughService() { return this._triggerSearch.asObservable() }

    public search(event: SearchEvent<any>) { this._searchEvent.next(event) }

    // todo
    // try to make this typed
    public enable(form: TemplateRef<any>, filter: any, defaultFilterState: any): void {
        this._filtersForm.next(form)
        this.filter = filter
        this._defaultFilterState = defaultFilterState
        this.enabled.next(true)
    }

    public disable() {
        this.enabled.next(false)
    }
}

