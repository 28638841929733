<div class="chips-dropdown-wrapper" [ngClass]="cssClass">

    <div class="label-valued visible">
        <span>{{placeholder}}</span>
    </div>

    <div class="chips-dropdown-search chips-dropdown-{{size}}">

        <div class="action-icon fa fa-remove" (click)="clearAll()"
             [hidden]="isDisabled || !clearable  || !selected">
        </div>

        <div class="action-icon fa" [ngClass]="{'fa-caret-down': !opened, 'fa-caret-up': opened}"
             [hidden]="isDisabled && selected"
             (click)="toggleList($event)">
        </div>

        <div class="current-value"
             [class.with-chips]="selected"
             (click)="toggleList($event)"
        >
            <ul class="chips-list">
                <li *ngFor="let chip of selectedChips; index as i" class="chip-item">
                    {{chip.name}}
                    <i class="fa fa-remove remove-chip" (click)="removeChip(chip, i)"></i>
                </li>
                <li class="search-container">
                    <input #search
                           class="search"
                           [style.width]="((searchText.length + 1) * 8) + 'px'"
                           (keydown.enter)="selectByEnter()"
                           [placeholder]="'shared.search' | translate"
                    />
                </li>
            </ul>
        </div>

        <div class="list-wrapper" [hidden]="isDisabled">
            <ul class="list">
                <ng-container *ngFor="let item of itemsList; index as i; trackBy: trackByFn">
                    <li *ngIf="!item.selected"
                        class="item"
                        (click)="itemClick(i)"
                    >
                        <ng-container *ngIf="item.name && item.name.length > 0">{{item.name}}</ng-container>
                    </li>
                </ng-container>

                <div class="no-results" *ngIf="itemsList.length === 0">{{ 'shared.noResults' | translate }}</div>
            </ul>
        </div>
    </div>
</div>
