import { Component, Input } from '@angular/core'

@Component({
    selector: 'dt-name-cell',
    templateUrl: 'dt-name-cell.component.html',
})
export class DTNameCellComponent {
    @Input()
    public id?: string

    @Input()
    public name?: string

    @Input()
    public description?: string

    @Input()
    public routerLink?: any

    @Input()
    public clickAction?: Function
}
