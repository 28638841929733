import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core'
import { fromEvent, Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { IChipFilterItem } from './model'
import { FilterBaseView } from './components/views/base-view.component'
import { filterHasValue, FilterWrapperComponent, IFilterValue } from 'shared'
import { DtSearchService } from '../../../services'

@Component({
    selector: 'filters-chips',
    templateUrl: './filters-chips.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersChipsComponent implements OnDestroy {

    @ViewChild('filters', { read: ViewContainerRef, static: true })
    filtersContainer

    @ViewChild('staticFilters', { read: ViewContainerRef, static: true })
    staticFiltersContainer

    @Input()
    public filtersList: IChipFilterItem<Type<any>>[] = []
    selectedFilters: IChipFilterItem<Type<any>>[] = []

    @Input()
    public model: IFilterValue

    @Input()
    public size? = 'medium'

    @Input()
    public clearable = true

    get hasFilters(): boolean {
        return this.filtersList.some(f => !f.selected)
    }

    public get isDisabled(): boolean {
        return !this.filtersList || this.filtersList.length === 0
    }

    get scrollable(): boolean {
        return this.filtersList.filter(f => !f.selected).length > 5
    }

    opened = false
    destroy$ = new Subject<void>()

    public get cssClass() {
        return {
            'opened': this.opened,
            'closed': !this.opened,
            'disabled': this.isDisabled,
            'scrollable': this.scrollable,
        }
    }

    constructor(private cdr: ChangeDetectorRef,
                private resolver: ComponentFactoryResolver,
                private dtSearch: DtSearchService,
    ) {
        fromEvent(document, 'click')
            .pipe(takeUntil(this.destroy$))
            .subscribe((event: MouseEvent) => {
                const target = event.target as HTMLElement
                if (!target.classList.contains('dt-search-add-filter')) {
                    this.setOpenState(false)
                }
            })
    }

    public ngOnInit(): void {
        this.filtersList
            .filter(f => f.static)
            .forEach((f) => this.attachFilterComponent(f, this.staticFiltersContainer, false))

        this.filtersList
            .filter(f => !f.static && filterHasValue(this.model[f.key]))
            .forEach(f => {
                f.selected = true
                this.selectedFilters.push(f)
                this.attachFilterComponent(f, this.filtersContainer, false)
            })

        this.dtSearch.searchEvent
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.filtersList
                    // remove selected non-static filters that has no value(after reset)
                    .filter(f => !f.static && f.selected && !filterHasValue(this.model[f.key]))
                    .forEach(f => this.destroyFilterComponent(f))
            })
    }

    private filtersCompRefMap = new Map<number, ComponentRef<any>>([])
    private attachFilterComponent(
        filter: IChipFilterItem<Type<Component>>,
        filterContainer: ViewContainerRef,
        init = true,
    ): void {
        const filterWrapperRef = this.renderComp(FilterWrapperComponent, filterContainer)
        filterWrapperRef.instance.remove.subscribe(() => this.destroyFilterComponent(filter))
        filterWrapperRef.instance.static = filter.static

        this.filtersCompRefMap.set(filter.id, filterWrapperRef)
        this.cdr.detectChanges()

        const { instance: filterInstance } = this.renderComp(filter.type, filterWrapperRef.instance.filterDropdown)
        const filterViewRef = this.renderComp(FilterBaseView, filterWrapperRef.instance.filterView)

        filterInstance.filterView.subscribe(fview => filterViewRef.instance.filterView = fview)
        filterInstance.hasValue.subscribe(hasValue => filterWrapperRef.instance.filterHasValue = hasValue)
        filterViewRef.instance.title = filterWrapperRef.instance.title = filter.viewTitle || 'shared.filter'
        filterViewRef.instance.bool = filter.bool

        filterInstance.key = filter.key
        filterInstance.setModel = this.model
        filterViewRef.instance.model = this.model

        filterViewRef.changeDetectorRef.detectChanges()

        if (init) {
            filterWrapperRef.instance.init()
        }
    }

    private renderComp(type, vcRef: ViewContainerRef): ComponentRef<any> {
        const componentFactory = this.resolver.resolveComponentFactory(type)
        const componentRef = vcRef.createComponent(componentFactory)

        return componentRef
    }

    private destroyFilterComponent(filter: IChipFilterItem<Type<Component>>) {
        const comp = this.filtersCompRefMap.get(filter.id)
        if (comp) {
            filter.selected = false
            comp.destroy()
            this.cdr.detectChanges()
        }
    }

    public itemClick(index: number): void {
        const filter = this.filtersList[index]
        filter.selected = true
        this.selectedFilters.push(filter)
        this.attachFilterComponent(filter, this.filtersContainer)

        this.setOpenState(false)
        this.cdr.detectChanges()
    }

    public toggleList(): void {
        if (this.filtersList.every(f => f.selected)) return
        this.setOpenState(!this.opened)
    }

    private setOpenState(isOpened: boolean): void {
        if (this.isDisabled) {
            return
        }
        this.opened = isOpened

        this.cdr.detectChanges()
    }

    public ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
