export const FiltersStorageKeysMap = {
    GroupSearchFilter: 'groupsFilters',
    ClientsSearchFilter: 'clientsFilter',
    BannersSearchFilter: 'bannerFilters',
    CampaignsSearchFilter: 'campaignsFilter',
    UsersSearchFilter: 'usersFilter',
    SSPSearchFilter: 'sspFilters',
    ReportSearchFilter: 'reportsFilters',
    ContainersSearchFilter: 'containersFilters',
    TargetSearchFilter: 'targetsFilters',
}