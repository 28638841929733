export enum IQuickFilterOps {
    equal = 'eq',
    notEqual = 'ne',
}

export interface IQuickFilter {
    columnId: number
    op: IQuickFilterOps
    values: Array<string>
}
