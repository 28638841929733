<div class="collapsable-area" [ngClass]="{'collapsed': collapsed, 'disabled': disabled}">

    <div class="collapsable-area-header" (click)="toggle()">
        <div class="header-title">
            <ng-container *ngIf="title; else templateTitle">
                {{title}}
            <span *ngIf="required" class="required-asteriks"></span>
            </ng-container>
        </div>
        <div class="header-state">
            <span *ngIf="collapsed"><i class="fa fa-chevron-down"></i></span>
            <span *ngIf="!collapsed"><i class="fa fa-chevron-up"></i></span>
        </div>
    </div>

    <div class="collapsable-area-content" [hidden]="collapsed">
        <ng-content></ng-content>
    </div>

</div>

<ng-template #templateTitle>
    <ng-content select="[collapse-title]"></ng-content>
</ng-template>
