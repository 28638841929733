﻿<span class="sorting-arrow"
       [ngClass]="{'sorting-arrow-selected': currentOrdering.direction != 'desc' && currentOrdering.field == fieldName}"
       (click)="sort('asc')">
    <i class="fa fa-chevron-up"></i>
</span>
<span class="sorting-arrow"
      [ngClass]="{'sorting-arrow-selected': currentOrdering.direction != 'asc' && currentOrdering.field == fieldName}"
      (click)="sort('desc')">
    <i class="fa fa-chevron-down"></i>
</span>
