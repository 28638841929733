import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core'
import { fromEvent, Subscription } from 'rxjs'
import { filter, first, tap } from 'rxjs/operators'

import { UiService } from '../../../services'

@Component({
  selector: 'side-modal',
  templateUrl: './side-modal.component.html',
  styleUrls: ['./side-modal.component.less'],
})
export class SideModalComponent {
    @Input() public title: string

    @Input() public set visible(value: boolean) { this.setVisible(value) }
    private _visible = false
    protected get isVisible(): boolean { return this._visible }

    @Output() public closed = new EventEmitter<void>()

    @HostListener('document:keyup', ['$event'])
    public handleKeyboardEvent(event: KeyboardEvent): void {
        if (this._visible && event.key === 'Escape') {
            this.close()
        }
    }

    protected isActive = false

    @Input() public transitionDuration = 300

    constructor(
        protected ui: UiService,
        private element: ElementRef,
        private cdr: ChangeDetectorRef,
    ) {}

    private setVisible(visible: boolean): void {
        if (visible) {
            this._visible = true
            this.ui.addBodyClass('scroll-disabled')
            this.ui.showPageMask()
            this.cdr.detectChanges()
            setTimeout(() => {
                this.isActive = true
                this.cdr.detectChanges()
                fromEvent(document, 'click')
                    .pipe(
                        filter((event: MouseEvent) => {
                            const target = event.target
                            return this._visible && !this.element.nativeElement.contains(target)
                        }),
                        first(),
                        filter(() => this.isActive),
                        tap(() => this.close()),
                    )
                    .subscribe()
            })
        } else {
            this.isActive = false
            this.cdr.detectChanges()

            setTimeout(() => {
                this._visible = false
                this.ui.removeBodyClass('scroll-disabled')
                this.ui.hidePageMask()
                this.cdr.detectChanges()
            }, this.transitionDuration)
        }
    }

    protected close(): void {
        this.setVisible(false)

        this.closed.emit()
    }
}
