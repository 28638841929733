import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

import { IRadioGroupElement } from 'shared/models/ui/IRadioGroupElement'


@Component({
    selector: 'radio-group',
    templateUrl: 'radio-group.component.html',
    styles: [`
        .label {
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 15px;
        }
    `],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RadioGroupComponent),
            multi: true,
        },
    ],
})
export class RadioGroupComponent implements ControlValueAccessor {

    @Input()
    public label = ''

    @Input()
    public items: IRadioGroupElement[] = []

    @Input()
    public readonly = false

    @Input()
    public vertical = false

    private _selectedIndex?: number = null
    get selectedIndex(): number { return this._selectedIndex }

    value: string | number

    //#region ControlValueAccessor realization

    public writeValue(value: string | number): void {
        if (value === null) {
            this.value = null
        } else if (value !== undefined
            && this.items.map(x => x.value.toString()).indexOf(value.toString()) > -1) {
            const index = this.items.findIndex(item =>
                item.value.toString() === value.toString(),
            )
            if (index > -1) {
                this._selectedIndex = index
                this.value = value.toString()
                this.propagateChange(this.value)
            } else {
                this.value = null
            }
        }
    }

    propagateChange = (_value: string | number): void => undefined
    public registerOnChange(fn: () => void): void {
        this.propagateChange = fn
    }

    propagateTouched = (): void => undefined
    public registerOnTouched(fn: () => void): void {
        this.propagateTouched = fn
    }

    //#endregion

    //#region private methods

    public get isDisabled(): boolean {
        return this.readonly || !this.items || this.items.length === 0
    }


    private emitModelValue(value: string | number = null): void {
        const emittedValue = value !== null ? value : this.value
        this.propagateChange(emittedValue)
    }

    //#endregion

    //#region public methods

    itemClick(index: number): void {
        if (!this.isDisabled && !this.items[index].disabled) {
            this._selectedIndex = index
            const newValue: string | number = this.items[index].value
            this.emitModelValue(newValue)
            this.value = newValue
        }
    }

    //#endregion
}
