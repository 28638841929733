import { Component, Input } from '@angular/core'

@Component({
    selector: 'help-hint',
    templateUrl: 'help-hint.component.html',
    styleUrls: ['help-hint.component.less'],
})

export class HelpHintComponent
{
    @Input() public text: string

    @Input() public direction: 'left' | 'right' = 'right'
}