<div class="search-field" 
    [ngClass]="data.cssClass"
    *ngIf="{
        cssClass: cssClass$ | async,
        inProgress: inProgress$ | async
    } as data">

    <div class="search-border">

        <div class="search-reset-wrapper" *ngIf="data.inProgress">
            <div class="search-reset-icon" (click)="resetSearch()">
                <i class="fa fa-times"></i>
            </div>
        </div>

        <div class="search-input-wrapper">
            <input type="text" [attr.placeholder]="'shared.search'|translate" #searchInput />
        </div>

    </div>
</div>
