import { Component, Input, OnDestroy } from '@angular/core'

import { FilterBaseComponent } from '../filter-base-component'
import { IFilterValue } from 'shared'


@Component({
   templateUrl: './keyword-filter.component.html',
})
export class KeywordFilterComponent extends FilterBaseComponent implements OnDestroy {

   @Input() public title: string

   protected generateFilterView(filter: IFilterValue): void {
      this.filterView.emit(filter[this.key])
   }

   protected change(value: string): void {
      if (value) {
         this.hasValue.emit(true)
      } else {
         this.hasValue.emit(false)
      }
   }

   public ngOnDestroy(): void {
      this.model[this.key] = ''
   }
}
