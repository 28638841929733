import { AbstractControl, FormGroup } from '@angular/forms'

export function toFormGroup(dist: AbstractControl): FormGroup {
   return dist as FormGroup
}

export function markFormAsTouchedIfInvalid(form: FormGroup): boolean {
    if (form.invalid) {
        Object.keys(form.controls).forEach((key) => {
            form.controls[key].markAsTouched()
            form.controls[key].updateValueAndValidity({ emitEvent: false })
        })
    }
    return form.invalid
}
