import { Pipe, PipeTransform } from '@angular/core'


/*
 * Convert the value in B to KB, MB or GB.
 * Takes a dimension argument that defaults to MB.
 * Usage:
 *   value | byteConvert
 * Example:
 *   {{ 12288 | byteConvert }}
 *   formats to: 12 KB
*/
@Pipe({ name: 'byteConvert' })
export class ByteConvertPipe implements PipeTransform {
    transform(value: number): string {
        const count = value.toString().length
        let size = ''
        if (count >= 4 && count < 7) {
            size = `${this.calc(value, 1)} KB`
        } else
            if (count >= 7 && count < 10) {
                size = `${this.calc(value, 2)} MB`
            } else
                if (count >= 10 && count < 13) {
                    size = `${this.calc(value, 3)} GB`
                } else {
                    size = `${value} B`
                }
        return size
    }

    private calc(value: number, pow: number): string {
        return (value / Math.pow(2, 10 * pow)).toFixed(1)
    }
}
