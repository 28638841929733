import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { ByteConvertPipe } from '../../../pipes'


@Component({
    selector: 'file-loader',
    templateUrl: './file-loader.component.html',
    styles: [`
        :host {display: block;}
    `],
})
export class FileLoaderComponent implements OnDestroy {
    @Input() public accept = ''
    @Input() public file: File = null
    @Input() public label
    @Input() public validationMessages: string[] = []
    @Input() public maxSize: number
    @Output() public onLoad: EventEmitter<File> = new EventEmitter<File>()
    @Output() public onError: EventEmitter<string> = new EventEmitter<string>()

    @ViewChild('fileOpenBtn') private fileOpenBtn: ElementRef

    constructor(private i18n: TranslateService,
                private byteConverter: ByteConvertPipe,
    ) {
    }

    private _loadedFile: File = null
    get loadedFile() {
        return this._loadedFile
    }

    private _error = ''
    public get error(): string {
        return this._error
    }

    private emit(): void {
        this.onLoad.emit(this._loadedFile)
    }

    public fireFileDialogClickEvent(): void {
        this.fileOpenBtn.nativeElement.click()
    }

    public onFilesLoadedByInput(event): void {
        if (event?.target?.files?.length > 0) {
            const file = (event.target.files as FileList)[0]
            this._loadedFile = this.filterFileBySize(file)
            if (this._loadedFile) {
                this.emit()
            }
        }
    }

    public reset(): void {
        this._loadedFile = null;
        (this.fileOpenBtn.nativeElement as HTMLInputElement).value = ''
        this.emit()
    }

    private filterFileBySize(file: File): File | null {
        let result = file
        if (file.size > this.maxSize) {
            const value = this.byteConverter.transform(this.maxSize)
            const template = this.i18n.instant('app.creatives.fileRestictions.maxSizeTemplate', {value})
            this.onError.next(`${ template } ${ value }`)
            result = null
        }
        return result
    }

    public ngOnDestroy(): void {
        this._loadedFile = null
    }
}
