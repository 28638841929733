import { AfterContentInit, Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { distinctUntilChanged, filter } from 'rxjs/operators'

import { IFilterValue } from 'shared/models/ui/IFilterValue'
import { LocalStorageFiltersCrud } from 'shared/components/ui/data-table/dt-search/local-storage-filters-crud'
import { DtSearchService, UiService } from '../../../../services'
import { SearchEvent } from '../../../../models'


@Component({
    selector: 'dt-search',
    templateUrl: 'dt-search.component.html',
})
export class DTSearchComponent extends LocalStorageFiltersCrud implements AfterContentInit, OnDestroy {

    public key: string
    private currentEntityId: number

    private _focused = false
    protected get focused(): boolean {
        return this._focused
    }

    private subscriptions: Subscription[] = []

    public filtersForm: any

    public filter: IFilterValue

    constructor(
        protected dtSearch: DtSearchService,
        protected actRoute: ActivatedRoute,
        protected router: Router,
        protected ui: UiService,
    ) {
        super()
        this.subscriptions.push(
            this.dtSearch.onResetFilters.subscribe(this.reset.bind(this)),
            this.dtSearch.onTriggerSearchThroughService().subscribe(this.emitSearchEvent.bind(this)),
        )
    }

    public ngAfterContentInit(): void {
        this.setEntityLastUrlId()

        this.subscriptions.push(this.dtSearch.filtersForm.subscribe(
            form => {
                this.filtersForm = form
                const savedFilters = this.restoreFilters()
                this.filter = savedFilters || this.dtSearch.filter
                setTimeout(() => {
                    this.emitSearchEvent()
                })
            },
        ))

        this.subscriptions.push(this.dtSearch.searchEvent.subscribe(
            (event: SearchEvent<any>) => {
                for (const key in event.filters) {
                    this.filter[key] = event.filters[key]
                }
                this.calculateChanged()
            }))
    }

    private calculateChanged(): void {
        const lsKey = this.filter.localStgKey

        const changed = JSON.stringify(this.filter) !== JSON.stringify(this.dtSearch.defaultFilterState)
        changed
            ? this.saveFitlers(lsKey, { ...this.filter, entityId: this.currentEntityId })
            : this.flushFilters(lsKey)
    }

    protected get searchEnabled$(): Observable<boolean> {
        return this.dtSearch.enabled$.pipe(
            filter(val => val !== null),
            distinctUntilChanged(),
        )
    }

    protected emitSearchEvent(): void {
        const filter: any = {}

        Object.getOwnPropertyNames(this.filter).forEach(propName => {
            filter[propName] = this.filter[propName]
        })

        this.dtSearch.search(new SearchEvent(filter))
    }

    protected reset(): void {
        const defaultFilter = JSON.parse(JSON.stringify(this.dtSearch.defaultFilterState))
        for (const key in defaultFilter) {
            this.filter[key] = defaultFilter[key]
        }
        this.flushFilters(this.filter.localStgKey)
        this.emitSearchEvent()
    }

    private restoreFilters(): IFilterValue | undefined {
        const localStgKey = this.dtSearch.filter.localStgKey
        const flushOnNewEntity = this.dtSearch.filter.flushOnNewEntity
        return flushOnNewEntity
            ? this.getFilterBasedOnState(localStgKey)
            : this.getFilters(localStgKey)
    }

    // returns saved filter if user enters same Entity or empty filters for new entity
    // visit Campaign{id: 1} -> revisit Campaign{id: 1} = filters restored
    // visit Campaign{id: 1} -> visit Campaign{id: 3}   = filters flushed, empty object
    private getFilterBasedOnState(id: string): IFilterValue | undefined {
        const filters = this.getFilters(id)
        const entityId = filters && filters.entityId

        return entityId === this.currentEntityId
            ? filters
            : undefined
    }

    private setEntityLastUrlId(): void {
        // get last id out of url: /route1/id1/route2/id2.../id3
        // gets id3 or undefined
        const urlSegment = this.actRoute.snapshot.url
            .reverse()
            .find(urlSegment => !isNaN(+urlSegment.path))

        this.currentEntityId = urlSegment ? +urlSegment.path : undefined
    }

    public ngOnDestroy(): void {
        this.dtSearch.disable()
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }
}
