import {
   ChangeDetectionStrategy,
   ChangeDetectorRef,
   Component,
   ElementRef, EventEmitter,
   HostListener, Input, Output, Renderer2, ViewChild, ViewContainerRef,
} from '@angular/core'
import { fromEvent, Subscription } from 'rxjs'
import { Router } from '@angular/router'

import { DtSearchService } from 'shared/services'
import { PaginationService } from 'shared/services/pagination.service'


@Component({
   templateUrl: './filter-wrapper.component.html',
   styleUrls: ['./filter-wrapper.component.less'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   host: {
      '[class.pre-init]': '!inited',
      '[class.static]': 'static',
   },
})
export class FilterWrapperComponent {

   opened = false
   inited = true

   documentClick: Subscription

   @ViewChild('modal', {read: ElementRef}) modal: ElementRef

   @Input()
   public static = false

   @Input()
   public title = ''

   @Input()
   public set filterHasValue(value: boolean) {
      this._filterHasValue = value
   }

   public get filterHasValue(): boolean {
      return this._filterHasValue
   }

   private _filterHasValue = false

   @Output()
   remove = new EventEmitter()

   @ViewChild('filterView', { read: ViewContainerRef })
   filterView: ViewContainerRef

   @ViewChild('filterDropdown', { read: ViewContainerRef })
   filterDropdown: ViewContainerRef

   @HostListener('click', ['$event'])
   onClick({ target }) {
      if (!target.closest('.apply')) {
         this.setOpenState(true)
      }
   }

   public get cssClass() {
      return {
         'opened': this.opened,
         'closed': !this.opened,
      }
   }

   constructor(private cdr: ChangeDetectorRef,
               private _element: ElementRef,
               private dtSearch: DtSearchService,
               private renderer: Renderer2,
               private router: Router,
               private pagination: PaginationService,
   ) {
      this.documentClick = fromEvent(document, 'click')
         .subscribe((event: MouseEvent) => {
            const target = event.target as HTMLElement
            if (
               !this._element.nativeElement.contains(target)
               && !target.classList.contains('filter-list-item')
            ) {
               if (!this.inited) {
                  this.remove.emit()
               } else {
                  this.setOpenState(false)
               }
            }
         })
   }

   public init(): void {
      this.inited = false
      this.setOpenState(true)
   }

   private setOpenState(isOpened: boolean): void {
      if (isOpened) {
         this.opened = isOpened
         setTimeout(() => this.renderer.addClass(this.modal.nativeElement, 'animated'))
      } else {
         this.renderer.removeClass(this.modal.nativeElement, 'animated')
         setTimeout(() => {
            this.opened = isOpened
            this.cdr.detectChanges()
         })
      }
      this.cdr.detectChanges()
   }

   public removeFilter(): void {
      this.remove.emit()
      if (this.inited) {
         this.dtSearch.triggerSearchThroughService()
      }
   }

   apply(): void {
      if (!this.inited && this._filterHasValue) {
         this.inited = true
      }

      const section = this.router.url.split('/').reverse()[0]
      this.pagination.resetPagination(section)

      if (this.inited) {
         this.setOpenState(false)
         this.dtSearch.triggerSearchThroughService()
      }
   }

   public ngOnDestroy(): void {
      this.documentClick.unsubscribe()
   }
}
