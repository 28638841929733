import { ErrorHandler, NgModule } from '@angular/core'
import { CommonModule, DecimalPipe } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { RouterModule } from '@angular/router'

import { TranslateModule } from '@ngx-translate/core'
import { AutosizeModule } from 'ngx-autosize'
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask'
import { ClipboardModule } from 'ngx-clipboard'
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap'

import { DictionariesService, KeyEventService } from './services'
import {
    AutofocusDirective,
    EqualValidator,
    FormControlAsNumberDirective,
    LastElementDirective,
    NgModelValidator,
} from './directives'
import { ByteConvertPipe, DateTimePipe, DecimalSuffixPipe, GroupByPipe } from './pipes'
import {
    ActivityChangerComponent,
    AgencyFilterComponent,
    AgencyIdsFilterComponent,
    BreadcrumbComponent,
    CheckboxComponent,
    CheckboxGroupComponent,
    ChipsDropdownComponent,
    CollapsableAreaComponent,
    DropdownComponent,
    DTActionComponent,
    DTActionsComponent,
    DTNameCellComponent,
    DTPagerComponent,
    DTSearchComponent,
    DtSortableHeaderComponent,
    EditableFieldComponent,
    FileLoaderComponent,
    FilterBaseView,
    FiltersChipsComponent,
    FilterWrapperComponent,
    FormFieldErrorComponent,
    FormFieldValidationComponent,
    FormInputComponent,
    FormValidationComponent,
    HelpHintComponent,
    InputTextComponent,
    InputTextWrapperComponent,
    InputTextDateWrapperComponent,
    InputTextDateComponent,
    KeywordFilterComponent,
    LabelOptionalComponent,
    NotFoundPageComponent,
    OverlayComponent,
    PopupComponent,
    PrivacyPolicyComponent,
    RadioGroupComponent,
    SearchFieldComponent,
    StatusFilterComponent,
    TabsComponent,
    TextListComponent,
    TooltipComponent,
    ValuesListComponent,
    EntityTreeComponent,
    CreativeTypeFilterComponent,
    SideModalComponent,
    TypeFilterComponent,
    ButtonWithDropdownComponent,
    FilterSelectWrapper,
    SelectedFilterComponent,
} from './components'
import { TooltipDirective } from './components/ui/tooltip/tooltip.directive'
import { SearchPipe } from './pipes/filter-search.pipe'
import { GlobalErrorHandler } from './services/global-error-handler'

const COMPONENTS = [
    NotFoundPageComponent,
    PrivacyPolicyComponent,
    BreadcrumbComponent,
    CollapsableAreaComponent,
    PopupComponent,
    FormFieldValidationComponent,
    FormValidationComponent,
    FormFieldErrorComponent,
    FileLoaderComponent,
    DropdownComponent,
    RadioGroupComponent,
    ActivityChangerComponent,
    FormInputComponent,
    CheckboxComponent,
    TabsComponent,
    CheckboxGroupComponent,
    TextListComponent,
    InputTextComponent,
    InputTextWrapperComponent,
    InputTextDateWrapperComponent,
    InputTextDateComponent,
    HelpHintComponent,
    LabelOptionalComponent,
    ValuesListComponent,
    SearchFieldComponent,
    EditableFieldComponent,
    ChipsDropdownComponent,

    DTActionsComponent,
    DTActionComponent,
    DTSearchComponent,
    DTPagerComponent,
    DtSortableHeaderComponent,
    DTNameCellComponent,

    EqualValidator,
    NgModelValidator,
    AutofocusDirective,
    LastElementDirective,
    TooltipDirective,
    FormControlAsNumberDirective,

    ByteConvertPipe,
    DecimalSuffixPipe,
    DateTimePipe,
    GroupByPipe,
    TooltipComponent,
    FiltersChipsComponent,
    KeywordFilterComponent,
    StatusFilterComponent,
    AgencyFilterComponent,
    AgencyIdsFilterComponent,
    TypeFilterComponent,
    FilterWrapperComponent,
    FilterBaseView,
    SearchPipe,
    OverlayComponent,
    EntityTreeComponent,
    CreativeTypeFilterComponent,
    SideModalComponent,
    ButtonWithDropdownComponent,

    FilterSelectWrapper,
    SelectedFilterComponent,
]
const MODULES_FOR_EXPORT = [ClipboardModule, NgScrollbarModule]


@NgModule({
    imports: [
        FormsModule,
        RouterModule,
        NgxMaskDirective,
        NgxMaskPipe,
        TranslateModule.forChild(),
        AutosizeModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        ClipboardModule,
        NgScrollbarModule.withConfig({
            autoHeightDisabled: false,
            visibility: 'hover',
        }),
        NgbDatepickerModule,
    ],
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS, ...MODULES_FOR_EXPORT],
    providers: [
        DictionariesService,
        DecimalPipe,
        DateTimePipe,
        KeyEventService,
        provideNgxMask(),
        ByteConvertPipe,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],
})
export class SharedModule {
}
