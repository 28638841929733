import { Injectable } from '@angular/core'
import { RxwebValidators } from '@rxweb/reactive-form-validators'

@Injectable({ providedIn: 'root' })
export class UrlService {
    public static readonly protocols = ['http://', 'https://']

    public static get urlValidator() { return RxwebValidators.pattern({ expression: { 'notEmptyUrl': new RegExp('^(http|https)://.+', 'i') } }) }

    public static get defaultProtocol() { return UrlService.protocols[0] }

    constructor() { }

    public static isUrlProtocolOnly(value: string) {
        return value == UrlService.protocols[0] || value == UrlService.protocols[1]
    }

    public static prepareUrl(value: string): string {
        return value && value.length > 0 && !UrlService.isUrlProtocolOnly(value)
            ? value
            : ''
    }
}