import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms'


export function equalToValidator(fieldName: string): ValidationErrors {
    return (control: AbstractControl) => {
        const form = control.parent
        if (form) {
            const passwordValue = control.value
            const confirmPasswordValue = (<AbstractControl>control.parent.controls[fieldName]).value

            return passwordValue != confirmPasswordValue ? { 'equalTo': { value: control.value } } : null
        }
        return null
    }
}


export function notEmptyStringValidator(control: FormControl): ValidationErrors {
    const value = control.value

    if (value) {
        const clearedValue = value.toString().trim()

        if (clearedValue.length === 0) {
            return {
                notEmptyString: {
                    value: value,
                },
            }
        }
    }

    return null
}


export function numberValidator(control: FormControl): ValidationErrors {
    const value: string = control.value

    if (value) {
        const numberValue: number = parseFloat(value)

        if (isNaN(numberValue)) {
            return {
                number: {
                    value: value,
                },
            }
        }
    }

    return null
}


export function notZeroNumberValidator(control: FormControl): ValidationErrors {
    const value = control.value

    if (value === 0 || value === '0' || value === '0.0') {
        return {
            number: {
                value: value,
            },
        }
    }

    return null
}


export function positiveNumberValidator(control: FormControl): ValidationErrors {
    const value: string = control.value

    if (value) {
        const numberValue: number = parseFloat(value)

        if (isNaN(numberValue) || numberValue < 0) {
            return {
                number: {
                    value: value,
                },
            }
        }
    }

    return null
}


/*
export function requiredIfValidator (comparedFieldName: string, condition: (comparedControlValue: any) => boolean) {
    return (control: AbstractControl, key, descriptor) => {
        console.log('start requiredIf validator');
        setTimeout(() => {
            let comparedControl = <AbstractControl>(control.root.get(comparedFieldName));

            if (comparedControl) {
                if (condition(comparedControl.value)) {
                    if (!control.value || (control.value as string).length == 0) {
                        return { 'requiredIf': { value: control.value } };
                    }
                }
            }

            return null;
        });
    }
}
*/