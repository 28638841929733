<div class="radio-group-component" [ngClass]="{'vertical': vertical === true }">
    <div *ngIf="label" class="label">{{label}}</div>
    <div class="radio-group-item-wrapper"
         *ngFor="let item of items; index as i"
         (click)="itemClick(i)"
         [ngClass]="{
            'selected': selectedIndex === i,
            'disabled': !!item.disabled,
         }">

        <div class="dot">
            <div class="dot-inner"></div>
        </div>

        <div class="display-name">{{item.displayName}}</div>
    </div>

</div>
