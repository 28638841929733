<div class="checkbox-group-component">
    <div class="checkbox-group-wrapper">
        <ng-container *ngFor="let item of items; index as i">
            <checkbox [ngModel]="getState(item.value)"
                      (ngModelChange)="toggleState($event, item.value)"
                      [outerLabel]="item.displayName"
            ></checkbox>
        </ng-container>
    </div>
</div>
