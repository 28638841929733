import { Component, Input, EventEmitter, Output } from '@angular/core'


@Component({
    selector: 'activity-changer',
    templateUrl: 'activity-changer.component.html',
})
export class ActivityChangerComponent {

    private _value = false

    @Input()
    set value(val: boolean) {
        this._value = val
    }

    get value(): boolean {
        return this._value
    }

    @Input() public disabled = false

    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>()

    public toggle(): void {
        if (!this.disabled) {
            this._value = !this._value
            this.valueChange.emit(this._value)
        }
    }
}
