import { Injectable } from '@angular/core'
import Timeout = NodeJS.Timeout;

import { ICallbackFunction } from 'shared/services/messageBus/ICallbackFunction'


@Injectable({ providedIn: 'root' })
export class UserIdleService  {

    private readonly TICK_MS = 1000

    private secondsIdle: number
    private idleIntervalId: Timeout
    private timeouts: Timeout[] = []

    constructor() {
        window.addEventListener('mousemove', () => {
            this.secondsIdle = 1
        })
    }

    public init(): void {
        this.secondsIdle = 1
        if (!this.idleIntervalId) {
            this.idleIntervalId = setInterval(() => this.secondsIdle++, this.TICK_MS)
        }
    }

    /**
     * Allows to run function after some user idle timeout with tick frequency
     * @param callback {function} A Function to run after expireTime has reached user idle time
     * @param expireTime {number} Amount in seconds of user's idle time to wait for before running callback
     * @param tick {number} Check frequency in ms
     */
    public onExpire<T>(
        callback: ICallbackFunction<T>,
        expireTime: number,
        tick: number,
    ): void {
        this.timeouts.push(
            setInterval(() => {
                if (this.secondsIdle % expireTime === 0) {
                    callback.call(null)
                }
            }, tick),
        )
    }

    public destroy(): void {
        clearInterval(this.idleIntervalId)
        this.timeouts.forEach(timeout => clearInterval(timeout))
    }
}