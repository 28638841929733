import { Component, EventEmitter, Inject, Injector, Input, OnDestroy, Output } from '@angular/core'
import { takeUntil } from 'rxjs/operators'
import { Subscription } from 'rxjs'

import { DestroyService, DtSearchService } from 'shared/services'
import { filterHasValue, IFilterValue } from 'shared'


@Component({
    template: '',
    providers: [DestroyService],
})
export abstract class FilterBaseComponent implements OnDestroy {

    model: IFilterValue

    @Input()
    public set setModel(value: IFilterValue) {
        this.model = value
        if (filterHasValue(this.model[this.key])) {
            this.generateFilterView(value)
        }
    }

    @Input()
    public key: string

    @Output()
    public hasValue = new EventEmitter()

    @Output()
    public filterView = new EventEmitter()

    protected abstract generateFilterView(model: any): void
    protected abstract change(value: any): void

    dtSearch: DtSearchService
    destroy$: DestroyService

    protected subscriptions: Subscription[] = []

    constructor(@Inject(Injector) injector: Injector) {
        this.dtSearch = injector.get(DtSearchService)
        this.destroy$ = injector.get(DestroyService)

        this.subscriptions.push(
            this.dtSearch.searchEvent
                .pipe(takeUntil(this.destroy$))
                .subscribe(event => this.generateFilterView(event.filters)),
        )
    }

    protected unsubscribeToAll(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe())
    }

    public ngOnDestroy(): void {
        this.unsubscribeToAll()
    }
}
