import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Params } from '@angular/router'

@Component({
    selector: 'dt-action',
    templateUrl: 'dt-action.component.html',
})
export class DTActionComponent {
    @Input() public iconClass = ''

    @Input() public link?: Array<string | number> | string

    @Input() public queryParams: Params

    @Input() public externalLink?: string = ''

    @Input() public title?: string = ''

    @Output() public clickEvent?: EventEmitter<void> = new EventEmitter<void>()

    public get onClickSubscribed() {
        return this.clickEvent.observers.length > 0
    }

    public runClickEvent() {
        this.clickEvent.emit()
    }
}
