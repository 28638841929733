<a class="btn btn-primary btn-medium"
   (click)="buttonActive = !buttonActive; $event.stopPropagation()"
   translate>{{ buttonTitle }}</a>
<ul class="button-dropdown-list"
    [class.active]="buttonActive">
    <li *ngFor="let buttonDropdownItem of buttonDropdownItems"
        class="button-dropdown-item">
        <a class="link"
           [routerLink]="buttonDropdownItem.url"
           translate>{{ buttonDropdownItem.title }}</a>
    </li>
</ul>