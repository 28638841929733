<div class="input-text" [ngClass]="cssClass">

    <div class="symbols-counter-wrapper" *ngIf="showCounter && this.multiline !== true">
        {{control?.value?.length || 0}}/{{symbolsCount}}
    </div>

    <div class="error-wrapper" *ngIf="showErrors && !isValid && isDirty && isHovered">
        <form-field-error [messages]="errorMessages"></form-field-error>
    </div>

    <div class="form-input-wrapper" [ngClass]="{
            'with-errors': showErrors
        }"
    >
        <div class="label-valued" [ngClass]="{'visible': isFocused || control?.value !== undefined || control?.value !== null}">
            <span>{{label}}</span>
        </div>

        <ng-container *ngIf="!multiline">
            <input
                #textInput
                [formControl]="control"
                [attr.type]="showTheEye && hidePasswordStars ? 'text' : htmlType"
                [attr.name]="formControlName"
                [attr.placeholder]="!readonly && isFocused ? '' : label"
                [attr.autocomplete]="autocomplete || 'off'"
                [attr.id]="id && id.length > 0 ? id : ('input-text-' + uniqueId)"
                [maxlength]="symbolsCount ? symbolsCount : ''"
                [attr.tabindex]="tabIndex || ''"
                [readOnly]="readonly"
                [decimalMarker]="'.'"
                [mask]="inputMask"
                [thousandSeparator]="thousandSeparator"
                [dropSpecialCharacters]="dropSpecialCharacters"
                [showMaskTyped]="showInputMask"
                [placeHolderCharacter]="maskSymbol"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (mouseenter)="onMouseEnter()"
                (mouseleave)="onMouseLeave()"
                (input)="onChange(control.value)"
            />

            <div class="eye-wrapper" *ngIf="showTheEye">
                <div class="eye"
                     [ngClass]="{'active': hidePasswordStars}"
                     (click)="togglePasswordStarsVisibility()">

                    <i class="fa fa-eye-slash" *ngIf="!hidePasswordStars"></i>
                    <i class="fa fa-eye" *ngIf="hidePasswordStars"></i>
                </div>
            </div>

            <div class="postfix-wrapper" *ngIf="showPostfix">
                <span class="postfix">{{postfix}}</span>
            </div>

            <div class="password-strength-wrapper" *ngIf="showPasswordStrengthBlock">
                <div class="password-strength" [ngClass]="{'valid': passwordValid, 'invalid': !passwordValid }">
                    <div class="indicator-wrapper">
                        <div class="indicator" [ngStyle]="{'width': indicatorCssWidth}"></div>
                    </div>
                    <div class="counter">{{valueLength}}/{{minPasswordLength}}</div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="multiline">
            <textarea
                #textInput
                [autosize]="autosize"
                [minRows]="1"
                [attr.name]="formControlName"
                [attr.placeholder]="label"
                [maxlength]="symbolsCount ? symbolsCount : ''"
                [formControl]="control"
                [readOnly]="readonly"
                (focus)="onFocus()"
                (blur)="onBlur()"
                (mouseenter)="onMouseEnter()"
                (mouseleave)="onMouseLeave()"
                (change)="onChange(control.value)"
            ></textarea>

            <div class="symbols-counter-wrapper" *ngIf="showCounter">
                {{control?.value?.length}}/{{symbolsCount}}
            </div>
        </ng-container>

    </div>

    <label-optional *ngIf="optional"></label-optional>

</div>
