import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core'
import { fromEvent, Observable } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { FormControl, ValidatorFn } from '@angular/forms'


@Component({
    selector: 'editable-field',
    templateUrl: './editable-field.component.html',
    styleUrls: ['./editable-field.component.less'],
})
export class EditableFieldComponent implements OnInit {

    @Output()
    public onSubmit = new EventEmitter()

    @Input()
    public validators: ValidatorFn[] = []

    @Input()
    public mask: string

    @Input()
    public set value(val: number | string | undefined) {
        if (val) {
            this._initialValue = typeof val === 'number' ? val.toString() : val
            this.newValue.setValue(this._initialValue)
        }
    }
    private _initialValue: string

    private editModeInit = false
    protected viewMode = true

    private clickOutSide$: Observable<Event>
    protected newValue: FormControl = new FormControl('')

    private get element(): Element {
        return this.elemRef.nativeElement
    }

    @HostBinding('attr.class')
    public get mode(): string {
        return this.viewMode ? 'view-mode' : ''
    }

    @HostListener('click', ['$event'])
    public onClick(): void {
        this.viewMode = false

        if (!this.editModeInit) {
            this.editModeInit = true
            this.clickOutSide$ = fromEvent(document, 'click').pipe(
                filter((evnt: Event & { path: Node[] }) => {
                    // ff fallback
                    const path = evnt.path || (evnt.composedPath && evnt.composedPath())
                    return path.indexOf(this.element) === -1
                }),
                take(1),
            )

            this.clickOutSide$.subscribe(() => {
                this.editModeInit = false
                this.viewMode = true
                this.newValue.setValue(this._initialValue)
            })
        }
        this.cdRef.detectChanges()
    }

    constructor(
        private elemRef: ElementRef,
        private cdRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.newValue.setValidators(this.validators)
    }

    protected submit(event): void {
        event.stopPropagation()
        this.onSubmit.emit(this.newValue.value)
        this.viewMode = true
        this._initialValue = this.newValue.value
    }
}
