import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { ERIRStatus } from 'app/inventory/models/erir'


@Injectable({ providedIn: 'root' })
export class ErirStatusDescriptionService {

    private readonly ERIR_STATUS_AS_TEXT = {
        [ERIRStatus.Error]   : this.i18n.instant('shared.erir-state.error'),
        [ERIRStatus.Ok]      : this.i18n.instant('shared.erir-state.ok'),
        [ERIRStatus.Pending] : this.i18n.instant('shared.erir-state.pending'),
    }

    constructor(private i18n: TranslateService) {
    }

    public hint(prefix:string, type: string, status: ERIRStatus): string {
       return `${prefix} ${type} - ${this.get(status)}`
    }

    public get(status: ERIRStatus): string {
        return this.ERIR_STATUS_AS_TEXT[status]
    }
}

