import { IApiDto } from './IApiDto'
import { IApiDtoAdditions } from './IApiDtoAdditions'
import { IApiGovRegulation } from './IApiGovRegulation'


export interface IApiDtoNamed
    extends
        IApiDto,
        IApiDtoAdditions,
        IApiGovRegulation
{
    name: string
}
