export interface IChipItem<T> {
    id: T
    name: string
    selected?: boolean
}

export interface IChipsDropdownState<T> {
    opened: boolean
    model: T
}
