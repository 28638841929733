import { ISelectable } from '../global'

export interface ITabItem extends ISelectable {
    key?         : string
    value        : string
    title?       : string
    changed?     : boolean
    description? : string
    className?   : string
    iconFile?    : string
    disabled?    : boolean
    subitems?    : ITabItem[]
    routerLink?  : unknown[]
}