import { ValidatorFn } from '@angular/forms'

export interface IFormFieldConfiguration {
    validators?: ValidatorFn[]
    dynamic?: IDynamicValidators
    disabledIfNew?: boolean
    disabledIfNotNew?: boolean
    children?: Record<string, IFormFieldConfiguration> | IFormFieldConfiguration[]
}

interface IDynamicValidators {
    validators?: ValidatorFn[]
    dependOnCtrl?: string
    depenOnCtrlNullValues?: Array<string | boolean>
    clearOnChange?: boolean
}