<div class="grid padding-top-2">
    <div class="cell cell-60 offset-20">
        <div class="block-accent padding-top-2 padding-bottom-2">

            <div class="align-center margin-top-2 margin-bottom-2">
                <h4>404: <span [translate]>shared.pageNotFound</span></h4>

                <p [translate]>shared.pageNotFoundText</p>
            </div>

            <div class="align-center margin-top-2 margin-bottom-2">
                <a [routerLink]="['/']" class="btn btn-primary-inverse btn-medium">
                    <i class='fa fa-home'></i>
                    <span [translate]>shared.goHomeLink</span>
                </a>
            </div>

        </div>
    </div>
</div>