import { Injectable } from '@angular/core'
import { fromEvent, Observable } from 'rxjs'
import { filter } from 'rxjs/operators'


@Injectable({providedIn: 'root'})
export class KeyEventService {
    _keydown$: Observable<Event>
    _documentClick$: Observable<Event>

    public get escapeKeyPress$() {
        return this._keydown$.pipe(filter((event: KeyboardEvent) => event.code === 'Escape'))
    }

    public get documentClick$() {
        return this._documentClick$
    }

    constructor() {
        this._keydown$ = fromEvent(window, 'keydown')
        this._documentClick$ = fromEvent(window, 'click')
    }
}
