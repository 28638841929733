import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'

import { AuthService } from 'shared/services/auth.service'
import { UserIdleService } from 'app/services/user-idle.service'
import { LocalStorageFiltersCrud } from 'shared/components/ui/data-table/dt-search/local-storage-filters-crud'
import { DtSearchService } from 'shared'
import { PaginationService } from 'shared/services/pagination.service'


@Injectable({
    providedIn: 'root',
})
export class AuthGuardService  {
    constructor(
        private auth: AuthService,
        private router: Router,
        private idlSvc: UserIdleService,
        private searchSvc: DtSearchService,
        private pagination: PaginationService,
    ) { }

    private onLoginActions(): void {
        this.idlSvc.init()

        // TODO: move to service DTSearchService
        LocalStorageFiltersCrud.flushAllFilters()
        this.searchSvc.resetFilters()
        this.pagination.resetAllPagination()

        this.idlSvc.onExpire(() => {
            LocalStorageFiltersCrud.flushAllFilters()
            this.searchSvc.resetFilters()
            this.pagination.resetAllPagination()
        }, 30 * 60, 1000)
    }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean {
        if (this.auth.isLoggedIn) {
            this.onLoginActions()
            return true
        }

        this.router.navigateByUrl('/auth').then()
        return false
    }
}