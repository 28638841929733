export interface ISearchFilter {
    key: string
    localStgKey?: string
    entityId?: number
    flushOnNewEntity?: boolean

    /** @TODO move filters check from list-page.component */
    status?: any
    role?: any
    agencyIds?: any
    type?: any
}