import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'

import { AuthService } from 'shared/services/auth.service'

@Injectable({ providedIn: 'root' })
export class AgencyGuardService {
    constructor(
        private auth: AuthService,
        private router: Router,
    ) { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean
    {
        if (!this.auth.isAgency && !this.auth.isAdmin) {
            this.router.navigateByUrl('/app/no-permissions')
        }

        return true
    }
}