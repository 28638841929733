import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'

import { AuthService } from 'shared/services/auth.service'


export const AdminFunctionalGuard: CanActivateFn = (
) => {
   const auth = inject(AuthService)
   const router = inject(Router)

   if (!auth.isAdmin) {
      router.navigateByUrl('/').then()
      return
   }

   return true
}