import { ITabItem } from './ITabItem'

export interface ITargetingMenuItem {
    key: string
    title: string
    selected?: boolean
    visible?: boolean
    disabled?: boolean
    required?: boolean
    submenu?: ITabItem[]
}