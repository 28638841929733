import {
    Component, Input, EventEmitter, Output, ChangeDetectionStrategy, ViewChild,
    ElementRef, AfterViewInit, OnDestroy,
} from '@angular/core'
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators'
import { IKeyValueItem } from 'shared/models/global/IKeyValueItem'
import { IDictionaryItem } from '../../../models'


@Component({
    selector: 'search-field',
    templateUrl: 'search-field.component.html',
    styleUrls: ['search-field.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent
    implements AfterViewInit, OnDestroy {
    @ViewChild('searchInput') public searchInput: ElementRef

    @Input()
    public searchMethod: (term: string) => Observable<IDictionaryItem[]>

    @Input()
    public mapResultsMethod: (results: any[]) => IKeyValueItem<string, string>[] =
        (results: any[]) => results.
            filter(item => item !== null && item !== undefined)
            .map(item => ({
                key: item?.key || '',
                value: item?.value || '',
            }))

    @Output() founded = new EventEmitter<IKeyValueItem<string, string>[]>()

    public get cssClass$() { return this.cssClass.asObservable() }
    private cssClass = new BehaviorSubject<{ [key: string]: boolean }>({
        focused: false,
    })

    public get inProgress$() { return this.inProgress.asObservable() }
    private inProgress = new BehaviorSubject<boolean>(false)

    private typeStream$: Observable<KeyboardEvent>
    private typeStreamSubscription: Subscription


    constructor() { }


    ngAfterViewInit() {
        const input = this.searchInput.nativeElement as HTMLInputElement

        this.typeStream$ = fromEvent<KeyboardEvent>(input, 'keyup')

        this.typeStreamSubscription = this.typeStream$.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => this.startSearch()),
            switchMap(() => this.searchMethod(input.value).pipe(
                map(res => this.mapResultsMethod(res)),
                tap(results => this.emitValue(results)),
            )),
        ).subscribe()

        input.addEventListener('focus', () => this.markAsFocused())

        input.addEventListener('blur', () => this.markAsBlurred())
    }

    ngOnDestroy() {
        this.typeStreamSubscription.unsubscribe()
    }


    private emitValue(value: IKeyValueItem<string, string>[]) {
        this.founded.emit(value)
    }

    private markAsFocused() {
        this.cssClass.next({
            ...this.cssClass.getValue(),
            focused: true,
        })
    }

    private markAsBlurred() {
        this.cssClass.next({
            ...this.cssClass.getValue(),
            focused: false,
        })
    }

    private startSearch() {
        this.inProgress.next(true)
    }

    public resetSearch() {
        (this.searchInput.nativeElement as HTMLInputElement).value = ''
        this.emitValue([])
        this.inProgress.next(false)
    }
}
