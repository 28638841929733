import {Component, Input, EventEmitter, Output} from '@angular/core'
import { IApiOrdering } from 'shared/models'


@Component({
    selector: 'dt-sortable-header,[dt-sortable-header]',
    templateUrl: 'dt-sortable-header.component.html',
    providers: [],
})
export class DtSortableHeaderComponent {
    @Input()
    public currentOrdering: IApiOrdering

    @Input()
    public fieldName: string

    @Output()
    public sorting = new EventEmitter<IApiOrdering>()


    public sort(dir = 'asc'): void {
        if (this.currentOrdering.field === this.fieldName
            && this.currentOrdering.direction === dir) {
            return
        }
        this.currentOrdering.field = this.fieldName
        this.currentOrdering.direction = dir

        this.sorting.emit(this.currentOrdering)
    }
}
