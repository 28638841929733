import { Component, Inject, Injector, OnDestroy } from '@angular/core'

import { FilterBaseComponent } from '../filter-base-component'
import { ICheckBoxGroupElement, IFilterValue } from '../../../../../models'
import { EContainerType } from 'app/dmp/models/containers/EContainerType'

@Component({
    template: `
        <checkbox-group [(ngModel)]="model[key]"
                        (ngModelChange)="change($event)"
                        [label]="'app.creatives.filters.type'|translate"
                        [items]="items"
        ></checkbox-group>
    `,
})
export class TypeFilterComponent extends FilterBaseComponent implements OnDestroy {
    protected items: ICheckBoxGroupElement[] = [
        {
            displayName: 'Web',
            value: EContainerType.Web,
        },
        {
            displayName: 'App',
            value: EContainerType.App,
        },
    ]

    constructor(
        @Inject(Injector) injector: Injector,
    ) {
        super(injector)
    }

    public generateFilterView(filter: IFilterValue): void {
        const filtersList = filter[this.key] as string[]

        const result = filtersList
            .map(creativeType => this.items.find(item => item.value === creativeType).displayName)
            .join(', ')

        this.filterView.emit(result)
    }

    public change(value: string[]): void {
        this.hasValue.emit(Boolean(value.length))
    }

    public ngOnDestroy(): void {
        this.model[this.key] = []
    }
}