import { Component, EventEmitter, OnDestroy, Output } from '@angular/core'

import { IFilterValue } from 'shared/models'
import { FilterBaseComponent } from '../filter-base-component'
import { IAgencyFilter } from '../../../../../models'


@Component({
    templateUrl: './agency-filter.component.html',
})
export class AgencyFilterComponent extends FilterBaseComponent implements OnDestroy {

    @Output()
    public filterView = new EventEmitter()

    protected get isAgency(): boolean {
        return this.model[this.key] === IAgencyFilter.Agency
    }

    public generateFilterView(filter: IFilterValue): void {
        this.filterView.emit(filter[this.key])
    }

    public change(value: boolean): void {
        if (value !== null && value !== undefined) {
            this.model[this.key] = value ? IAgencyFilter.Agency : null
            this.hasValue.emit(true)
        } else {
            this.hasValue.emit(false)
        }
    }

    public ngOnDestroy(): void {
        this.model[this.key] = null
    }
}
