import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Host,
    Input,
    Optional,
    SkipSelf,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { ControlContainer, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import { TranslateService } from '@ngx-translate/core'

import { FormValidationService } from 'shared/services'
import { FormInputComponent } from '../formInputComponent'
import { TInputType } from '../../../models'


@Component({
    selector: 'input-text',
    templateUrl: 'input-text.component.html',
    styleUrls: ['input-text.component.less'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputTextComponent),
            multi: true,
        },
    ],
})
export class InputTextComponent extends FormInputComponent<string | number> implements AfterViewInit {

    @Input() public multiline = false

    @Input() public autosize = false

    @Input() public symbolsCount?: number = null

    @Input() public autocomplete = 'off'

    @Input() public ignoreEnter = true

    @Input() public maskedPlaceholder = false

    @Input() public id = ''

    @Input() public postfix?: string

    @Input() public passwordStrength = false

    @Input() public mini = false

    @Input() public required = false

    @Input() public replaceCommaByDot = false

    @Input()
    public set minPasswordLength(value: number) {
        this.minPasswordLengthValue = value
        this.passwordIndicatorSegments = [...Array(value).keys()]
    }

    public get minPasswordLength(): number {
        return this.minPasswordLengthValue
    }

    private minPasswordLengthValue = 8

    @Input()
    public htmlType = 'text'

    @Input()
    public type: TInputType = 'string'

    @Input()
    public showCounter = false

    private passwordIndicatorSegments = [...Array(8).keys()]

    public get indicatorSegments() {
        return this.passwordIndicatorSegments
    }

    @ViewChild('textInput') public textInput: ElementRef

    public get showPasswordStrengthBlock(): boolean {
        return this.passwordStrength && this.htmlType?.toLowerCase() === 'password'
    }

    public get valueLength(): number {
        return this.control?.value?.length || 0
    }

    public get indicatorCssWidth(): string {
        return this.valueLength <= this.minPasswordLength
            ? this.valueLength / this.minPasswordLength * 100 + '%'
            : '100%'
    }

    public get passwordValid(): boolean {
        return (this.control?.value?.length || 0) >= this.minPasswordLength
    }

    public get showTheEye(): boolean {
        return this.htmlType?.toLowerCase() === 'password'
    }

    public get showPostfix(): boolean {
        return this.postfix?.length > 0
    }

    private hidePasswordStarsValue = false

    public get hidePasswordStars(): boolean {
        return this.hidePasswordStarsValue
    }

    public get cssClass(): Record<string, boolean> {
        return {
            'no-label': this.noLabel,
            'multiline': this.multiline,
            'singleline': !this.multiline,
            'number': this.type === 'number' || this.htmlType.toLowerCase() === 'number',
            'with-symbols-counter': this.showCounter,
            'show-valid-state': this.showValidState,
            'focused': this.isFocused,
            'readonly': this.readonly,
            'invalid': !this.isValid && this.isDirty,
            'valid': this.isValid && this.isDirty,
            'with-password-strength': this.showPasswordStrengthBlock,
            'mini': this.mini,
            'masked-placeholder': this.maskedPlaceholder,
        }
    }

    constructor(
        @Optional() @Host() @SkipSelf()
        protected controlContainer: ControlContainer,
        protected cdr: ChangeDetectorRef,
        protected validator: FormValidationService,
        private i18n: TranslateService,
    ) {
        super(controlContainer, cdr, validator)
    }

    public ngOnInit(): void {
        super.ngOnInit()

        if (this.required) {
            const validators: ValidatorFn[] = []
            if (this.control.validator) {
                validators.push(this.control.validator)
            }
            validators.push(Validators.required)

            this.control.setValidators(validators)

            this.control.markAsDirty()
        }

        if (this.type === 'number') {
            const validators: ValidatorFn[] = []
            if (this.control.validator) {
                validators.push(this.control.validator)
            }
            validators.push(
                RxwebValidators.pattern({
                    expression: {
                        'number': new RegExp(/^\d*\.?\d+$/),
                    },
                    message: this.i18n.instant('shared.wrongNumberFormat'),
                }),
            )

            this.control.setValidators(validators)
        }
    }

    public ngAfterViewInit(): void {
        if (this.type === 'number' && this.replaceCommaByDot) {
            this.textInput.nativeElement.addEventListener('paste', event => {
                event.preventDefault()
                const paste = (event.clipboardData || (window as any).clipboardData).getData('text')
                this.textInput.nativeElement.value = paste.replaceAll(',', '.')
                this.textInput.nativeElement.dispatchEvent(new Event('input'))
            })
        }
    }

    public togglePasswordStarsVisibility(): void {
        this.hidePasswordStarsValue = !this.hidePasswordStars
    }
}
