<div class="dt-pager">
    <!--
    <div class="pager-per-page-text">
        items per page
    </div>
    -->
    <div class="pager-per-page-dropdown" *ngIf="!hideItemsPerPageSelector">
        <dropdown [scrollable]="false"
                  [listItems]="itemsPerPageList"
                  [(ngModel)]="itemPerPage"
                  (ngModelChange)="itemsPerPageChange()"
                  [searchable]="false"
                  [nullable]="false">
        </dropdown>
    </div>

    <ul>
        <li *ngIf="paging.pageCount > 1 && extendedMode && currentIndex != 0" (click)="changePage(-1)">
            <span><i class="fa fa-chevron-left"></i></span>
        </li>

        <li *ngFor="let page of pages; index as i" (click)="setPage(page.index)"
            [hidden]="pages.length == 1">
            <span *ngIf="page.skipped">...</span>

            <span *ngIf="!page.skipped && page.index != currentIndex">{{page.page}}</span>
            <span *ngIf="!page.skipped && page.index == currentIndex"
                  class="pager-page-current">{{page.page}}</span>
        </li>

        <li *ngIf="paging.pageCount > 1 && extendedMode && currentIndex != pages[pages.length-1].index"
            (click)="changePage(1)">
            <span><i class="fa fa-chevron-right"></i></span>
        </li>
    </ul>

</div>
