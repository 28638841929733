import { Component, Inject, Injector, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { PageComponent } from 'shared/models'
import { AppTranslationService } from 'shared/services'

@Component({
    selector: 'privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.less'],
})
export class PrivacyPolicyComponent extends PageComponent implements OnInit {
    private _lang
    public get lang() { return this._lang }

    constructor(
        @Inject(Injector) injector: Injector,
        protected appTranslate: AppTranslationService,
        protected route: ActivatedRoute,
    ) {
        super(injector)

        this._lang = this.route.snapshot.queryParamMap.get('l') || this.appTranslate.getCurrentLanguage()
    }

    ngOnInit() {
        this.i18n.get('shared.privacyPolicy').subscribe(
            title => this.ui.setPageBreadcrumb([{ title: title }]),
        )
    }
}