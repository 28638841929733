import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import 'reflect-metadata'

import { IPageViewModel } from './IPageView.model'
import { PageComponent } from './page.component'
import { FormMetadataKeys, FormValidityState, IFormFieldConfiguration } from '../forms'
import { deepGet, deepSet } from 'shared/utils'
import { ControlNullValue } from '../const'


@Component({ template: '' })
export abstract class ItemPageEditableComponent<
    DtoReadType,
    DtoWriteType,
    VmType extends IPageViewModel<DtoReadType, DtoWriteType>
>
    extends PageComponent
    implements OnInit, OnDestroy {
    protected returnUrl: string = null

    protected get needReturn(): boolean {
        return this.returnUrl !== null && this.returnUrl !== undefined && this.returnUrl.length > 0
    }

    protected _id: number
    public get id(): number {
        return this._id
    }

    public get isNew(): boolean {
        return isNaN(this._id)
    }

    private _idRecognized: Subject<number> = new Subject()
    public get idRecognized(): Observable<number> {
        return this._idRecognized.asObservable()
    }

    private submittingValue = new BehaviorSubject<boolean>(false)

    public get submitting$(): Observable<boolean> {
        return this.submittingValue.asObservable()
    }

    protected _idParsed = false
    public get idParsed(): boolean {
        return this._idParsed
    }

    public form: FormGroup
    protected formFieldKeys: string[] = []

    public model: VmType
    protected source: VmType

    public state: FormValidityState = new FormValidityState()

    public modelLoaded = false

    protected fb: UntypedFormBuilder

    protected constructor(
        @Inject(Injector) injector: Injector,
        protected route: ActivatedRoute,
        @Inject(String) protected editableItemId: string = 'id',
    ) {
        super(injector)
        this.fb = injector.get(UntypedFormBuilder)
    }

    public ngOnInit(): void {
        this.subscribeOn(this.route.params.subscribe(params => {
            const id: number = +params[this.editableItemId]
            if (!isNaN(id) && id > -1) {
                this._id = id
                this._idRecognized.next(id)
            } else {
                this._idRecognized.next(null)
            }

            this._idParsed = true

            this.returnUrl = params['return'] || null
        }))
    }

    private composeDynamicValidators(
        form: FormGroup,
        control: FormControl,
        conf: IFormFieldConfiguration,
    ): void {
        const {
            validators: validators,
            dependOnCtrl: dependOn,
            depenOnCtrlNullValues: nullValues = [],
            clearOnChange,
        } = conf.dynamic
        nullValues.push(ControlNullValue)

        const dependOnControl = form.get(dependOn)

        let dynamicValidators: ValidatorFn[] = []

        if (control.validator) {
            dynamicValidators.push(control.validator)
        }
        dynamicValidators = dynamicValidators.concat(validators)
        const setVs: (c: FormControl) => void
            = (c: FormControl) => c.setValidators(
            Validators.compose(dynamicValidators),
        )

        if (dependOnControl && !nullValues.includes(dependOnControl.value)) {
            setVs(control)
            // invalid state only triggers for 'isDirty: true' controls
            control.markAsDirty()
        }

        dependOnControl.valueChanges.subscribe(value => {
            if (
                value !== undefined && value !== null
                && !nullValues.includes(dependOnControl.value)
            ) {
                setVs(control)
                control.updateValueAndValidity()
                if (clearOnChange) control.reset()
            } else {
                control.clearValidators()
                control.reset()
            }
        })
    }

    private getFormGroup(model: (VmType | (keyof VmType)), parentKey?: string): FormGroup {
        const formGroup = this.fb.group({})

        Object.getOwnPropertyNames(model).forEach(propertyName => {
            const key = parentKey ? `${ parentKey }[${ propertyName }]` : propertyName
            const fieldConfiguration: IFormFieldConfiguration = Reflect.getMetadata(FormMetadataKeys.FORM_FIELD, this.model, key)

            if (fieldConfiguration || parentKey) {
                let control: FormGroup | FormControl | FormArray

                if (Array.isArray(model[propertyName])) {

                    control = this.getFormArray(model[propertyName], key)

                } else if (typeof model[propertyName] === 'object' && model[propertyName] !== null) {

                    control = this.getFormGroup(model[propertyName], key)

                } else {

                    const fieldValidators: ValidatorFn[] = fieldConfiguration?.validators || []
                    control = this.getFormControl(formGroup, model, propertyName, fieldValidators, fieldConfiguration)

                }

                this.addFormFieldKeys(model, propertyName, key)
                formGroup.addControl(propertyName, control)
            }
        })

        return formGroup
    }

    private addFormFieldKeys(
        model: VmType | keyof VmType | (keyof VmType)[],
        field: string | number,
        parentKey?: string,
    ): void {
        const value = model[field]
        const isArray = Array.isArray(value)
        const isObject: (val) => boolean =
            (val) => (
                typeof val === 'object'
                && !(val instanceof Date)
                && val !== null
            )

        if (
            (isArray && !isObject(value[0]))
            || !isObject(value)
        ) {
            this.formFieldKeys.push(`${ parentKey }`)
        }
    }

    private getFormControl(
        form: FormGroup,
        model: VmType | keyof VmType,
        field: string,
        validators: ValidatorFn[],
        fieldConfiguration: IFormFieldConfiguration,
    ): FormControl {
        const control = new FormControl(model[field], Validators.compose(validators))
        const isModelIdExists = (<VmType>model).id !== null && !isNaN((<VmType>model).id)

        if (
            (fieldConfiguration?.disabledIfNew && !isModelIdExists)
            || (fieldConfiguration?.disabledIfNotNew && isModelIdExists)
        ) {
            control.disable()
        }

        if (fieldConfiguration?.dynamic?.validators && fieldConfiguration?.dynamic.dependOnCtrl) {
            this.composeDynamicValidators(form, control, fieldConfiguration)
        }

        return control
    }

    private getFormArray(array: (keyof VmType)[], parentKey?: string): FormArray {
        const formArray = this.fb.array([])

        for (let i = 0; i < array.length; i++) {
            if (typeof array[i] !== 'object') {
                formArray.push(
                    this.fb.control(array[i]),
                )
                this.addFormFieldKeys(array, i, parentKey + `[${ i }]`)
            } else {
                const innerControl = this.getFormGroup(array[i], `${ parentKey }[${ i }]`)
                formArray.push(innerControl)
            }
        }

        return formArray
    }

    private createForm(model: VmType): void {
        if (this.form) {
            return
        }

        this.form = this.getFormGroup(model)
        this.state = new FormValidityState(this.form)
        this.form.valueChanges.subscribe(() => this.updateModelFromForm())
    }

    private getPatchObjectFromModel(model: VmType): Record<string, any> {
        const patchObject: Record<string, any> = {}

        this.formFieldKeys.forEach(key => {
            patchObject[key] = (<any>model)[key]
        })

        return patchObject
    }

    protected returnIfNeed(): void {
        if (this.needReturn) {
            this.router.navigate([this.returnUrl]).then()
        }
    }

    protected preSubmit(): void {
        this.ui.startBackendAction()
        this.submittingValue.next(true)
    }

    protected postSubmit(): void {
        this.ui.stopBackendAction()
        this.submittingValue.next(false)
    }

    protected setupForm(dto?: DtoReadType): void {
        if (dto) {
            this.model.mapFromDto(dto)
            this.source.mapFromDto(dto)
        }

        this.createForm(this.model)
        this.modelLoaded = true
    }

    protected updateFormFromModel(model: VmType): void {
        this.form.patchValue(this.getPatchObjectFromModel(model))
    }

    protected updateFormFieldFromModel(formField: string, value: any): void {
        const patchObject: Record<string, any> = {}
        patchObject[formField] = value
        this.form.patchValue(patchObject)
    }

    protected resetForm(source: VmType): void {
        this.form.reset(this.getPatchObjectFromModel(source))
        this.form.markAsPristine()
    }

    protected updateModelFromForm(): void {
        this.formFieldKeys.forEach((key) => deepSet(this.model, key, deepGet(this.form.value, key)))
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy()
        this.form = null
        this.model = null
        this.source = null
    }
}

