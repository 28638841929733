<div class="chips-dropdown-wrapper filters-chips" [ngClass]="cssClass">
    <div class="chips-dropdown-search chips-dropdown-{{size}}">
        <div class="current-value">
            <div class="filters-list">
                <ng-container #staticFilters></ng-container>

                <ng-container #filters></ng-container>

                <div>
                    <button class="dt-search-add-filter"
                            *ngIf="hasFilters"
                            (click)="toggleList()"
                            type="button"
                            [translate]>
                        shared.add-filter
                    </button>

                    <div class="list-wrapper list-wrapper--filters" [hidden]="isDisabled">
                        <ul class="list" #list>
                            <ng-container *ngFor="let item of filtersList; index as i">
                                <li *ngIf="!item.selected
                                        && !item.static
                                        && !(item.isHidden && item.isHidden())"
                                    class="item filter-list-item"
                                    (click)="itemClick(i)"
                                >
                                    <ng-container *ngIf="item.name && item.name.length > 0">
                                        {{item.name|translate}}
                                    </ng-container>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
