import { FilterValueTypes, IQuickFilter } from '../models'


export function notNullOrUndefined(value): boolean {
    return value !== undefined && value !== null
}

export function filterHasValue(value: FilterValueTypes): boolean {
    return Boolean(Array.isArray(value) ? value.length : value)
}

export function isInvalidValidFilter(filter: IQuickFilter): boolean {
    return filter === null || filter.op === undefined || filter.values.length === 0
}

export function deepSet(
    obj: Record<string, any>,
    path: string | string[],
    value: any,
): void {
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

    pathArray.reduce((acc, key, i) => {
        if (acc[key] === undefined) acc[key] = {}
        if (i === pathArray.length - 1) acc[key] = value
        return acc[key]
    }, obj)
}

export function deepGet(
    obj: Record<string, any>,
    path: string | string[],
): any {
    if (!path) {
        return undefined
    }

    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
    return pathArray.reduce(
        (prevObj, key) => prevObj && prevObj[key],
        obj,
    )
}

export function timeToSeconds(time: string): number {
    const [hours, minutes, seconds] = time.split(':')
    return +(hours ?? 0) * 60 * 60 + +(minutes ?? 0) * 60 + +(seconds ?? 0)
}