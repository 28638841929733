import { Inject, Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs'

import { BaseApiService } from './base-api.service'
import { IApiDtoNamed } from '../models'

@Injectable()
export class FilterSelectService extends BaseApiService {
    constructor(
        public injector: Injector,
        @Inject(String) protected filterSearchUrl: string,
    ) {
        super(injector)
    }

    public filterSearch(term: string, columnId: string): Observable<IApiDtoNamed[]> {
        const url = `${this.filterSearchUrl}/${columnId}`

        const params = {}
        params['searchTerm'] = term

        return this.post<{ [key: string]: string }, IApiDtoNamed[]>(url, params)
    }
}