import { IApiDto } from 'shared/models'
import { EUserLanguage } from './'

export interface IProfileDto extends IApiDto {
    email?: string
    firstName?: string
    lastName?: string
    agencyName?: string
    phone?: string
    countryId?: number
    timezoneId?: number
    currency?: number
    language?: EUserLanguage
    bidderId?: number
    bidderDmpId?: number
}
