import { Component } from '@angular/core'
import { map, takeUntil, tap } from 'rxjs/operators'

import { DestroyService, FormValidationService } from 'shared/services'
import { IApiFieldError } from '../../../models'


@Component({
    selector: 'form-validation',
    templateUrl: './form-validation.component.html',
    styleUrls: ['./form-validation.component.less'],
    providers: [DestroyService],
})
export class FormValidationComponent {
    private _message = ''
    public get message(): string { return this._message }

    public get showMessage(): boolean { return this._message && this._message.length > 0 }

    constructor(
        private validator: FormValidationService,
        private destroy: DestroyService,
    ) {
        this.validator.lastMessage.pipe(
            takeUntil(this.destroy),
            map(message => {
                const validResponse = message !== undefined && message.apiResponse !== undefined
                const hasError = !message?.apiResponse.isSuccessful

                return validResponse && hasError ? message : null
            }),
            tap(message => {
                if (message === null) {
                    this._message = ''
                } else {
                    const errorDetails = message.apiResponse.errorDetails

                    this._message = errorDetails && errorDetails.length > 0
                        ? this.formatErrorMsg(errorDetails)
                        : message.apiResponse.errorMessage
                }
            }),
        ).subscribe()
    }

    private formatErrorMsg(errorDetails: IApiFieldError[]): string {
        let result = ''

        for (const detail of errorDetails) {
            result += `${detail.errorDescriptor}: ${detail.errorMessage.join(', ')}<br/>`
        }
        return result
    }
}
