import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component, ElementRef, EventEmitter,
    forwardRef,
    Input, OnChanges,
    Output, SimpleChanges, ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { fromEvent, Observable, Subscription } from 'rxjs'
import { debounceTime, map, tap } from 'rxjs/operators'

import { IChipItem, IChipsDropdownState } from './model'


@Component({
    selector: 'chips-dropdown',
    templateUrl: './chips-dropdown.component.html',
    styleUrls: ['./chips-dropdown.component.less'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipsDropdownComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsDropdownComponent
    implements OnChanges, ControlValueAccessor {

    @Input()
    public placeholder = ''
    displayName = ''

    private _chipsList: IChipItem<number>[] = []
    @Input()
    public set chipsList(value: IChipItem<number>[]) {
        this._chipsList = [...value]
    }

    itemsList: IChipItem<number>[] = []
    selectedChips: IChipItem<number>[] = []

    @Input()
    public size? = 'medium'

    @Input()
    public set model(value: number[]) {
        this._model = value
        if (value.length === 0) {
            this.selectedChips = []
        } else {
            this.selectedChips = this._chipsList.filter(chip => {
                if (value.includes(chip.id)) {
                    chip.selected = true
                    return true
                }

                return false
            })
        }
    }
    public get model(): number[] {
        return this._model
    }
    private _model: number[]

    @Input()
    public clearable = true

    @Output()
    public modelChange = new EventEmitter<number[]>()

    @Output()
    public stateChange = new EventEmitter<IChipsDropdownState<number[]>>()

    @ViewChild('search')
    public searchInput: ElementRef

    protected searchText = ''

    public get isDisabled(): boolean {
        return !this._chipsList || this._chipsList.length === 0
    }

    public opened = false

    documentClick: Subscription

    propagateChange = (_: any) => {
    }

    propagateTouched = (_: any) => {
    }

    protected get cssClass(): Record<string, boolean> {
        return {
            'opened': this.opened,
            'closed': !this.opened,
            'selected': this.selected,
            'disabled': this.isDisabled,
            'has-chips': this._chipsList.length !== this.selectedChips.length,
        }
    }

    private searchInputStream$: Observable<KeyboardEvent>
    private searchInputStreamSubscription: Subscription

    private searchFocusStream$: Observable<KeyboardEvent>
    private searchFocusStreamSubscription: Subscription

    constructor(private cdr: ChangeDetectorRef,
                private _element: ElementRef,
    ) {
        this.documentClick = fromEvent(document, 'click')
            .subscribe((event: MouseEvent) => {
                const target = event.target
                if (!this._element.nativeElement.contains(target)) {
                    this.setOpenState(false)
                }
            })
    }

    get selected(): boolean {
        return this.selectedChips.length > 0
    }

    private get selectedValues(): number[] {
        return this.selectedChips.length
            ? this.selectedChips.map(chip => Number(chip.id))
            : []
    }

    private emitModelValue(): void {
        this.modelChange.emit(this.selectedValues)
        this.propagateChange(this.selectedValues)
    }

    public ngOnDestroy(): void {
        this.documentClick.unsubscribe()
    }

    public trackByFn(index, item) {
        return index
    }

    public itemClick(index: number): void {
        const chip = this.itemsList[index]
        chip.selected = true
        this.selectedChips.push(chip)

        this.setOpenState(false)
        this.emitModelValue()
        this.cdr.detectChanges()
    }

    public toggleList(event: Event): void {
        const target = event.target as HTMLElement
        if (
            target.classList.contains('remove-chip')
            || this.itemsList.length === this.selectedChips.length
            || target.classList.contains('fa-remove')
        ) return

        this.setOpenState(!this.opened)
    }

    protected selectByEnter(): void {
        const value = this.searchInput.nativeElement.value.toLowerCase()
        const idx = this.itemsList.findIndex(i => i.name.toLowerCase() === value)
        if (idx !== -1) {
            this.itemClick(idx)
        }
    }

    private setOpenState(isOpened: boolean): void {
        if (this.isDisabled) {
            return
        }

        this.searchInput.nativeElement.value = ''
        this.searchText = ''

        if (isOpened) {
            this.setupSearch()
            setTimeout(() => this.searchInput.nativeElement.focus())
        } else {
            this.removeSearchEvents()
            this.itemsList = [].concat(this._chipsList)
            setTimeout(() => this.searchInput.nativeElement.blur())
        }

        this.opened = isOpened
        this.stateChange.emit({ opened: this.opened, model: this.model })

        this.cdr.detectChanges()
    }

    private setupSearch(): void {
        this.searchInputStream$ = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'keyup')
        this.searchFocusStream$ = fromEvent<KeyboardEvent>(this.searchInput.nativeElement, 'focus')

        this.searchInputStreamSubscription = this.searchInputStream$
            .pipe(
                tap(() => {
                    this.searchText = this.searchInput.nativeElement.value
                    this.cdr.detectChanges()
                }),
                debounceTime(200),
                map(() => {
                    const term = this.searchInput.nativeElement.value

                    return this._chipsList.filter(item => item.name.toLowerCase().includes(term))
                }),
                tap((results) => {
                    this.itemsList = [].concat(results)
                    this.cdr.detectChanges()
                }),
            ).subscribe()

        this.searchFocusStreamSubscription = this.searchFocusStream$
            .pipe(
                map(() => {
                    const term = this.searchInput.nativeElement.value

                    return this._chipsList.filter(item => item.name.toLowerCase().includes(term))
                }),
                tap((results) => {
                    this.itemsList = [].concat(results)
                    this.cdr.detectChanges()
                }),
            )
            .subscribe()
    }

    private removeSearchEvents(): void {
        if (this.searchInputStreamSubscription) {
            this.searchInputStreamSubscription.unsubscribe()
        }
        if (this.searchFocusStreamSubscription) {
            this.searchFocusStreamSubscription.unsubscribe()
        }
    }

    private setDefaultDisplayName(): void {
        this.displayName = this.placeholder
            ? this.placeholder
            : 'Select value'
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn
    }

    public registerOnTouched(fn: any): void {
        this.propagateTouched = fn
    }

    public writeValue(obj: any): void {
        throw 'writeValue not implemented'
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.placeholder) {
            this.setDefaultDisplayName()
        }

        if (changes.chipsList) {
            this.itemsList = [].concat(this._chipsList)
        }
    }

    protected removeChip(chip: IChipItem<number>, index: number): void {
        const clickedChip = this._chipsList.find(c => c.id === chip.id)
        clickedChip.selected = false
        this.selectedChips.splice(index, 1)
        this.emitModelValue()
    }

    protected clearAll(): void {
        this.selectedChips = []
        for (const chip of this._chipsList) {
            chip.selected = false
        }
        this.emitModelValue()
    }
}
